import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'
import { jwtTokenShopAdm, jwtTokenExp } from '@/config'
import paths from './paths'

Vue.use(Router)

/**
 * 
 * @returns boolean value for token validation
 */
const checkTokenValidity = () => {
  if (localStorage.getItem(jwtTokenShopAdm) && localStorage.getItem(jwtTokenExp)) return (Math.floor(Date.now() / 1000) < localStorage.getItem(jwtTokenExp))
  else return false
}

/**
 * vue-router
 */
const router = new Router({
  base: '',
  mode: 'history',
  routes: paths
})

/**
 * protected routes
 */
router.beforeEach((to, from, next) => {
  if (to.path !== '/' && to.path !== '/login') store.commit('setFastUrl', to)
  to.matched.some(record => record.meta.requiresAuth) ? checkTokenValidity() ? next() : next('/login') : next()
})


export default router
