/**
 * shopAdm backend axios instance
 */
import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import { jwtTokenShopAdm } from '@/config'

// getting and setting shopAdm base url endpoint
window.BaseShopAdm = {
  value: '',
  set (val) { this.value = val },
  get () { return this.value }
}

// shopAdm custom axios instance with interceptiors pre and after request
const httpShopAdm = axios.create()

httpShopAdm.interceptors.request.use(cfg => {
  cfg.baseURL = window.BaseShopAdm.get()
  const token = window.localStorage.getItem(jwtTokenShopAdm)
  if (token) cfg.headers.common['Authorization'] = 'Bearer ' + token
  return cfg
}, err => {
  router.push('login').catch(e => e)
  return Promise.reject(err)
})

httpShopAdm.interceptors.response.use(response => response, err => {
  if (err.response.status === 401) {
    router.push('login').catch(e => e)
    Vue.notify({
      title: 'Error: 401',
      text: 'ShopAdm Token expired'
    })
    return err
  } else if (err.response.status === 404) {
    return Promise.reject(err.response.data)
  }
  return Promise.reject(err.response.data.error)
})

export default httpShopAdm
