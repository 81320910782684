import httpShopAdm from './http/httpShopAdm'
import apiHandler from '@/services/http/api'

export const getProduct = id => apiHandler(httpShopAdm.get(`/api/product/get?movielib_id=${id}`))

export const createProduct = data => apiHandler(httpShopAdm.post('/api/product/create-from-movielib', data))

export const updateProduct = (id, data, afterCreate) => apiHandler(httpShopAdm.post(`/api/product/update?id=${id}&afterCreate=${afterCreate}`, data))

export const updateMultipleProducts = (eans, data) => apiHandler(httpShopAdm.post(`/api/product/update-bulk?ean_list=${eans}`, data))

export const getSuppliers = () => apiHandler(httpShopAdm.get('/api/product/get-suppliers'))

export const additionalInfo = ids => apiHandler(httpShopAdm.get(`/api/product/get-multi?movielib_id_list=${ids}`))
