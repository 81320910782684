import httpMovieLib from './http/httpMovieLib'
import apiHandler from '@/services/http/api'
import httpShopAdm from './http/httpShopAdm'

const multiPart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const seriesGrid = (searchParameters, pagination) => {
  let searchString = ''

  Object.keys(searchParameters).forEach(el => {
    if (searchParameters[el] !== '' && searchParameters[el] !== undefined && searchParameters[el] !== null) {
      switch (el) {
        case 'title': {
          searchString += `&st[0][o]=cs&st[0][v]=${searchParameters[el]}`
          break
        }
        case 'active': {
          searchString += `&sa[0][o]=is&sa[0][v]=${searchParameters[el]}`
          break
        }
        case 'bgImage': {
          searchString += `&sbg[0][o]=is&sbg[0][v]=${searchParameters[el]}`
          break
        }
        case 'id': {
          searchString += `&id[0][o]=is&id[0][v]=${searchParameters[el]}`
          break
        }
        case 'studio': {
          searchString += `&sid[0][o]=is&sid[0][v]=${searchParameters[el]}`
          break
        }
      }
    }
  })

  return apiHandler(httpMovieLib.get(`/api/series/grid?sort=${pagination.orderBy}&dir=${pagination.order}&limit=${pagination.rowsPerPage}&page=${pagination.page}${searchString}`))
}

export const getSeriesById = id => apiHandler(httpMovieLib.get(`/api/serie/studio/${id}/options`))

export const createSerie = data => apiHandler(httpMovieLib.post('/api/serie/add', data))

export const updateSerie = (id, data) => apiHandler(httpMovieLib.put(`/api/serie/${id}`, data))

export const deleteSerie = id => apiHandler(httpMovieLib.delete(`/api/serie/${id}`))

export const uploadBackground = (id, data) => apiHandler(httpMovieLib.post(`/api/serie/${id}/bg-image`, data, multiPart))

export const removePicture = id => apiHandler(httpMovieLib.delete(`/api/serie/${id}/bg-image`))

export const getSerieDetails = id => apiHandler(httpMovieLib.get(`/api/serie/${id}`))

export const detectByTitle = data => apiHandler(httpMovieLib.get(`/api/serie/detect-by-title?title=${data}`))

export const getSerieFeatured = serieId => apiHandler(httpShopAdm.get(`/api/series/featured/${serieId}`))

export const setSerieFeatured = (serieId, isFeatured) => apiHandler(httpShopAdm.post(`/api/series/featured/${serieId}`, { isFeatured }))

export const getSeriesFeatured = idList => apiHandler(httpShopAdm.get(`/api/series/featured-multi?idList=${idList}`))
