export const getters = {
  username: state => {
    return state.username
  },
  roles: state => {
    return state.roles
  },
  loggedIn: state => {
    return state.loggedIn
  },
  fastUrl: state => {
    return state.fastUrl
  },
  toolbar: state => {
    return state.toolbar
  },
  drawer: state => {
    return state.drawer
  },
  drawerMini: state => {
    return state.drawerMini
  },
  confirmationPrompt: state => {
    return state.confirmationPrompt
  },
  cachedDirectors: state => {
    return state.cachedOptions.directors
  },
  cachedStudios: state => {
    return state.cachedOptions.studios
  },
  cachedCategories: state => {
    return state.cachedOptions.categories
  },
  cachedStudioCategories: state => {
    return state.cachedOptions.studioCategories
  },
  cachedCategoriesGroup: state => {
    return state.cachedOptions.categoriesGroup
  },
  cachedSuppliers: state => {
    return state.cachedOptions.suppliers
  }
}
