import httpShopAdm from './http/httpShopAdm'
import apiHandler from '@/services/http/api'

const multiPart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const getPornstarOptions = data => apiHandler(httpShopAdm.get(`/api/performer/options?q=${data}`))

export const getPornstarsOptionsByNames = data => apiHandler(httpShopAdm.post('/api/performer/resolve', data))

export const getPornstarDetails = id => apiHandler(httpShopAdm.get(`/api/performer/get?id=${id}`))

export const getPornstarSimilarNames = name => apiHandler(httpShopAdm.get(`/api/performer/similar-names?name=${name}`))

export const createPornstar = data => apiHandler(httpShopAdm.post('/api/performer/create', data))

export const updatePornstar = (id, data) => apiHandler(httpShopAdm.put(`/api/performer/update?id=${id}`, data))

export const deletePornstar = id => apiHandler(httpShopAdm.delete(`/api/performer/delete?id=${id}`))

export const undeletePornstar = id => apiHandler(httpShopAdm.post(`/api/performer/undelete?id=${id}`))

export const undeletePornstarFromSnapshot = id => apiHandler(httpShopAdm.post(`/api/performer/undelete-from-snapshot?id=${id}`))

export const undoPornstarFromSnapshot = (id, pid) => apiHandler(httpShopAdm.post(`/api/performer/undo?id=${id}&performerId=${pid}`))

export const listSnapshots = () => apiHandler(httpShopAdm.get(`/api/performer/snapshots`));

export const pornstarGrid = (searchParameters, pagination) => {
  let searchString = ''

  Object.keys(searchParameters).forEach(el => {
    if (searchParameters[el] !== '' && searchParameters[el] !== undefined && searchParameters[el] !== null) {
      searchString += `&${el}=${searchParameters[el]}`
    }
  })

  return apiHandler(httpShopAdm.get(`/api/performer/list?perPage=${pagination.rowsPerPage}&page=${pagination.page}&order=${pagination.order}&orderBy=${pagination.orderBy}${searchString}`))
}

export const addPicture = (performerId, data) => apiHandler(httpShopAdm.post(`/api/performer/add-picture?performerId=${performerId}`, data, multiPart))

export const updatePicture = (performerId, pictureId, data) => apiHandler(httpShopAdm.put(`/api/performer/update-picture?performerId=${performerId}&id=${pictureId}`, data))

export const deletePicture = (performerId, pictureId) => apiHandler(httpShopAdm.delete(`/api/performer/delete-picture?performerId=${performerId}&id=${pictureId}`))
