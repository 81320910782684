<template>
  <v-container
    class="crud-container"
    grid-list-sm
  >
    <v-layout>
      <v-flex v-if="promo.length">
        <v-layout
          row
          wrap
        >
          <MediaVideo
            v-for="(vid, index) in promo"
            :data="vid"
            :heading="`Promo trailer (${vid.id})`"
            :key="index + 'promo'"
            @removeTrailer="id => $emit('removeTrailer', id)"
          />
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import MediaVideo from '@/components/common/MediaVideo'
import { mapGetters } from 'vuex'

export default {
  components: {
    MediaVideo
  },
  computed: {
    ...mapGetters({
      selectedScene: 'movies/selectedScene',
      trailers: 'movies/movieTrailers'
    }),
    promo () {
      if (this.selectedScene === 0 && this.trailers.promo) {
        return this.trailers.promo
      } else return []
    }
  }
}
</script>
