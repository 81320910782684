import Vue from 'vue'
import Vuex from 'vuex'

// general store
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { state } from './state'

// movies store
import { moviesState } from './movies/state'
import { moviesGetters } from './movies/getters'
import { moviesActions } from './movies/actions'
import { moviesMutations } from './movies/mutations'

// studios store
import { studiosState } from './studios/state'
import { studiosGetters } from './studios/getters'
// import { studiosActions } from './studios/actions'
import { studiosMutations } from './studios/mutations'

// series store
import { seriesState } from './series/state'
import { seriesGetters } from './series/getters'
// import { seriesActions } from './series/actions'
import { seriesMutations } from './series/mutations'

// categories store
import { categoriesState } from './categories/state'
import { categoriesGetters } from './categories/getters'
// import { categoriesActions } from './categories/actions'
import { categoriesMutations } from './categories/mutations'

// pornstars store
import { pornstarsState } from './pornstars/state'
import { pornstarsGetters } from './pornstars/getters'
// import { pornstarsActions } from './pornstars/actions'
import { pornstarsMutations } from './pornstars/mutations'

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    movies: {
      namespaced: true,
      actions: moviesActions,
      getters: moviesGetters,
      mutations: moviesMutations,
      state: moviesState
    },
    studios: {
      namespaced: true,
      // actions: studiosActions,
      getters: studiosGetters,
      mutations: studiosMutations,
      state: studiosState
    },
    series: {
      namespaced: true,
      // actions: seriesActions,
      getters: seriesGetters,
      mutations: seriesMutations,
      state: seriesState
    },
    categories: {
      namespaced: true,
      // actions: categoriesActions,
      getters: categoriesGetters,
      mutations: categoriesMutations,
      state: categoriesState
    },
    pornstars: {
      namespaced: true,
      // actions: pornstarsActions,
      getters: pornstarsGetters,
      mutations: pornstarsMutations,
      state: pornstarsState
    }
  }
})

export default store
