export const pornstarsMutations = {
  mutateSavedData (state, data) {
    state.savedData = data
  },
  mutateSavedDataImages (state, data) {
    state.savedData.images = data
  },
  addPlaceholder (state) {
    if (!state.savedData.images) {
      state.savedData.images = []
    }
    state.savedData.images = [
        ...state.savedData.images,
        {
          source: '',
          primary: false,
          censored: false,
          url: '',
          width: '',
          height: ''
        }
      ]
  },
}