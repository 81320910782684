import jwtDecode from 'jwt-decode'
import { login } from '@/services/user.service'
import { getDirectors } from '@/services/directors.service'
import { getStudioOptions, getStudioCategories } from '@/services/studio.service'
import { getSuppliers } from '@/services/product.service'
import { getCategoriesOptions, getCategoriesGroup } from '@/services/categories.service'
import { jwtTokenMovieLib, jwtTokenShopAdm, jwtTokenFlexFiles, jwtTokenExp, jwtTokenIat } from '@/config'

function setBaseURLFromLocalStorage(key, setterFunction) {
  const value = localStorage.getItem(key);
  if (value) {
    setterFunction(value);
  }
}

export const actions = {
  userInitialization ({ commit }) {
    const token = localStorage.getItem(jwtTokenShopAdm)
    if (token) {
      const { uname, roles } = jwtDecode(token)
      setBaseURLFromLocalStorage('baseURLMovieLib', window.BaseMovieLib.set.bind(window.BaseMovieLib));
      setBaseURLFromLocalStorage('baseURLFlexFiles', window.BaseFlexFiles.set.bind(window.BaseFlexFiles));
      setBaseURLFromLocalStorage('baseURLShopAdm', window.BaseShopAdm.set.bind(window.BaseShopAdm));
      commit('loginSuccess', {
        username: uname,
        roles
      })
      commit('setDrawer', true)
      commit('setToolbar', true)
    }
  },

  login ({ commit }, data) {
    return login(data)
      .then(data => {
        localStorage.setItem(jwtTokenMovieLib, data.result.tokens.movielib)
        localStorage.setItem(jwtTokenShopAdm, data.result.tokens.shopadm)
        localStorage.setItem(jwtTokenFlexFiles, data.result.tokens.flexfiles)
        const { exp, iat, uname, roles } = jwtDecode(data.result.tokens.shopadm)
        localStorage.setItem(jwtTokenExp, exp)
        localStorage.setItem(jwtTokenIat, iat)
        if (data?.result?.baseUrls?.shopadm) {
          localStorage.setItem('baseURLShopAdm', data.result.baseUrls.shopadm);
          window.BaseShopAdm.set(data?.result?.baseUrls?.shopadm)
        }
        if (data?.result?.baseUrls?.movielib) {
          localStorage.setItem('baseURLMovieLib', data.result.baseUrls.movielib);
          window.BaseMovieLib.set(data?.result?.baseUrls?.movielib)
        }
        if (data?.result?.baseUrls?.flexfiles) {
          localStorage.setItem('baseURLFlexFiles', data.result.baseUrls.flexfiles);
          window.BaseFlexFiles.set(data?.result?.baseUrls?.flexfiles)
        }
        commit('loginSuccess', {
          username: uname,
          roles
        })
        commit('setDrawer', true)
        commit('setToolbar', true)
      })
  },

  getCategoriesOptionsData ({ state, commit }, force) {
    if (!state.cachedOptions.categories.length || force) {
      getCategoriesOptions()
        .then(response =>
          commit('cacheCategories', response.result.data.map(el => {
            return {
              name: el.name,
              id: el.id,
              nameDe: el.translations.de ? el.translations.de.name : '',
              nameNl: el.translations.nl ? el.translations.nl.name : '',
            }
          }))
        )
        .catch(e => e)
    }
  },

  getStudioCategoriesOptionsData ({ state, commit }, force) {
    if (!state.cachedOptions.studioCategories.length || force) {
      getStudioCategories()
        .then(response =>
          commit('cacheStudioCategories', response.result.map(el => {
            return {
              text: el.name,
              value: el.id
            }
          }))
        )
        .catch(e => e)
    }
  },

  getCategoriesGroupData ({ state, commit }, force) {
    if (!state.cachedOptions.categoriesGroup.length || force) {
      getCategoriesGroup()
        .then(response => commit('cacheCategoriesGroup', response.result))
        .catch(e => e)
    }
  },

  getStudioOptionsData ({ commit, state }, force) {
    if (!state.cachedOptions.studios.length || force) {
      getStudioOptions()
        .then(response =>
          commit('cacheStudios', response.result.map(el => {
            return {
              text: el.name,
              value: el.id
            }
          })))
        .catch(e => e)
    }
  },

  getDirectorsData ({ commit, state }, force) {
    if (state.cachedOptions.directors.length === 0 || force) {
      getDirectors()
        .then(response => commit('cacheDirectors', response.result))
        .catch(e => e)
    }
  },

  getSuppliersData ({ commit, state }, force) {
    if (!state.cachedOptions.suppliers.length || force) {
      getSuppliers()
        .then(response => commit('cacheSuppliers', response.result))
        .catch(e => e)
    }
  }
}
