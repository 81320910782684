<template>
  <v-sheet>
    <v-progress-linear
      v-if="scenesLoading"
      :indeterminate="true"
      :height="3"
    />
    <v-container
      :class="type === 'edit-scene' ? 'scene-padding' : ''"
      grid-list-md
      fluid
      class="crud-container"
      text-xs-center
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            md12
            :lg6="type !== 'edit-multiple'"
            :lg8="type === 'edit-multiple'"
          >
            <v-layout
              v-if="type !== 'edit-multiple'"
              row
              wrap
            >
              <v-flex
                lg5
                md5
                xs12
              >
                <v-radio-group
                  v-model="movieMeta.disabled"
                  :mandatory="false"
                  row
                >
                  <v-radio
                    :value="false"
                    label="Enabled"
                  />
                  <v-radio
                    :value="true"
                    label="Disabled"
                  />
                </v-radio-group>
                <Ean
                  v-if="type !== 'edit-scene'"
                  :value="movieMeta.auxEans"
                  :rules="rules.fieldRequierd"
                  :loading-check="loadingCheck"
                  :check-ean="checkEan"
                  @updateMovieMeta="movieMetaEditing = deepClone(movieMeta)"
                  @change="(newVal) => { movieMeta.auxEans = newVal }"
                />
                <v-autocomplete
                  v-if="type !== 'edit-scene'"
                  v-model="movieMeta.studio"
                  :items="studios"
                  :search-input.sync="studioSearch"
                  :rules="type !== 'edit-multiple' ? rules.fieldRequierd : []"
                  :required="type !== 'edit-multiple'"
                  box
                  chips
                  color="blue-grey lighten-2"
                  label="Studio"
                >
                  <template #selection="data">
                    <v-chip
                      :selected="data.selected"
                      close
                      class="chip--select-multi"
                      @input="movieMeta.studio = ''"
                    >
                      {{ data.item.text }}
                    </v-chip>
                  </template>
                  <template #item="data">
                    <template>
                      <v-list-tile-content>
                        <v-list-tile-title v-html="data.item.text" />
                      </v-list-tile-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-layout v-if="type !== 'edit-scene'">
                  <v-flex xs8>
                    <v-autocomplete
                      v-model="movieMeta.series"
                      :items="series"
                      :search-input.sync="seriesSearch"
                      box
                      chips
                      color="blue-grey lighten-2"
                      label="Serie"
                      item-text="name"
                      item-value="id"
                      multiple
                    >
                      <template #selection="data">
                        <v-chip
                          :selected="data.selected"
                          close
                          class="chip--select-multi"
                          @input="removeItem(movieMeta.series, data.item.id)"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template #item="data">
                        <template>
                          <v-list-tile-content>
                            <v-list-tile-title v-html="data.item.name" />
                          </v-list-tile-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs2>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          fab
                          dark
                          small
                          color="info"
                          class="ml-1"
                          v-on="on"
                          @click="togglePopupForm(true, 'serie')"
                        >
                          <v-icon
                            right
                            light
                            class="ml-0"
                          >
                            add
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Add Serie</span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex xs2>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          :disabled="seriesLoding"
                          :loading="seriesLoding"
                          fab
                          dark
                          small
                          color="info"
                          class="ml-1"
                          @click="detectSerie"
                          v-on="on"
                        >
                          <v-icon
                            right
                            light
                            class="ml-0"
                          >
                            cached
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Detect serie by Title</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs10>
                    <v-text-field
                      v-model.trim="movieMeta.titleDe"
                      box
                      flat
                      label="Title DE"
                    />
                  </v-flex>
                  <v-flex
                    v-if="(type === 'edit' || type === 'edit-scene') && movieMeta.titleDe"
                    xs2
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          fab
                          dark
                          small
                          color="info"
                          v-on="on"
                          @click.prevent="openFrontendPage('de', movieMeta.titleDe || '')"
                        >
                          <v-icon
                            right
                            light
                            dark
                          >
                            launch
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Movie frontend page (DE)</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs10>
                    <v-text-field
                      v-model.trim="movieMeta.title"
                      :required="type !== 'edit-multiple'"
                      box
                      flat
                      label="Title EN"
                    />
                  </v-flex>
                  <v-flex
                    v-if="(type === 'edit' || type === 'edit-scene') && movieMeta.title"
                    xs2
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          fab
                          dark
                          small
                          color="info"
                          v-on="on"
                          @click.prevent="openFrontendPage('en', movieMeta.title || '')"
                        >
                          <v-icon
                            right
                            light
                            dark
                          >
                            launch
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Movie frontend page (EN)</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs3>
                    <v-text-field
                      v-model.trim="movieMeta.duration"
                      box
                      flat
                      type="number"
                      label="Duration"
                    />
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-if="type !== 'edit-scene'"
                      v-model.trim="movieMeta.numOfScenes"
                      box
                      flat
                      type="number"
                      label="Scenes num."
                    />
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-model.trim="movieMeta.prodYear"
                      box
                      flat
                      type="number"
                      label="Prod. Year"
                    />
                  </v-flex>
                  <v-flex xs3>
                    <v-text-field
                      v-model.trim="movieMeta.releaseYear"
                      box
                      flat
                      type="number"
                      label="Release Y."
                    />
                  </v-flex>
                </v-layout>
                <v-layout v-if="type !== 'edit-scene'">
                  <v-flex xs10>
                    <v-autocomplete
                      v-model="movieMeta.directors"
                      :items="directors"
                      :search-input.sync="directorsSearch"
                      box
                      small-chips
                      chips
                      color="blue-grey lighten-2"
                      label="Directors"
                      item-text="name"
                      item-value="id"
                      multiple
                    >
                      <template #selection="data">
                        <v-chip
                          :selected="data.selected"
                          close
                          class="chip--select-multi"
                          @input="removeItem(movieMeta.directors, data.item.id)"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template #item="data">
                        <template>
                          <v-list-tile-content>
                            <v-list-tile-title v-html="data.item.name" />
                          </v-list-tile-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs2
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          fab
                          dark
                          small
                          color="info"
                          class="ml-1"
                          v-on="on"
                          @click="togglePopupForm(true, 'director')"
                        >
                          <v-icon
                            right
                            light
                            class="ml-0"
                          >
                            add
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Create new Director</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <v-select
                  :items="$staticData.languages"
                  v-model="movieMeta.vodLang"
                  label="VOD Language"
                  clearable
                  box
                />
                <v-layout v-if="type === 'edit'">
                  <v-flex>
                    <v-combobox
                      v-model="movieMeta.tags"
                      hide-no-data
                      deletable-chips
                      dense
                      allow-overflow
                      small-chips
                      hide-details
                      box
                      multiple
                      label="Tags"
                    />
                  </v-flex>
                </v-layout>
                <br>
              </v-flex>
              <v-flex
                lg7
                md6
                xs12
              >
                <v-switch
                  v-model="pornstarMode"
                  :label="pornstarMode ? 'Comma separated names' : 'Search by characters'"
                />
                <v-layout>
                  <v-flex xs10>
                    <v-autocomplete
                      v-model="movieMeta.roles"
                      :items="pornstars"
                      :loading="isLoadingPornstars"
                      :search-input.sync="searchPornstars"
                      small-chips
                      box
                      chips
                      color="blue-grey lighten-2"
                      label="Pornstars"
                      item-text="name"
                      item-value="id"
                      multiple
                    >
                      <template #selection="data">
                        <v-chip
                          :selected="data.selected"
                          close
                          class="chip--select-multi"
                          @input="removeItem(movieMeta.roles, data.item.id);removeItem(selectedPornstars, data.item.id)"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template #item="data">
                        <template>
                          <v-list-tile-content>
                            <v-list-tile-title v-html="data.item.name" />
                          </v-list-tile-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    v-if="type !== 'edit-scene'"
                    xs2
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          :loading="checkPornstarsLoading"
                          fab
                          dark
                          small
                          color="info"
                          class="ml-1"
                          v-on="on"
                          @click="updateSearchData(searchPornstars)"
                        >
                          <v-icon
                            right
                            light
                            class="ml-0"
                          >
                            cached
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Check pornstars</span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex
                    v-if="type !== 'edit-scene'"
                    xs2
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn
                          fab
                          dark
                          small
                          color="info"
                          class="ml-1"
                          v-on="on"
                          @click="togglePopupForm(true, 'pornstar')"
                        >
                          <v-icon
                            right
                            light
                            class="ml-0"
                          >
                            add
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Create new Pornstar</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <v-autocomplete
                  v-if="type !== 'edit-scene'"
                  v-model="featureStar"
                  :disabled="!movieMeta.roles.length"
                  :items="selectedPornstars"
                  small-chips
                  box
                  chips
                  color="blue-grey lighten-2"
                  label="Cover Girls"
                  item-text="name"
                  item-value="id"
                  multiple
                >
                  <template #selection="data">
                    <v-chip
                      :selected="data.selected"
                      close
                      class="chip--select-multi"
                      @input="removeItem(featureStar, data.item.id);removeItem(featureStar, data.item.id)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template #item="data">
                    <template>
                      <v-list-tile-content>
                        <v-list-tile-title v-html="data.item.name" />
                      </v-list-tile-content>
                    </template>
                  </template>
                </v-autocomplete>
                <Editor
                  :value="movieMeta.descrDe"
                  placeholder="Description De"
                  @change="value => movieMeta.descrDe = value"
                />
                <Editor
                  :value="movieMeta.descr"
                  placeholder="Description En"
                  @change="value => movieMeta.descr = value"
                />
              </v-flex>
            </v-layout>
            <v-flex
              v-if="type === 'add' || type === 'edit'"
              xs12
            >
              <v-switch
                v-model="dvdForm"
                label="Enable DVD Creation"
              />
            </v-flex>
            <br v-else>
            <v-flex
              v-if="type !== 'edit-scene'"
              xs12
            >
              <v-layout>
                <v-flex
                  md12
                  lg5
                >
                  <v-layout>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md11="type === 'edit-multiple'"
                      :lg11="type === 'edit-multiple'"
                    >
                      <v-select
                        :items="formats"
                        v-model="movieDvd.format"
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.format : !dvdForm"
                        box
                        label="Product Format"
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.format"
                      />
                    </v-flex>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md11="type === 'edit-multiple'"
                      :lg11="type === 'edit-multiple'"
                    >
                      <v-select
                        :items="$staticData.productStatus"
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.status_new : !dvdForm"
                        v-model="movieDvd.status_new"
                        box
                        label="Product Status"
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.status_new"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md5="type === 'edit-multiple'"
                      :lg5="type === 'edit-multiple'"
                      xs6
                    >
                      <v-text-field
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.num_discs : !dvdForm"
                        v-model.trim="movieDvd.num_discs"
                        label="Number of Discs"
                        type="number"
                        box
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.num_discs"
                      />
                    </v-flex>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md5="type === 'edit-multiple'"
                      :lg5="type === 'edit-multiple'"
                      xs6
                    >
                      <v-text-field
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.running_time : !dvdForm"
                        v-model.trim="movieDvd.running_time"
                        label="Running Time"
                        type="number"
                        box
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.running_time"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md11="type === 'edit-multiple'"
                      :lg11="type === 'edit-multiple'"
                    >
                      <v-select
                        :items="editions"
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.editions : !dvdForm"
                        v-model="movieDvd.editions"
                        box
                        multiple
                        label="Edition"
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.editions"
                      />
                    </v-flex>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md11="type === 'edit-multiple'"
                      :lg11="type === 'edit-multiple'"
                    >
                      <v-select
                        :items="$staticData.languages"
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.languages : !dvdForm"
                        v-model="movieDvd.languages"
                        label="DVD Language"
                        multiple
                        box
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.languages"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs11>
                      <v-autocomplete
                        v-if="type === 'edit-multiple'"
                        v-model="bulkCategories"
                        :disabled="!bulkCheckBoxes"
                        :items="categories"
                        :allow-overflow="false"
                        small-chips
                        color="blue-grey lighten-2"
                        label="⊕ Add Categories"
                        multiple
                        box
                        item-text="name"
                        item-value="id"
                        @keyup.enter="search"
                      >
                        <template #selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            @input="removeItem(bulkCategories, data.item.id)"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <template>
                            <v-list-tile-content v-text="data.item.name" />
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="bulkCheckBoxes"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs11>
                      <v-autocomplete
                        v-if="type === 'edit-multiple'"
                        v-model="bulkCategoriesRemoveList"
                        :disabled="!bulkRemoveCheckBoxes"
                        :items="categories"
                        :allow-overflow="false"
                        small-chips
                        color="blue-grey lighten-2"
                        label="⊖ Remove Categories"
                        multiple
                        box
                        item-text="name"
                        item-value="id"
                        @keyup.enter="search"
                      >
                        <template #selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            @input="removeItem(bulkCategories, data.item.id)"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <template>
                            <v-list-tile-content v-text="data.item.name" />
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="bulkRemoveCheckBoxes"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  md12
                  lg7
                >
                  <v-layout>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md11="type === 'edit-multiple'"
                      :lg11="type === 'edit-multiple'"
                    >
                      <v-autocomplete
                        v-model="movieDvd.supplier_id"
                        :items="suppliers"
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.supplier_id : !dvdForm"
                        :rules="dvdForm ? rules.fieldRequierd : []"
                        box
                        chips
                        color="blue-grey lighten-2"
                        label="Supplier"
                      >
                        <template #selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            @input="movieDvd.supplier_id = ''"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <template>
                            <v-list-tile-content>
                              <v-list-tile-title v-html="data.item.text" />
                            </v-list-tile-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.supplier_id"
                      />
                    </v-flex>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md11="type === 'edit-multiple'"
                      :lg11="type === 'edit-multiple'"
                    >
                      <v-autocomplete
                        v-model="movieDvd.backorder_supplier_id"
                        :items="suppliers"
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.backorder_supplier_id : !dvdForm"
                        box
                        chips
                        color="blue-grey lighten-2"
                        label="Backorder Supplier"
                      >
                        <template #selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            @input="movieDvd.backorder_supplier_id = ''"
                          >
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <template>
                            <v-list-tile-content>
                              <v-list-tile-title v-html="data.item.text" />
                            </v-list-tile-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.backorder_supplier_id"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex
                      :md4="type !== 'edit-multiple'"
                      :lg4="type !== 'edit-multiple'"
                      :md6="type === 'edit-multiple'"
                      :lg6="type === 'edit-multiple'"
                    >
                      <v-text-field
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.purchase_price : !dvdForm"
                        v-model.trim="movieDvd.purchase_price"
                        label="Purchasing Price"
                        type="number"
                        box
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.purchase_price"
                      />
                    </v-flex>
                    <v-flex
                      :md3="type !== 'edit-multiple'"
                      :lg3="type !== 'edit-multiple'"
                      :md4="type === 'edit-multiple'"
                      :lg4="type === 'edit-multiple'"
                      xs3
                    >
                      <v-text-field
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.price : !dvdForm"
                        v-model.trim="movieDvd.price"
                        type="number"
                        label="Price"
                        box
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.price"
                      />
                    </v-flex>
                    <v-flex
                      :md4="type !== 'edit-multiple'"
                      :lg4="type !== 'edit-multiple'"
                      :md5="type === 'edit-multiple'"
                      :lg5="type === 'edit-multiple'"
                      xs4
                    >
                      <v-text-field
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.price_discounted : !dvdForm"
                        v-model.trim="movieDvd.price_discounted"
                        type="number"
                        label="Special Price"
                        box
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.price_discounted"
                      />
                    </v-flex>
                    <v-flex
                      :md3="type !== 'edit-multiple'"
                      :lg3="type !== 'edit-multiple'"
                      :md4="type === 'edit-multiple'"
                      :lg4="type === 'edit-multiple'"
                      xs12
                    >
                      <v-text-field
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.quantity : !dvdForm"
                        v-model.trim="movieDvd.quantity"
                        type="number"
                        label="Stock"
                        box
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.quantity"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                      :md5="type === 'edit-multiple'"
                      :lg5="type === 'edit-multiple'"
                    >
                      <v-text-field
                        :disabled="type === 'edit-multiple' ? !dvdFormFields.manufacturers_code : !dvdForm"
                        v-model.trim="movieDvd.manufacturers_code"
                        box
                        flat
                        label="Product Number"
                      />
                    </v-flex>
                    <v-flex
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="dvdFormFields.manufacturers_code"
                      />
                    </v-flex>
                    <v-flex
                      v-if="type !== 'edit-multiple'"
                      :md6="type !== 'edit-multiple'"
                      :lg6="type !== 'edit-multiple'"
                    >
                      <DatePicker
                        :data="movieDvd"
                        :disabled="!dvdForm"
                        picker-ref="available_from"
                        label="Release Date"
                        @updateSync="val => movieDvd.available_from = val"
                        @updateDate="val => movieDvd.available_from = val"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs11>
                      <v-autocomplete
                        v-if="type === 'edit-multiple'"
                        v-model="bulkTags"
                        :disabled="!bulkTagsEnabled"
                        :items="bulkTagsOptions"
                        :allow-overflow="false"
                        :loading="isLoadingTags"
                        :search-input.sync="searchTags"
                        small-chips
                        color="blue-grey lighten-2"
                        label="⊕ Add Tags"
                        multiple
                        box
                      >
                        <template #selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            @input="removeItem(bulkTags, data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <template>
                            <v-list-tile-content v-text="data.item" />
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex 
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="bulkTagsEnabled"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs11>
                      <v-autocomplete
                        v-if="type === 'edit-multiple'"
                        v-model="bulkTagsRemoveList"
                        :disabled="!bulkRemoveTagsEnabled"
                        :items="bulkTagsOptions"
                        :allow-overflow="false"
                        :loading="isLoadingTags"
                        :search-input.sync="searchTags"
                        small-chips
                        color="blue-grey lighten-2"
                        label="⊖ Remove Tags"
                        multiple
                        box
                      >
                        <template #selection="data">
                          <v-chip
                            :selected="data.selected"
                            close
                            class="chip--select-multi"
                            @input="removeItem(bulkTagsRemoveList, data.item)"
                          >
                            {{ data.item }}
                          </v-chip>
                        </template>
                        <template #item="data">
                          <template>
                            <v-list-tile-content v-text="data.item" />
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex 
                      v-if="type === 'edit-multiple'"
                      md1
                      lg1
                    >
                      <Checkbox
                        classes="checkbox-enabler"
                        v-model="bulkRemoveTagsEnabled"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="movieDvd.st_price && type !== 'edit-multiple'">
                    <v-flex
                      lg4
                      md4
                      sm4
                    >
                      <div class="text-md-center py-3">
                        St Price
                      </div>
                    </v-flex>
                    <v-flex
                      lg8
                      md8
                      sm8
                    >
                      <v-text-field
                        :disabled="!dvdForm"
                        v-model.trim="movieDvd.st_price"
                        readonly
                        flat
                        hide-details
                        single-line
                        reverse
                        box
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="movieDvd.st_availability">
                    <v-flex
                      lg4
                      md4
                      sm4
                    >
                      <div class="text-md-center py-3">
                        St Avail.
                      </div>
                    </v-flex>
                    <v-flex
                      lg8
                      md8
                      sm8
                    >
                      <v-text-field
                        :disabled="!dvdForm"
                        v-model.trim="movieDvd.st_availability"
                        readonly
                        flat
                        hide-details
                        single-line
                        reverse
                        box
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-flex>
          <v-flex
            :lg4="type === 'edit-multiple'"
            :lg6="type !== 'edit-multiple'"
            sm12
            md12
          >
            <CategoriesBoxes
              v-if="type !== 'edit-scene' && type !== 'edit-multiple'"
              :value="movieMeta.categories"
              :categories="categories"
              @change="newVal => { movieMeta.categories = newVal }"
            />
            <br>
            <DataTable
              v-if="type === 'edit-multiple'"
              :items="{ 1: selected }"
              :headers="dvdMultiTableHeader"
              :pagination="dvdMultiTablePagination"
              table-style="table"
            >
              <template #rows="{ item }">
                <td class="text-xs-left">
                  {{ item.id }}
                </td>
                <td class="text-xs-left">
                  {{ item.additionalData && item.additionalData.price }}
                </td>
                <td class="text-xs-left">
                  {{ item.additionalData && item.additionalData.price_discounted }}
                </td>
                <td class="text-xs-left">
                  {{ item.additionalData && item.additionalData.st_price }}
                </td>
                <td class="text-xs-left">
                  {{ item.additionalData && item.additionalData.purchase_price }}
                </td>
                <td class="text-xs-left">
                  {{ item.additionalData && item.additionalData.supplier }}
                </td>
              </template>
            </DataTable>
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
    <StudioRemovalDialog
      :active="studioRemovalDialog"
      @updateActive="val => studioRemovalDialog = val"
      @closedialog="studioRemovalDialog = false"
    />
    <PopupForm
      :active="popupForm"
      :type="popupFormType"
      :studios="studios"
      :rules="rules"
      @updateActive="val => popupForm = val"
      @closepopup="popupForm = false"
      @updateSelectedDirector="val => movieMeta.directors.push(val)"
      @updateSelectedPornstar="(id, name) => updateSelectedPornstar(id, name)"
      @updateSelectedSerie="(studio, title, id) => updateSelectedSerie(studio, title, id)"
    />
    <ActionButtons
      :save="type === 'edit-multiple' ? () => updateMultipleDvds({ close: false, next: false }) : () => save({ close: false, next: false })"
      :close="close"
      :copy="copy"
      :paste="paste"
      :type="type"
      :loading-save="loadingSave"
      :save-and-next="type === 'edit-multiple' ? () => updateMultipleDvds({ close: false, next: false }) : () => save({ close: false, next: true })"
      :save-and-close="type === 'edit-multiple' ? () => updateMultipleDvds({ close: false, next: false }) : () => save({ close: true, next: false })"
      :has-next="type !== 'edit-scene' && type !== 'edit-multiple'"
      :has-copy="!this.copiedMovie && type === 'edit' || (type === 'add' && !!this.copiedMovie)"
      :has-paste="!!this.copiedMovie"
      :clear-copied="clearCopied"
    />
  </v-sheet>
</template>

<script>
import CategoriesBoxes from '../MetaData/FormFields/CategoriesBoxes'
import ActionButtons from '@/components/common/ActionButtons'
import Ean from '../MetaData/FormFields/Ean'
import Editor from '@/components/common/Editor'
import DatePicker from '@/components/common/DatePicker'
import decodeHtml from '@/utils/decodeHtml'
import PopupForm from './FormFields/PopupForm'
import StudioRemovalDialog from './FormFields/StudioRemovalDialog'
import DataTable from '@/components/common/DataTable'
import Checkbox from '@/components/common/Checkbox'
import { crudObjectHelpers } from '@/mixins/crudComponents'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { getProduct, updateProduct, updateMultipleProducts, createProduct } from '@/services/product.service'
import { getTagOptions } from '@/services/tags.service'
import { detectByTitle, getSeriesById } from '@/services/series.service'
import { manageBulkMovieCategories } from '@/services/categories.service'
import { manageBulkMovieTags } from '@/services/tags.service'
import { updateMovie, createMovie, updateMovieScene, getMovieDetailsByEan, getMovieDetails } from '@/services/movie.service'
import { getPornstarOptions, getPornstarsOptionsByNames } from '@/services/pornstar.service'
import { filterObject, clearData, deepClone, slugify } from '@/utils/object'
import { errorHandler } from '@/utils/error'

export default {
  components: {
    ActionButtons,
    Ean,
    CategoriesBoxes,
    Editor,
    DatePicker,
    DataTable,
    PopupForm,
    StudioRemovalDialog,
    Checkbox
  },
  props: {
    nextTab: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    },
    overlay: {
      type: Boolean,
      default: false
    },
    allTabs: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: Number,
      default: 0
    },
    crudTabs: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Array,
      default: () => []
    },
    checkImages: {
      type: Function,
      default: () => {}
    },
  },
  mixins: [crudObjectHelpers],
  data () {
    return {
      name: 'Metadata',
      valid: true,
      dvdForm: false,
      pornstarMode: true,
      popupForm: false,
      popupFormType: 'serie',
      studioRemovalDialog: false,
      dvdMultiTableHeader: [
        { text: 'ID', width: '60px' },
        { text: 'Price', width: '60px' },
        { text: 'S. Price', width: '60px' },
        { text: 'St. Price', width: '60px' },
        { text: 'Purchase p.', width: '60px' },
        { text: 'Supplier', width: '100px' }
      ],
      dvdMultiTablePagination: {
        order: 'desc',
        descending: true,
        page: 1,
        rowsPerPage: 50,
        orderBy: 'id',
        totalItems: 0,
        totalPages: 0,
        pagesLoaded: new Set()
      },
      editions: [
        'Jewel Case',
        'Collector\'s Edition',
        'Limited Edition',
        'Box Set',
        'Special Edition'
      ],
      formats: [
        { value: 'dvd', text: 'DVD' },
        { value: 'blu-ray', text: 'Blu-Ray' },
        { value: 'dvd+blu-ray', text: 'DVD & Blu-Ray' },
        { value: 'hddvd', text: 'HD DVD' }
      ],
      rules: {
        fieldRequierd: [
          v => !!v || 'This field is required'
        ]
      },
      loadingCreate: false,
      isLoadingPornstars: false,
      checkPornstarsLoading: false,
      seriesLoding: false,
      loadingSave: false,
      loadingCheck: false,
      scenesLoading: false,
      featureStar: [],
      selectedPornstars: [],
      directorsSearch: '',
      pornstars: [],
      studioSearch: '',
      series: [],
      seriesSearch: '',
      timeoutPornstars: null,
      searchPornstars: '',
      bulkCheckBoxes: false,
      bulkRemoveCheckBoxes: false,
      bulkCategoriesAdd: true,
      bulkCategories: [],
      bulkCategoriesRemoveList: [],
      bulkTagsEnabled: false,
      bulkRemoveTagsEnabled: false,
      bulkTagsAdd: true,
      isLoadingTags: false,
      timeoutTags: null,
      bulkTags: [],
      bulkTagsRemoveList: [],
      bulkTagsOptions: [],
      searchTags: '',
      movieMeta: {
        id: null,
        title: '',
        titleDe: '',
        descr: '',
        descrDe: '',
        auxEans: [{ value: '' }],
        studio: '',
        categories: [],
        directors: [],
        series: [],
        tags: [],
        roles: [],
        duration: '',
        prodYear: '',
        releaseYear: '',
        numOfScenes: null,
        disabled: false,
        vodLang: null
      },
      movieMetaEditing: null,
      movieDvd: {
        id: null,
        status_new: 2,
        format: 'dvd',
        num_discs: null,
        editions: [],
        running_time: null,
        languages: [],
        manufacturers_code: null,
        supplier_id: null,
        backorder_supplier_id: null,
        purchase_price: null,
        price: null,
        price_discounted: null,
        available_from: null,
        quantity: null,
        minimum_quantity: null,
        st_availability: null,
        st_price: null
      },
      movieDvdEditing: null,
      dvdFormFields: {
        format: false,
        status_new: false,
        num_discs: false,
        editions: false,
        running_time: false,
        languages: false,
        release_year: false,
        manufacturers_code: false,
        supplier_id: false,
        backorder_supplier_id: false,
        purchase_price: false,
        price: false,
        price_discounted: false,
        quantity: false,
        available_from: false,
        minimum_quantity: false,
        st_availability: false,
        st_price: false
      }
    }
  },
  computed: {
    ...mapGetters({
      studios: 'cachedStudios',
      directors: 'cachedDirectors',
      categories: 'cachedCategories',
      suppliers: 'cachedSuppliers',
      savedMovie: 'movies/savedMovie',
      type: 'movies/crudType',
      selectedScene: 'movies/selectedScene',
      copiedMovie: 'movies/movieCopy',
    }),
  },
  watch: {
    overlay: {
      handler (val) {
        if (val) {
          if (this.allTabs[this.activeTab] === this.name && !this.crudTabs[this.name].unsaved) {
            switch (this.type) {
              case 'add':
                clearData.call(this)
                break
              case 'edit':
                clearData.call(this)
                if (this.selectedScene) {
                  this.updateCrudType('edit-scene')
                  this.fillSceneEditing(this.savedMovie.scenes[this.selectedScene])
                } else {
                  this.fillMetaData(this.savedMovie)
                }
                this.initializeDvdData()
                break
              case 'edit-multiple':
                clearData.call(this)
                this.dvdForm = true
                break
            }
          }
        } else {
          // fix for Ckeditor not clearing html entities
          this.movieMeta.descr = decodeHtml(this.movieMeta.descr)
          this.movieMeta.descrDe = decodeHtml(this.movieMeta.descrDe)
          switch (this.type) {
            case 'add':
              if ((JSON.stringify(filterObject(this.movieMeta)) !== JSON.stringify(filterObject(this.$options.data().movieMeta))) || this.dvdForm) {
                this.handlePrompt()
              }
              break
            case 'edit':
              if ((JSON.stringify(this.movieMeta) !== this.decodeText(JSON.stringify(this.movieMetaEditing))) || (this.dvdForm && JSON.stringify(this.movieDvd) !== JSON.stringify(this.movieDvdEditing))) {
                this.handlePrompt()
              }
              break
            case 'edit-scene':
              if (JSON.stringify(this.movieMeta) !== this.decodeText(JSON.stringify(this.movieMetaEditing))) {
                this.handlePrompt()
              }
              break
            case 'edit-multiple':
              if (JSON.stringify(this.dvdFormFields) !== JSON.stringify(this.$options.data().dvdFormFields)) {
                this.handlePrompt()
              }
              break
          }
        }
      },
      immediate: true
    },
    type (newVal, oldVal) {
      if (oldVal === 'edit' && newVal === 'add') {
        this.resetValidation()
      }
      // when from adding movie, user added scenes for that movie, this will enable scene menu
      if (oldVal === 'add' && newVal === 'edit' && this.movieMeta.numOfScenes > 0) {
        getMovieDetails(this.movieMeta.id)
          .then(response => this.updateSavedMovie(response.result))
          .catch(errorHandler.bind(this))
      }
    },
    dvdForm (val) {
      if (val) {
        this.getSuppliersData()
      }
    },
    searchPornstars (newVal) {
      if (!this.pornstarMode) {
        if (!this.timeoutPornstars) {
          this.timeoutPornstars = setTimeout(() => {
            if (newVal) {
              this.isLoadingPornstars = true
              this.updateSearchData(newVal)
            }
          }, 500)
        } else {
          clearTimeout(this.timeoutPornstars)
          this.timeoutPornstars = setTimeout(() => {
            if (newVal) {
              this.isLoadingPornstars = true
              this.updateSearchData(newVal)
            }
          }, 500)
        }
      }
    },
    'movieMeta.series' () {
      this.seriesSearch = ''
    },
    'movieMeta.studio' (val) {
      this.studioSearch = ''
      if (val) this.fillSeriesData(val)
      else {
        this.seriesSearch = ''
        this.movieMeta.series.length = 0
        if (this.type === 'edit' && this.overlay) {
          this.studioRemovalDialog = true
        }
      }
    },
    'movieMeta.directors' () {
      this.directorsSearch = ''
    },
    'movieMeta.roles' (val) {
      if (val.length > this.selectedPornstars.length) {
        this.selectedPornstars.push(this.pornstars.find(el => el.id === val[val.length - 1]))
      }
      this.searchPornstars = ''
    },
    selectedScene (newVal) {
      this.scenesLoading = true
      if (this.type === 'edit-scene' && newVal && this.savedMovie) {
        clearData.call(this)
        this.fillSceneEditing(this.savedMovie.scenes[newVal])
      } else if (this.type !== 'edit-scene' && !newVal && this.savedMovie) {
        clearData.call(this)
        this.fillMetaData(this.savedMovie)
        // you can escape additional api call here by caching dvd data of movie.
        this.initializeDvdData()
      }
    },
    searchTags (newVal) {
      if (this.timeoutTags) clearTimeout(this.timeoutTags)
      this.timeoutTags = setTimeout(() => {
        if (newVal && newVal.length >= 2) {
          this.isLoadingTags = true
          this.getTags(newVal)
        }
      }, 500)
    }
  },
  created () {
    this.getStudioOptionsData()
    this.getCategoriesOptionsData()
    this.getDirectorsData()
    this.getSuppliersData()
  },
  methods: {
    ...mapActions([
      'getStudioOptionsData',
      'getCategoriesOptionsData',
      'getDirectorsData',
      'getSuppliersData'
    ]),
    ...mapMutations({
      setPrompt: 'setPrompt',
      copyMovie: 'movies/mutateMovieCopy',
      updateSavedMovie: 'movies/mutateMovieData',
      updateCrudType: 'movies/mutateCrudType'
    }),
    initializeDvdData () {
      if (this.savedMovie) {
        getProduct(this.savedMovie.id)
          .then(data => {
            this.dvdForm = true
            this.fillDvdData(data.result[this.savedMovie.id])
          })
          .catch(() => {
            this.dvdForm = false
            this.$notify({
              type: 'error',
              title: `DVD for ${this.savedMovie.id}`,
              text: 'Not found'
            })
          })
      }
    },
    save (options) {
      if (this.$refs.form.validate()) {
        if (!(this.movieMeta.title || this.movieMeta.titleDe)) {
          this.$notify({
            type: 'error',
            title: 'Meta titles',
            text: 'One of the titles are required'
          })
          return
        }
        this.loadingSave = true
        if (this.searchPornstars) {
          this.updateSearchData(this.searchPornstars)
        }
        let data
        if (this.type === 'edit-scene') {
          data = {}
          data.title = this.movieMeta.title
          data.disabled = this.movieMeta.disabled
          if (this.movieMeta.titleDe) data.titleDe = this.movieMeta.titleDe
          if (this.movieMeta.descr) data.descr = decodeHtml(this.movieMeta.descr)
          if (this.movieMeta.descrDe) data.descrDe = decodeHtml(this.movieMeta.descrDe)
          if (this.movieMeta.duration) data.duration = this.movieMeta.duration
          if (this.movieMeta.prodYear) data.prodYear = this.movieMeta.prodYear
          if (this.movieMeta.releaseYear) data.releaseYear = this.movieMeta.releaseYear
          if (this.movieMeta.roles.length) {
            data.stars = []
            this.movieMeta.roles.forEach(role => data.stars.push(role))
          }
          updateMovieScene(this.savedMovie.id, this.selectedScene, data)
            .then(({ result }) => {
              this.loadingSave = false
              this.movieMetaEditing = deepClone(this.movieMeta)
              this.updateSavedMovie(result)
              this.$emit('hasSaved', 'Metadata')
              if (options.next) this.nextTab()
              if (options.close) this.close()
              this.$notify({
                type: 'success',
                title: `Scene ${this.selectedScene}`,
                text: 'Updated'
              })
            })
            .catch(errorHandler.bind(this))
          return false
        }
        data = deepClone(this.movieMeta)
        // fix for ckeditor adding html endities
        data.descr = decodeHtml(data.descr)
        data.descrDe = decodeHtml(data.descrDe)

        if (this.type !== 'edit-multiple') {
          if (this.movieMeta.auxEans.length > 1) {
            delete data.auxEans
            data.auxEans = []
            data.ean = this.movieMeta.auxEans[0].value
            for (let i = 1; i < this.movieMeta.auxEans.length; i++) {
              data.auxEans.push(this.movieMeta.auxEans[i].value)
            }
          } else {
            data.ean = this.movieMeta.auxEans[0].value
            delete data.auxEans
          }
        } else {
          delete data.auxEans
          delete data.ean
        }
        if (data.roles) {
          data.roles = data.roles.map(role => {
            return { starId: role }
          })
        }
        if (this.featureStar.length && data.roles.length) {
          data.roles.map(role => this.featureStar.find(id => id === role.starId)).forEach(star => {
            if (star) {
              data.roles.find(role => role.starId === star).movieFeatureStar = true
            }
          })
        }
        if (!this.movieMeta.title) {
          data.title = this.movieMeta.titleDe
        }

        // fix for number of scenes sending 0 or null to backend.
        if (this.movieMeta.numOfScenes === null || this.movieMeta.numOfScenes === 0) {
          delete data.numOfScenes
        }

        if (this.type === 'add') {
          createMovie(data)
            .then(({ result }) => {
              // very important to add all needed properties in order for vuex and other tabs to work.
              this.updateSavedMovie({
                ean: this.movieMeta.auxEans[0].value,
                id: result.id,
                auxEANs: [],
                pictures: {},
                roles: [],
                numberOfScenes: 0,
                trailers: {}
              })
              this.$notify({
                type: 'success',
                title: 'New Movie',
                text: result.message
              })
              this.movieMeta.id = result.id
              this.$notify({
                type: 'success',
                title: `Covers search on: ${window.BaseCoverUrl}`
              })
              this.movieMetaEditing = deepClone(this.movieMeta)
              if (this.dvdForm) {
                this.saveDvd()
                  .then(() => {
                    this.updateCrudType('edit')
                    this.$router.replace({ query: { ...this.$route.query, mode: 'edit', id: result.id } }).catch(() => {})
                    this.$emit('refreshTable')
                    this.$emit('hasSaved', 'Metadata')
                    this.checkImages(this.movieMeta.auxEans.map(el => el.value), result.id)
                      .then(() => {
                        this.loadingSave = false
                        if (options.close) this.close()
                        if (options.next) this.nextTab()
                      })
                      .catch(errorHandler.bind(this))
                  })
                  .catch(errorHandler.bind(this))
              } else {
                this.updateCrudType('edit')
                this.$router.replace({ query: { ...this.$route.query, mode: 'edit', id: result.id } }).catch(() => {})
                this.$emit('refreshTable')
                this.$emit('hasSaved', 'Metadata')
                this.checkImages(this.movieMeta.auxEans.map(el => el.value), result.id)
                  .then(() => {
                    this.loadingSave = false
                    if (options.close) this.close()
                    if (options.next) this.nextTab()
                  })
                  .catch(errorHandler.bind(this))
              }
            })
            .catch(errorHandler.bind(this))
        } else if (this.type === 'edit') {
          // fix if movie number of scenes is not changed on edit form during submit.
          if (this.movieMetaEditing.numOfScenes === this.movieMeta.numOfScenes) {
            delete data.numOfScenes
          }
          updateMovie(this.savedMovie.id, data)
            .then(({ result }) => {
              // if user change number of scenes on update, then re-fetch movie data so it will re-render new scenes
              if (this.savedMovie.numOfScenes !== this.movieMeta.numOfScenes) {
                getMovieDetails(this.movieMeta.id)
                  .then(response => this.updateSavedMovie(response.result))
                  .catch(errorHandler.bind(this))
              }
              this.$notify({
                type: 'success',
                title: `Movie ${this.savedMovie.id}`,
                text: result.message
              })
              this.movieMetaEditing = deepClone(this.movieMeta)
              if (this.dvdForm) {
                this.saveDvd()
                  .then(() => {
                    this.$emit('refreshTable')
                    this.$emit('hasSaved', 'Metadata')
                    this.loadingSave = false
                    if (options.next) this.nextTab()
                    if (options.close) this.close()
                  })
                  .catch(errorHandler.bind(this))
              } else {
                this.$emit('refreshTable')
                this.$emit('hasSaved', 'Metadata')
                this.loadingSave = false
                if (options.next) this.nextTab()
                if (options.close) this.close()
              }
            })
            .catch(errorHandler.bind(this))
        }
      } else {
        this.$notify({
          type: 'error',
          title: 'Add Movie',
          text: 'Fill required fields'
        })
      }
    },
    saveDvd () {
      return new Promise(async (resolve, reject) => {
        const data = deepClone(this.movieDvd)
        delete data.st_availability
        delete data.st_price
        delete data.id

        if (this.movieDvd.id) {
          try {
            await updateProduct(this.movieDvd.id, data, 0)
            this.$notify({
              type: 'success',
              title: `DVD ${this.movieDvd.id}`,
              text: 'Record Updated'
            })
            this.movieDvdEditing = deepClone(this.movieDvd)
            resolve()
          } catch (e) { reject(e) }
        } else {
          try {
            const response = await createProduct({ movielibId: this.movieMeta.id })
            if (response.result.minimum_quantity) data.minimum_quantity = response.result.minimum_quantity
            this.movieDvd.id = response.result.id
            this.$notify({
              type: 'success',
              title: `DVD ${response.result.id}`,
              text: 'Record Created'
            })
            if (response.result.id) {
              await updateProduct(response.result.id, data, 1)
              this.$notify({
                type: 'success',
                title: `DVD ${this.movieDvd.id}`,
                text: 'Record Updated'
              })
              this.movieDvdEditing = deepClone(this.movieDvd)
              resolve()
            }
          } catch (e) { reject(e) }
        }
      })
    },
    updateMultipleDvds (options) {
      this.loadingSave = true
      const data = {}

      Object.keys(this.movieDvd).forEach(key => {
        if (this.dvdFormFields[key]) {
          data[key] = this.movieDvd[key]
        }
      })

      if (Object.keys(data).length) {
        updateMultipleProducts(this.selected.map(el => el.ean), data)
          .then(() => {
            this.$notify({
              type: 'success',
              title: `DVDs ${this.selected.map(el => el.ean)}`,
              text: 'Records Update'
            })
            this.movieDvdEditing = deepClone(this.movieDvd)
            this.updateCrudType('edit-multiple')
            this.$emit('refreshTable')
            this.$emit('hasSaved', 'Metadata')
            }
         )
         .catch(errorHandler.bind(this))
      }
              // Perform bulk operations
                if (this.bulkCheckBoxes && this.bulkCategories.length) {
                    this.manageBulkOperation(
                      {
                        categories: this.bulkCategories,
                        add: true
                      }
                    );
                }
                if (this.bulkRemoveCheckBoxes && this.bulkCategoriesRemoveList.length) {
                  this.manageBulkOperation(
                    {
                      categories: this.bulkCategoriesRemoveList,
                    }
                  );
                }
                if (this.bulkTagsEnabled && this.bulkTags.length) {
                  this.manageBulkOperation(
                    {
                      tags: this.bulkTags,
                      add: true
                    }
                  );
                }

                if (this.bulkRemoveTagsEnabled && this.bulkTagsRemoveList.length) {
                  this.manageBulkOperation(
                    {
                      tags: this.bulkTagsRemoveList,
                    }
                  );
                }
             if (options.close) this.close()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    updateSearchData (val) {
      if (!val) {
        this.$notify({
          type: 'error',
          title: 'Fill Pornstars Field'
        })
        return
      }
      if (this.pornstarMode) {
        this.checkPornstarsLoading = true
        this.$notify({
          type: 'success',
          title: 'Pornstars search...'
        })
        getPornstarsOptionsByNames({ names: val })
          .then(result => {
            this.$notify({
              type: 'success',
              title: 'Pornstars found'
            })
            result.result.sort((a, b) => a.name.localeCompare(b.name)).forEach(star => {
              this.pornstars.push(star)
              this.movieMeta.roles.push(star.id)
              this.selectedPornstars.push(star)
            })
            this.isLoadingPornstars = false
            this.checkPornstarsLoading = false
          })
          .catch(errorHandler.bind(this))
      } else {
        getPornstarOptions(val)
          .then(({ result }) => {
            result.sort((a, b) => a.name.localeCompare(b.name)).forEach(star => {
              this.pornstars.push(star)
            })
            this.isLoadingPornstars = false
          })
          .catch(errorHandler.bind(this))
      }
    },
    updateSelectedPornstar (id, name) {
      this.pornstars.push({ id, name })
      this.selectedPornstars.push({ id, name })
      this.movieMeta.roles.push(id)
    },
    checkEan () {
      this.loadingCheck = true
      getMovieDetailsByEan(this.movieMeta.auxEans[0].value)
        .then(response => {
          this.$router.replace({ query: { ...this.$route.query, mode: 'edit', id: response.result.id } })
          this.updateSavedMovie(response.result)
          this.fillMetaData(response.result)
          this.loadingCheck = false
          this.$notify({
            type: 'success',
            title: `Movie ${response.result.ean}`,
            text: 'Was found'
          })
          this.updateCrudType('edit')
          this.$router.replace({ query: { ...this.$route.query, mode: 'edit', id: response.result.id } }).catch(() => {})
          this.initializeDvdData()
        })
        .catch(e => {
          if (e.code === 404) {
            this.loadingCheck = false
            this.$notify({
              type: 'error',
              title: `Movie ${this.movieMeta.auxEans[0].value}`,
              text: 'Ean doesn\'t exist'
            })
          } else {
            errorHandler.call(this, e)
          }
        })
    },
    detectSerie () {
      if (this.movieMeta.title && this.movieMeta.studio) {
        this.seriesLoding = true
        detectByTitle(this.movieMeta.title)
          .then(({ result }) => {
            // this.seriesLoding = false
            if (result.length) {
              const validSeries = result.filter(el => el.studio.id === this.movieMeta.studio)
              if (validSeries.length) {
                this.$notify({
                  type: 'succsess',
                  title: 'Serie Detector',
                  text: `${validSeries.length} series detected`
                })
                validSeries.forEach(el => {
                  this.series.push({ name: el.title, id: el.id })
                  this.movieMeta.series.push(el.id)
                })
              } else {
                this.$notify({
                  type: 'warn',
                  title: 'Serie Detector:',
                  text: 'No serie detected'
                })
              }
            } else {
              this.$notify({
                type: 'warn',
                title: 'Serie Detector:',
                text: 'No serie detected'
              })
            }
          })
          .catch(errorHandler.bind(this))
      } else {
        this.$notify({
          type: 'error',
          title: 'Serie Detector:',
          text: 'Fill up EN Title and Studio fields'
        })
      }
    },
    updateSelectedSerie (studio, name, id) {
      this.movieMeta.studio = studio
      this.series.push({ id, name })
      this.movieMeta.series.push(id)
    },
    updateMovieTags (tags) {
      this.movieMeta.tags.splice(0, this.movieMeta.tags.length)
      tags.forEach(tag => this.movieMeta.tags.push(tag))
      this.movieMetaEditing = deepClone(this.movieMeta)
    },
    fillMetaData (data) {
      this.movieMeta.id = data.id
      this.movieMeta.disabled = data.disabled
      this.movieMeta.studio = data.studio.id
      this.fillSeriesData(this.movieMeta.studio)
      this.movieMeta.title = data.title
      if (data.descr) {
        if (data.descr.startsWith('<p>')) {
          this.movieMeta.descr = data.descr
        } else {
          this.movieMeta.descr = '<p>' + data.descr + '</p>'
        }
      } else this.movieMeta.descr = ''
      if (data.translations.de) {
        this.movieMeta.titleDe = data.translations.de.title
        if (data.translations.de.descr) {
          if (data.translations.de.descr.startsWith('<p>')) {
            this.movieMeta.descrDe = data.translations.de.descr
          } else {
            this.movieMeta.descrDe = '<p>' + data.translations.de.descr + '</p>'
          }
        } else this.movieMeta.descrDe = ''
      } else {
        this.movieMeta.titleDe = ''
        this.movieMeta.descrDe = ''
      }
      this.movieMeta.duration = data.duration
      this.movieMeta.prodYear = data.prodYear
      this.movieMeta.releaseYear = data.releaseYear
      this.movieMeta.numOfScenes = data.numOfScenes
      this.movieMeta.vodLang = data.vodLang
      this.movieMeta.auxEans.length = 0
      if (data.ean) {
        this.movieMeta.auxEans.push({ value: data.ean })
      }
      data.categories.forEach(el => {
        this.movieMeta.categories.push(el.id)
      })
      this.movieMeta.series.length = 0
      data.series.forEach(el => {
        this.movieMeta.series.push(el.id)
      })
      this.movieMeta.directors.length = 0
      data.directors.forEach(el => {
        this.movieMeta.directors.push(el.id)
      })
      this.movieMeta.roles.length = 0
      data.roles.forEach(el => {
        this.pornstars.push(el.star)
        this.selectedPornstars.push(el.star)
        this.movieMeta.roles.push(el.star.id)
        if (el.movieFeatureStar) {
          this.featureStar.push(el.star.id)
        }
      })
      if (data.auxEANs.length) {
        data.auxEANs.forEach(ean => {
          this.movieMeta.auxEans.push({ value: ean })
        })
      }
      data.tags.forEach(tag => this.movieMeta.tags.push(tag))
      this.scenesLoading = false
      this.movieMetaEditing = deepClone(this.movieMeta)
    },
    fillDvdData (data) {
      this.movieDvd.id = data.id
      this.movieDvd.status_new = data.status_new
      this.movieDvd.format = data.format
      this.movieDvd.num_discs = data.num_discs
      this.movieDvd.editions.length = 0
      data.editions.forEach(el => this.movieDvd.editions.push(el))
      this.movieDvd.running_time = data.running_time
      if (data.languages) data.languages.forEach(lang => this.movieDvd.languages.push(lang))
      this.movieDvd.manufacturers_code = data.manufacturers_code
      this.movieDvd.supplier_id = data.supplier_id
      if (data.available_from) {
        this.movieDvd.available_from = data.available_from.split(' ').length ? data.available_from.split(' ')[0] : data.available_from
      }
      /* Warning: api workaround
       * Api returns backorder_supplier_id = 0, if its empty
       * but 0 is valid id, and will be checked
       * instead we want to have null
       * Could cause a bug, when there is a legit backorder_supplier_id  with the id 0
       */
      this.movieDvd.backorder_supplier_id = data.backorder_supplier_id ? data.backorder_supplier_id : null
      this.movieDvd.purchase_price = data.purchase_price
      this.movieDvd.price = data.price
      this.movieDvd.price_discounted = data.price_discounted
      this.movieDvd.quantity = data.quantity
      this.movieDvd.minimum_quantity = data.minimum_quantity
      this.movieDvd.st_availability = data.st_availability
      this.movieDvd.st_price = data.st_price
      this.movieDvdEditing = deepClone(this.movieDvd)
    },
    fillSceneEditing (data) {
      this.movieMeta.title = data.title
      this.movieMeta.duration = data.duration
      this.movieMeta.prodYear = data.prodYear
      this.movieMeta.releaseYear = data.releaseYear
      this.movieMeta.disabled = data.disabled
      if (data.descr) {
        if (data.descr.startsWith('<p>')) {
          this.movieMeta.descr = data.descr
        } else {
          this.movieMeta.descr = '<p>' + data.descr + '</p>'
        }
      } else this.movieMeta.descr = ''
      if (!Array.isArray(data.translations) && !data.translations.length) {
        this.movieMeta.titleDe = data.translations.de.title
        if (data.translations.de.descr) {
          if (data.translations.de.descr.startsWith('<p>')) {
            this.movieMeta.descrDe = data.translations.de.descr
          } else {
            this.movieMeta.descrDe = '<p>' + data.translations.de.descr + '</p>'
          }
        } else this.movieMeta.descrDe = ''
      } else {
        this.movieMeta.titleDe = ''
        this.movieMeta.descrDe = ''
      }
      this.movieMeta.roles.length = 0
      this.savedMovie.roles.forEach(el => {
        this.pornstars.push(el.star)
        if (el.scenes.includes(this.selectedScene)) {
          this.movieMeta.roles.push(el.star.id)
        }
      })
      this.scenesLoading = false
      this.movieMetaEditing = deepClone(this.movieMeta)
    },
    fillSeriesData (id) {
      getSeriesById(id)
        .then(({ result }) => {
          this.series = result.map(el => {
            return { name: el.title, id: el.id }
          })
        })
        .catch(errorHandler.bind(this))
    },
    async getTags (val) {
      if (!val) {
        this.$notify({
          type: 'error',
          title: 'Fill Tags Field'
        })
        return
      }
      try {
        const response = await getTagOptions(val)
        response.result.forEach(tag => {
          this.bulkTagsOptions.push(tag.name)
        })
        this.isLoadingTags = false
      } catch (e) { errorHandler(e).bind(this) }
    },
    copy () {
      this.copyMovie({ ...this.savedMovie })
    },
    paste () {
      this.fillMetaData(this.copiedMovie)
    },
    clearCopied () {
      this.copyMovie(null)
    },
    togglePopupForm (active, type) {
      this.popupForm = active
      this.popupFormType = type
    },
    decodeText (html) {
      const txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    slugify,
    openFrontendPage (lang, title) {
      let link = 'https://erotik.com/'
      if (lang === 'en') {
        link += 'en/'
      }
      link += slugify(this.studios.find(el => el.value === this.savedMovie.studio.id).text)
      link += '/'
      link += slugify(title)
      window.open(link, "_blank")
    },
    // Takes categories or tags to update multiple movies at once
    manageBulkOperation(options) {
      return new Promise((resolve, reject) => {
        const useCategories = options.categories !== undefined && options.categories !== null;
        const tagsOrCategories = useCategories ? options.categories : options.tags;
        const addItems = !!options.add;
        const titleMsg = useCategories ? 'Categorie(s)' : 'Tag(s)'
        const manageBulkFunction = useCategories ? manageBulkMovieCategories : manageBulkMovieTags;

        manageBulkFunction(this.selected.map(el => el.id), tagsOrCategories, addItems)
          .then(() => {
            this.loadingSave = false;
            this.$notify({
              type: 'success',
              title: titleMsg,
              text: `${titleMsg} ${addItems ? "added" : "removed"}`
            });
            resolve();
          })
          .catch((error) => {
            this.loadingSave = false;
            reject(error);
          });
      }).catch(errorHandler.bind(this));
    },
    handlePrompt () {
      this.setPrompt({
        title: 'Unsaved form',
        message: 'You have unsaved Metadata form, do you want do delete form fields data?',
        confirmMethod: function () {
          this.$emit('hasUnsaved', 'Metadata')
          clearData.call(this)
          this.resetValidation()
        }.bind(this),
        cancelMethod: function () {
          const query = { ...this.$route.query }
          query.mode = this.type
          if (this.type === 'edit') query.id = this.savedMovie.id
          this.$router.replace({ query }).catch(() => {})
          this.$emit('hasUnsaved', 'Metadata')
          this.close()
        }.bind(this)
      })
    }
  }
}
</script>
