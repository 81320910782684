import axios from 'axios';

// Create an instance of axios with the DeepL API base URL
const deeplApi = axios.create({
  baseURL: 'https://deepl-tt-93ytbh0c4qe5.deno.dev',
  headers: {
    'accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

/**
 * Translate text using DeepL API.
 * @param {string} text - The text to be translated.
 * @param {string} source_lang - The source language code (e.g., "en").
 * @param {string} target_lang - The target language code (e.g., "de").
 * @returns {Promise} - A Promise that resolves to the translation result.
 */
export const translate = (text, source_lang, target_lang) => {
  const target = target_lang.toLowerCase() === "en" ? "en-GB" : target_lang.toLowerCase();
  const data = {
    text,
    source_lang: source_lang.toLowerCase(),
    target_lang: target,
    split_sentences: true,
    preserve_formatting: true,
    formality: "default"
  };

  return deeplApi.post('/', data)
    .then(response => response.data)
    .catch(error => {
      console.error('Error during translation:', error);
      throw error;
    });
};