<template>
  <v-dialog
    :ref="type"
    :value="active"
    persistent
    full-width
    width="500px"
    @change="val => $emit('updateActive', val)"
  >
    <v-sheet>
      <v-container
        grid-list-md
        fluid
        text-xs-center
      >
        <h3 class="heading text-lg-left text-md-left">
          Manage Movie {{ type }}
        </h3>
        <v-btn
          icon
          dark
          absolute
          round
          right
          class="close-btn"
          @click="$emit('closepopup')"
        >
          <v-icon class="close-icon">
            close
          </v-icon>
        </v-btn>
        <hr><br>
        <v-form
          ref="popupform"
          v-model="formValid"
        >
          <v-autocomplete
            v-if="type === 'serie'"
            v-model="serieStudio"
            :items="studios"
            :rules="rules.fieldRequierd"
            :required="true"
            box
            chips
            color="blue-grey lighten-2"
            label="Select Studio"
          >
            <template #selection="data">
              <v-chip
                :selected="data.selected"
                close
                class="chip--select-multi"
                @input="serieStudio = ''"
              >
                {{ data.item.text }}
              </v-chip>
            </template>
            <template #item="data">
              <template>
                <v-list-tile-content>
                  <v-list-tile-title v-html="data.item.text" />
                </v-list-tile-content>
              </template>
            </template>
          </v-autocomplete>
          <v-text-field
            v-if="type === 'serie'"
            v-model.trim="serieTitle"
            :rules="rules.fieldRequierd"
            :required="true"
            label="New Serie name"
            box
          />
          <v-text-field
            v-if="type === 'pornstar'"
            v-model.trim="pornstarName"
            :rules="rules.fieldRequierd"
            :required="true"
            label="Pornstar Name"
            box
          />
          <v-select
            v-if="type === 'pornstar'"
            v-model="pornstarGender"
            :rules="rules.fieldRequierd"
            :required="true"
            :items="$staticData.performerData.gender"
            label="Gender"
            box
            clearable
          />
          <v-text-field
            v-if="type === 'director'"
            :rules="rules.fieldRequierd"
            :required="true"
            v-model.trim="directorName"
            label="Director Name"
            box
          />
        </v-form>
        <v-flex
          xs12
          text-xs-right
        >
          <v-btn
            :loading="popupFormLoading"
            :disabled="popupFormLoading"
            color="info"
            class="mx-1 white--text"
            @click="submitForm"
          >
            Save
          </v-btn>
          <v-btn
            color="info"
            class="mx-1 white--text"
            @click="$emit('closepopup')"
          >
            close
          </v-btn>
        </v-flex>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { createPornstar } from '@/services/pornstar.service'
import { createSerie } from '@/services/series.service'
import { createDirector } from '@/services/directors.service'
import { errorHandler } from '@/utils/error'
import { clearData } from '@/utils/object'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'serie'
    },
    studios: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formValid: true,
      popupFormLoading: false,
      serieTitle: '',
      serieStudio: '',
      pornstarName: '',
      pornstarGender: '',
      directorName: ''
    }
  },
  computed: {
    ...mapGetters({
      savedMovie: 'movies/savedMovie'
    })
  },
  watch: {
    active (newVal, oldVal) {
      if (!oldVal && newVal) this.$refs.popupform.resetValidation()
    }
  },
  methods: {
    ...mapActions(['getDirectorsData']),
    submitForm () {
      if (this.$refs.popupform.validate()) {
        switch (this.type) {
          case 'serie':
            this.addNewSerie(this.serieTitle, this.serieStudio)
            break
          case 'pornstar':
            this.addNewPornstar(this.pornstarName, this.pornstarGender)
            break
          case 'director':
            this.addNewDirector(this.directorName)
            break
        }
      } else {
        this.$notify({
          type: 'error',
          title: `Add ${this.type}`,
          text: 'Fill required fields'
        })
      }
    },
    addNewSerie (title, studio) {
      this.popupFormLoading = true
      createSerie({ title, studio, sorting: 0 })
        .then(({ result }) => {
          this.$notify({
            type: 'success',
            title: 'New Serie',
            text: result.message
          })
          this.popupFormLoading = true
          this.$emit('updateSelectedSerie', studio, title, result.id)
          clearData.call(this)
          this.$refs.popupform.resetValidation()
          this.$emit('closepopup')
        })
        .catch(errorHandler.bind(this))
    },
    addNewPornstar (name, gender) {
      this.popupFormLoading = true
      createPornstar({ name, gender })
        .then(({ result }) => {
          this.$notify({
            type: 'success',
            title: 'New Pornstar Added'
          })
          this.popupFormLoading = false
          this.$emit('updateSelectedPornstar', result.id, name)
          clearData.call(this)
          this.$refs.popupform.resetValidation()
          this.$emit('closepopup')
        })
        .catch(errorHandler.bind(this))
    },
    addNewDirector (name) {
      this.popupFormLoading = true
      createDirector({ name })
        .then(({ result }) => {
          this.$notify({
            type: 'success',
            title: 'New Director Added'
          })
          this.popupFormLoading = false
          this.getDirectorsData(true)
          this.$emit('updateSelectedDirector', result.id)
          clearData.call(this)
          this.$refs.popupform.resetValidation()
          this.$emit('closepopup')
        })
        .catch(errorHandler.bind(this))
    },
  }
}
</script>
