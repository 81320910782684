<template>
  <v-menu
    v-model="menu"
    :ref="pickerRef"
    :close-on-content-click="false"
    :return-value.sync="syncData"
    :disabled="disabled"
    lazy
    full-width
    dark
    transition="scale-transition"
    offset-y
  >
    <template #activator="{ on }">
      <v-text-field
        :value="data[pickerRef]"
        :label="label"
        :disabled="disabled"
        box
        clearable
        append-icon="event"
        readonly
        class="date-picker-input"
        v-on="on"
        @input="val => $emit('updateDate', val)"
      />
    </template>
    <v-date-picker
      :value="data[pickerRef]"
      :disabled="disabled"
      box
      no-title
      scrollable
      @input="val => $emit('updateDate', val)"
    >
      <v-spacer />
      <v-btn
        flat
        color="primary"
        @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
        flat
        color="primary"
        @click="$refs[pickerRef].save(data[pickerRef])"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    pickerRef: {
      type: String,
      default: 'date-picker'
    },
    label: {
      type: String,
      default: 'date-picker'
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  /**
   * @menu - v-model menu state
   * @syncData - state for capturing and sending data to parrent component
   */
  data () {
    return {
      menu: false,
      syncData: null
    }
  },
  watch: {
    syncData (value) {
      this.$emit('updateSync', value)
    }
  }
}
</script>
