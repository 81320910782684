<template>
  <div
    :class="classes"
    class="custom-checkbox"
  >
    <input
      :name="name"
      :value="value"
      :id="id"
      :checked="isChecked"
      type="checkbox"
      @change="updateInput"
    >
    <label
      v-if="label"
      :for="id"
    >{{ label }}</label>
  </div>
</template>

<script>

export default {
  name: 'CustomCheckbox',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    name: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    classes: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Number, String, Boolean],
      default: null
    },
    modelValue: {
      type: [Number, String, Boolean, Array],
      default: null
    }
  },
  computed: {
    /**
     * computed checked value state
     */
    isChecked () {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      return this.checked
    }
  },
  methods: {
    /**
     * 
     * @param {HTMLClickEvent} event
     * updating checkobx on click method
     */
    updateInput (event) {
      const isChecked = event.target.checked

      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        if (isChecked) {
          this.$emit('change', this.value ? this.value : isChecked)
        } else {
          let returnValue
          if (typeof this.value === 'string') returnValue = ''
          if (typeof this.value === 'number') returnValue = null
          this.$emit('change', this.value ? returnValue : isChecked)
        }
      }
    }
  }
}
</script>

