import Vue from 'vue'
import './plugins'

import App from '@/App'
import router from '@/router'
import store from '@/store'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Vue.config.productionTip = false
Vue.prototype.$staticData = {}

/* eslint-disable no-new */
/**
 * Before app loads, we require config.json file. This file contains all needed configurations
 * for app staging/development and production environments
 */
fetch('/config.json').then(res => res.json()).then(({
  BaseMovieLib,
  BaseShopAdm,
  BaseCoverUrl,
  BaseFlexFiles,
  HideCategories,
  languages,
  countries,
  chooseOptionsNumber,
  chooseOptionsNumberWithNull,
  performerData,
  productStatus,
  trailerTypes,
  pictureTypes,
  pictureCensores,
  trailerCensores,
  env
}) => {
  // sentry.io integration for production
  process.env.NODE_ENV !== 'development' && Sentry.init({
    Vue,
    dsn: 'https://84b1260cc30e425c903930bebf3bcc1b@o374816.ingest.sentry.io/6200232',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          BaseMovieLib.replace(/(^\w+:|^)\/\//, ''),
          BaseFlexFiles.replace(/(^\w+:|^)\/\//, ''),
          BaseShopAdm.replace(/(^\w+:|^)\/\//, '')
        ]
      })
    ],
    debug: true,
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true
    },
    logErrors: true,
    attachProps: true,
    attachStacktrace: true
  })
  // setting global base URL's for backend endpoints
  window.BaseMovieLib.set(BaseMovieLib)
  window.BaseShopAdm.set(BaseShopAdm)
  window.BaseFlexFiles.set(BaseFlexFiles)
  window.BaseCoverUrl = BaseCoverUrl
  window.HideCategories = HideCategories
  window.environment = env

  // setting static config data from config.json that are used across the app
  Vue.prototype.$staticData.languages = languages
  Vue.prototype.$staticData.countries = countries
  Vue.prototype.$staticData.performerData = performerData
  Vue.prototype.$staticData.chooseOptionsNumber = chooseOptionsNumber
  Vue.prototype.$staticData.chooseOptionsNumberWithNull = chooseOptionsNumberWithNull
  Vue.prototype.$staticData.productStatus = productStatus
  Vue.prototype.$staticData.trailerTypes = trailerTypes
  Vue.prototype.$staticData.pictureTypes = pictureTypes
  Vue.prototype.$staticData.pictureCensores = pictureCensores
  Vue.prototype.$staticData.trailerCensores = trailerCensores
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}).catch(e => alert('File config.json was not found in root folder', e))
