<template>
  <v-flex
    v-if="!isUpdatingCensorship"
    xs12
    class="action-buttons"
    text-xs-right
  >
    <slot
      name="before"
    />
    <v-btn
      v-if="hasAddTrailer"
      color="green"
      class="mx-1 white--text"
      @click="$emit('addTrailer')"
    >
      Add new Trailer
    </v-btn>
    <v-btn
      v-if="hasAddMultiType"
      color="green"
      class="mx-1 white--text"
      @click="$emit('multiManage')"
    >
      Add New
    </v-btn>
    <v-btn
      v-if="hasUpdateCensorship"
      color="green"
      class="mx-1 white--text"
      @click="openCensorship"
    >
      Censorship
    </v-btn>
    <v-btn
      v-if="hasImageSearch"
      :loading="findCoversLoading"
      :disabled="findCoversLoading"
      color="primary"
      class="mx-1 white--text"
      @click="searchImages(true)"
    >
      Find Covers
    </v-btn>
    <v-btn
      v-if="hasImageSearch"
      :loading="findCoversLoading"
      :disabled="findCoversLoading"
      color="primary"
      class="mx-1 white--text max-width-button"
      @click="searchImages(false)"
    >
      Find Alternative Covers
    </v-btn>
    <v-btn
      v-if="hasNext"
      :loading="loadingSave"
      :disabled="loadingSave"
      color="info"
      class="mx-1 white--text"
      @click="saveAndNext"
    >
      {{ actionButtonText }} & Next
    </v-btn>
    <v-btn
      v-if="!noSaveButtons"
      :loading="loadingSave"
      :disabled="loadingSave"
      color="info"
      class="mx-1 white--text"
      @click="saveAndClose"
    >
      {{ actionButtonText }} & Close
    </v-btn>
    <v-btn
      v-if="!noSaveButtons"
      :loading="loadingSave"
      :disabled="loadingSave"
      color="info"
      class="mx-1 white--text"
      @click="save"
    >
      {{ actionButtonText }}
    </v-btn>
    <v-btn
      v-if="hasCopy"
      color="info"
      class="mx-1 white--text"
      @click="copy"
    >
      Copy
    </v-btn>
    <v-btn
      v-if="hasPaste"
      color="primary"
      class="mx-1 white--text"
      @click="paste"
    >
      paste
    </v-btn>
    <v-btn
      v-if="hasPaste"
      fab
      dark
      small
      color="primary"
      @click="clearCopied"
    >
      <v-icon dark>
        remove
      </v-icon>
    </v-btn>
    <v-btn
      color="red"
      class="mx-1 white--text"
      @click="close"
    >
      Close
    </v-btn>
  </v-flex>
  <v-layout
    v-else
    class="action-buttons"
    style="padding-top: 10px"
    text-xs-right
    row
  >
    <v-flex
      xs3
      pr-2
      offset-xs1
    >
      <v-select
        :items="selectCensoredOption"
        v-model="selected"
        box
        label="Set selected as:"
      />
    </v-flex>
    <v-flex
      xs3
    >
      <v-select
        :items="selectCensoredOption"
        v-model="unselected"
        box
        label="Set un-selected as:"
      />
    </v-flex>
    <v-flex xs3>
      <v-btn
        :loading="loadingCensored"
        :disabled="loadingCensored"
        color="info"
        class="mx-1 white--text"
        @click="updateCensorship(selected, unselected)"
      >
        Update Images
      </v-btn>
      <v-btn
        color="red"
        class="mx-1 white--text"
        @click="cancelCensorship"
      >
        Cancel
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    save: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    },
    saveAndNext: {
      type: Function,
      default: () => {}
    },
    updateCensorship: {
      type: Function,
      default: () => {}
    },
    openCensorship: {
      type: Function,
      default: () => {}
    },
    cancelCensorship: {
      type: Function,
      default: () => {}
    },
    saveAndClose: {
      type: Function,
      default: () => {}
    },
    copy: {
      type: Function,
      default: () => {}
    },
    paste: {
      type: Function,
      default: () => {}
    },
    clearCopied: {
      type: Function,
      default: () => {}
    },
    searchImages: {
      type: Function,
      default: () => {}
    },
    type: {
      type: String,
      default: 'add'
    },
    hasCopy: {
      type: Boolean,
      default: false
    },
    hasAddTrailer: {
      type: Boolean,
      default: false
    },
    hasUpdateCensorship: {
      type: Boolean,
      default: false
    },
    isUpdatingCensorship: {
      type: Boolean,
      default: false
    },
    hasAddMultiType: {
      type: Boolean,
      default: false
    },
    hasImageSearch: {
      type: Boolean,
      default: false
    },
    hasPaste: {
      type: Boolean,
      default: false
    },
    findCoversLoading: {
      type: Boolean,
      default: false
    },
    hasNext: {
      type: Boolean,
      default: false
    },
    loadingSave: {
      type: Boolean,
      default: false
    },
    loadingCensored: {
      type: Boolean,
      default: false
    },
    noSaveButtons: {
      type: Boolean,
      default: false
    }
  },
  /**
   * @selectCensoredOption - array items for v-select
   * @selected - selected option from array items (default 1)
   * @unselected - selected default option for unselected part
   */
  data () {
    return {
      selectCensoredOption: [
        { text: 'Censored', value: 1 },
        { text: 'Uncensored', value: 0 },
        { text: 'Do not change', value: null }
      ],
      selected: 1,
      unselected: 0
    }
  },
  computed: {
    actionButtonText () {
      return this.type === 'add' ? 'Save' : 'Update'
    }
  }
}
</script>
