<template>
  <v-container
    class="crud-container"
    grid-list-sm
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        sm12
      >
        <v-layout
          justify-center
        >
          <v-flex
            lg3
            md3
            sm12
            class="large-image"
          >
            <ImageContainer
              :id="'hero'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Hero"
              resolution="0x480"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg3
            md3
            sm12
            class="large-image"
          >
            <ImageContainer
              :id="'poster'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Poster"
              resolution="0x480"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg3
            md3
            sm12
            class="large-image"
          >
            <ImageContainer
              :id="'picture'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Picture"
              resolution="0x480"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ImageContainer from '@/components/common/ImageContainer'

export default {
  components: {
    ImageContainer
  },
  props: {
    currentImageSet: {
      type: Object,
      default: () => {}
    },
    onImageFilePicked: {
      type: Function,
      default: () => {}
    },
    clearImage: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
