import httpMovieLib from './http/httpMovieLib'
import apiHandler from '@/services/http/api'

export const getTagOptions = query => apiHandler(httpMovieLib.get(`/api/tag/options?q=${query}`))

export const addMovieTags = (movieId, data) => apiHandler(httpMovieLib.post(`/api/movie/${movieId}/add-tags`, data))

export const removeTagsFromMovie = (movieId, data) => apiHandler(httpMovieLib.post(`/api/movie/${movieId}/remove-tags`, data))

export const removeTagsCompletely = data => apiHandler(httpMovieLib.post('/api/tags/remove', data))

export const addBulkTags = data => apiHandler(httpMovieLib.put(`/api/movie/add-tags`, data))

export const removeBulkTags = data => apiHandler(httpMovieLib.put(`/api/movie/remove-tags`, data))

export const manageBulkMovieTags = (movies, tags, isAdd) => {
  if (isAdd) {
    return addBulkTags({ movies, tags })
  } else {
    return removeBulkTags({ movies, tags })
  }
}
