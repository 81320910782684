import httpFlexFiles from './http/httpFlexFiles'
import httpShopAdm from './http/httpShopAdm'
import apiHandler from '@/services/http/api'

export const getMovieSalesOptions = movieId => apiHandler(httpShopAdm.get(`/api/vod/sales-options/${movieId}`))

export const setMovieSalesOptions = (movieId, data) => apiHandler(httpShopAdm.post(`/api/vod/sales-options/${movieId}`, data))

export const getMultiMovieSalesOptions = idList => apiHandler(httpShopAdm.get(`/api/vod/sales-options-multi/${idList}`))

export const setMultiMovieSalesOptions = (idList, data) => apiHandler(httpShopAdm.post(`/api/vod/sales-options-multi/${idList}`, data))

export function getMovieVodLinks(movieId, sceneNo) {
    return apiHandler(httpFlexFiles.get(`/movie/links/${movieId}?stream=1&s=${sceneNo}`))
}