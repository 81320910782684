<template>
  <v-toolbar
    v-if="toolbar"
    id="core-toolbar"
    :class="drawerMini ? 'small-margin': 'big-margin'"
    flat
    prominent
    dense
    clipped-left
    height="57px"
    style="background: #E0E0E0;"
  >
    <div class="v-toolbar-title">
      <v-toolbar-title
        class="tertiary--text font-weight-light"
      >
        <v-btn
          v-if="responsive"
          class="default"
          light
          icon
          @click.stop="toggleDawer"
        >
          <v-icon>menu</v-icon>
        </v-btn>
        {{ title }}
      </v-toolbar-title>
    </div>
    <v-spacer />
    <v-toolbar-items v-if="username">
      <v-flex
        align-center
        layout
        py-2
      >
        <v-menu offset-y>
          <template #activator="{ on }">
            <div
              class="user-account"
              v-on="on"
            >
              {{ username }}
              <v-avatar>
                <v-icon dark>
                  account_circle
                </v-icon>
              </v-avatar>
            </div>
          </template>
          <v-list>
            <v-list-tile @click="logoutUser">
              <v-list-tile-title>Logout</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>

import {
  mapMutations,
  mapGetters
} from 'vuex'

export default {
  /**
   * @title - toolbar title state
   * @responsive - responsive state of drwaer
   */
  data () {
    return {
      title: null,
      responsive: false
    }
  },

  computed: {
    ...mapGetters(['username', 'toolbar', 'drawer', 'drawerMini'])
  },

  watch: {
    '$route' (val) {
      this.title = val.name
    }
  },

  created () {
    this.title = this.$route.name
  },
  /**
   * detecting responsive state under lifecycle hooks
   */
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations(['setDrawer', 'logout']),
    logoutUser () {
      this.logout()
      this.$router.push('/login')
      this.$notify({
        type: 'success',
        title: `User: ${this.username}`,
        text: 'logged out'
      })
    },
    toggleDawer () {
      this.setDrawer(!this.drawer)
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>
