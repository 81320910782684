/**
 * movieLib backend axios instance
 */
import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import { jwtTokenMovieLib } from '@/config'

// getting and setting movieLib base url endpoint
window.BaseMovieLib = {
  value: '',
  set (val) { this.value = val },
  get () { return this.value }
}

// movieLib custom axios instance with interceptiors pre and after request
const httpMovieLib = axios.create()

httpMovieLib.interceptors.request.use(cfg => {
  cfg.baseURL = window.BaseMovieLib.get()
  const token = window.localStorage.getItem(jwtTokenMovieLib)
  if (token) cfg.headers.common['Authorization'] = 'Bearer ' + token
  return cfg
}, err => {
  router.push('login').catch(e => e)
  return Promise.reject(err)
})

httpMovieLib.interceptors.response.use(response => response, err => {
    if (err.response.status === 401) {
      Vue.notify({
        title: 'Error: 401',
        text: 'MovieLib Token expired'
      })
      router.push('login').catch(e => e)
      return err
    }
    return Promise.reject(err.response.data.error)
  })

export default httpMovieLib
