<template>
  <v-dialog
    ref="coverSceneImages"
    :value="active"
    persistent
    full-width
    width="500px"
    @change="val => $emit('updateActive', val)"
  >
    <v-sheet>
      <v-container
        grid-list-md
        fluid
        text-xs-center
      >
        <h3 class="heading text-lg-left text-md-left">
          Update scene no. {{ sceneNumber }} cover-front image
        </h3>
        <v-btn
          icon
          dark
          absolute
          round
          right
          class="close-btn"
          @click="$emit('close')"
        >
          <v-icon class="close-icon">
            close
          </v-icon>
        </v-btn>
        <hr>
        <v-img
          v-if="selectedCoverImage"
          :src="imageSource"
          :lazy-src="imageSource"
          height="400"
          aspect-ratio="1"
          contain
          @error="onImgError"
        >
          <template #placeholder>
            <v-layout
              fill-height
              align-center
              justify-center
              ma-0
            >
              <v-progress-circular
                indeterminate
                color="teal"
              />
            </v-layout>
          </template>
        </v-img>
        <v-switch
          v-model="censorship"
          :label="`This image is ${censorship ? 'Censored' : 'Uncensored'}`"
        />
        <v-flex
          xs12
          text-xs-right
        >
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="info"
            class="mx-1 white--text"
            @click="updateCover"
          >
            Update
          </v-btn>
          <v-btn
            color="info"
            class="mx-1 white--text"
            @click="$emit('close')"
          >
            close
          </v-btn>
        </v-flex>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { updatePictureFromUrl, addPictureFromUrl } from '@/services/movie.service'
import { errorHandler } from '@/utils/error'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    selectedCoverImage: {
      type: String,
      default: ''
    },
    sceneNumber: {
      type: Number,
      default: null
    },
    movieId: {
      type: Number,
      default: null
    },
    sceneImages: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      censorship: false,
      imageFailed: Date.now(),
      loading: false,
      type: 'cover'
    }
  },
  computed: {
    imageSource () {
      return `${this.selectedCoverImage}?${this.imageFailed}`
    }
  },
  methods: {
    updateCover () {
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.selectedCoverImage)
      formData.append('type', this.type)
      formData.append('censored', this.censorship ? 1 : 0)
      formData.append('scene', this.sceneNumber)
      if (this.sceneImages[`${this.type}${this.censorship ? 'Censored' : ''}`].id) {
        updatePictureFromUrl(this.sceneImages[`${this.type}${this.censorship ? 'Censored' : ''}`].id, formData)
          .then(response => {
            this.$notify({
              type: 'success',
              title: `Scene no. ${this.sceneNumber}`,
              text: `Cover${this.censorship ? ' Censored' : ''} image updated`
            })
            this.loading = false
            this.$emit('updatePictureList', response.data.result.picture, this.type, this.censorship)
            this.$emit('close')
          })
          .catch(errorHandler.bind(this))
      } else {
        addPictureFromUrl(this.movieId, formData)
          .then(response => {
            this.$notify({
              type: 'success',
              title: `Scene no. ${this.sceneNumber}`,
              text: `Cover${this.censorship ? ' Censored' : ''} image updated`
            })
            this.loading = false
            this.$emit('updatePictureList', response.data.result.picture, this.type, this.censorship)
            this.$emit('close')
          })
          .catch(errorHandler.bind(this))
      }
    },
    onImgError () {
      setTimeout(() => {
        this.imageFailed = Date.now()
      }, 2000)
    }
  }
}
</script>
