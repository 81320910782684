<template>
  <v-dialog
    ref="trailerForm"
    :value="active"
    persistent
    full-width
    width="500px"
  >
    <v-sheet>
      <v-container
        grid-list-md
        fluid
      >
        <h3
          v-if="mode === 'remove'"
          class="heading text-lg-left text-md-left"
        >
          Remove trailer video
        </h3>
        <h3
          v-if="mode === 'add'"
          class="heading text-lg-left text-md-left"
        >
          Add new trailer
        </h3>
        <h3
          v-if="mode === 'update'"
          class="heading text-lg-left text-md-left"
        >
          Update trailer
        </h3>
        <v-btn
          icon
          dark
          absolute
          round
          right
          class="close-btn"
          @click="$emit('close')"
        >
          <v-icon class="close-icon">
            close
          </v-icon>
        </v-btn>
        <hr>
        <br>
        <p
          v-if="mode === 'remove'"
        >
          Do you want to remove this trailer video?
        </p>
        <v-flex
          v-if="mode === 'add' || mode === 'update'"
          xs12
          text-xs-center
        >
          <v-text-field
            v-model.trim="url"
            :rules="rules.fieldRequierd"
            :required="true"
            label="Trailer URL"
            box
          />
          <v-select
            v-model="type"
            :rules="rules.fieldRequierd"
            :required="true"
            :items="trailerTypeItems"
            label="Trailer Type"
            box
          />
          <v-select
            v-if="hasCensored"
            v-model="censored"
            :items="$staticData.trailerCensores"
            label="Trailer Censorship"
            box
          />
        </v-flex>
        <v-flex
          xs12
          text-xs-right
        >
          <v-btn
            :loading="loading"
            :disabled="loading"
            :color="mode === 'add'|| mode === 'update' ? 'success' : 'error'"
            class="mx-1 white--text"
            @click="submitAction"
          >
            {{ mode.toUpperCase() }}
          </v-btn>
          <v-btn
            color="info"
            class="mx-1 white--text"
            @click="$emit('close')"
          >
            close
          </v-btn>
        </v-flex>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    selectedTrailerId: {
      type: Number,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasCensored: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    trailerTypeItems: {
      type: Array,
      default: () => []
    }
  },
  /**
   * @type - trailer type state
   * @ulr - string url for trailer to be uplaoded
   * @censored - trailer censored state
   * @rules - trailer form rules for required fields
   */
  data () {
    return {
      type: 'promo',
      url: '',
      censored: false,
      rules: {
        fieldRequierd: [
          v => !!v || 'This field is required'
        ]
      }
    }
  },
  methods: {
    submitAction () {
      switch (this.mode) {
        case 'add':
        default:
          return this.$emit('add', this.url, this.type, this.hasCensored && this.censored)
        case 'add-resolution':
          return this.$emit('add', this.selectedTrailerId, this.url, this.type)
        case 'remove':
          return this.$emit('remove', this.selectedTrailerId)
        case 'update':
          return this.$emit('update', this.selectedTrailerId, this.url, this.type)
      }
    }
  }
}
</script>
