<template>
  <div>
    <v-layout
      v-for="(item, index) in eans"
      :key="index + 'ean'"
      row
      wrap
    >
      <v-flex
        xs8
      >
        <v-text-field
          v-model.trim="item.value"
          :rules="rules"
          :required="index === 0 ? true : false"
          box
          flat
          type="number"
          label="EAN"
        />
      </v-flex>
      <v-flex
        v-if="index > 0"
        xs2
      >
        <v-btn
          :loading="loadingDelete"
          :disabled="loadingDelete"
          fab
          dark
          small
          color="error"
          @click="remField(index)"
        >
          <v-icon
            right
            light
            class="ml-0"
          >
            remove
          </v-icon>
        </v-btn>
      </v-flex>
      <v-flex
        v-else
        xs2
      >
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="info"
              @click="newField"
              v-on="on"
            >
              <v-icon
                right
                light
                class="ml-0"
              >
                add
              </v-icon>
            </v-btn>
          </template>
          <span>Additional Ean</span>
        </v-tooltip>
      </v-flex>
      <v-flex
        v-if="index === 0 && type === 'add'"
        xs2
      >
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              :loading="loadingCheck"
              :disabled="loadingCheck"
              fab
              dark
              small
              color="info"
              @click="checkEan"
              v-on="on"
            >
              <v-icon
                right
                light
                class="ml-0"
              >
                cached
              </v-icon>
            </v-btn>
          </template>
          <span>Check if Already Exist</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { deleteAuxEan } from '@/services/movie.service'
import { errorHandler } from '@/utils/error'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      default: () => []
    },
    checkEan: {
      type: Function,
      default: () => {}
    },
    loadingCheck: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingDelete: false
    }
  },
  computed: {
    ...mapGetters({
      type: 'movies/crudType',
      savedMovie: 'movies/savedMovie'
    }),
    eans: {
      get () { return this.value },
      set (ean) { this.$emit('input', ean) }
    }
  },
  methods: {
    newField () {
      this.eans.push({
        value: ''
      })
    },
    remField (index) {
      this.loadingDelete = true
      if (this.savedMovie && this.type === 'edit' && this.eans[index].value) {
        deleteAuxEan(this.savedMovie.id, this.eans[index].value)
          .then(response => {
            this.$emit('updateMovieMeta')
            this.$notify({
              type: 'success',
              title: response.result.message
            })
            if (index > -1) {
              this.eans.splice(index, 1)
            }
            this.loadingDelete = false
          })
          .catch(errorHandler.bind(this))
      } else {
        if (index > -1) {
          this.eans.splice(index, 1)
        }
        this.loadingDelete = false
      }
    }
  }
}
</script>
