/**
 * 
 * @param {HTMLdata} html 
 * @returns helper function used in ckEditor to parse html entities to code values
 */
export default function decodeHtml (html) {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}
