/**
 * flexfiles backend axios instance
 */
import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import { jwtTokenFlexFiles } from '@/config'

// getting and setting flexfiles base url endpoint
window.BaseFlexFiles = {
  value: '',
  set (val) { this.value = val },
  get () { return this.value }
}

// flexfiles custom axios instance with interceptiors pre and after request
const httpFlexFiles = axios.create()

httpFlexFiles.interceptors.request.use(cfg => {
  cfg.baseURL = window.BaseFlexFiles.get()
  const token = window.localStorage.getItem(jwtTokenFlexFiles)
  if (token) cfg.headers.common['Authorization'] = 'Bearer ' + token
  return cfg
}, err => {
  router.push('login').catch(e => e)
  return Promise.reject(err)
})

httpFlexFiles.interceptors.response.use(response => response, err => {
  if (err.response.status === 401) {
    Vue.notify({
      title: 'Error: 401',
      text: 'FlexFiles Token expired'
    })
    router.push('login').catch(e => e)
    return err
  }
  return Promise.reject(err.response.data.error)
})

export default httpFlexFiles
