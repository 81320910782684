<template>
  <v-navigation-drawer
    right
    fixed
    width="450"
    v-if="fastUrl?.path === '/pornstar-manager'"
    :mini-variant.sync="isClosed"
    class="history-drawer"
  >
    <v-toolbar
      style="background: #E0E0E0;"
    >
      <v-btn
        icon
        @click.stop="toggleDrawer"
        light
      >
        <v-icon v-if="!isClosed">
          menu
        </v-icon>
        <v-icon v-if="isClosed">
          history
        </v-icon>
      </v-btn>
      <v-toolbar-title class="tertiary--text font-weight-light">
        Pornstars History
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-text-field
      v-model="searchHistory"
      append-icon="search"
      label="Search"
      v-if="!isClosed"
    />
    <v-data-table
      :headers="headers"
      :items="snapshots"
      :search="searchHistory"
      :pagination.sync="pagination"
      :rows-per-page-items="[25,50,100,{'text':'$vuetify.dataIterator.rowsPerPageAll','value':-1}]"
      class="elevation-1"
      v-if="!isClosed"
    >
      <template #items="props">
        <td>{{ props.item.performer.name }}</td>
        <td class="text-xs-left">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                v-if="props.item.operation == 'replace'"
                disabled
                v-on="on"
              >
                face
              </v-icon>
            </template>
            <span>Replaced Pornstar {{ props.item?.performer?.name }} with {{ props.item?.replacementPerformer?.name }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                v-if="props.item.operation == 'update'"
                @click="deleteItem(props.item, snapshots)"
                v-on="on"
              >
                update
              </v-icon>
            </template>
            <span>Restore Changes from Pornstar {{ props.item?.performer?.name }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                v-if="props.item.operation == 'delete'"
                @click="deleteItem(props.item, snapshots)"
                v-on="on"               
              >
                delete
              </v-icon>
            </template>
            <span>Restore Deleted Pornstar {{ props.item?.performer?.name }}</span>
          </v-tooltip>
        </td>
        <td class="text-xs-left">
          {{ (props.item.createdAt).toLocaleString('de-DE') }}
        </td>
        <td class="text-xs-left">
          {{ props.item.username }}
        </td>
      </template>
    </v-data-table>
  </v-navigation-drawer>
</template>

<script>
import { listSnapshots, undeletePornstarFromSnapshot, undoPornstarFromSnapshot } from '@/services/pornstar.service'
import { mapMutations, mapGetters } from 'vuex' 
import { errorHandler } from '../../utils/error'

export default {
    data () {
      return {
        isClosed: true,
        searchHistory: '',
        headers: [
          {
            text: 'Pornstar',
            align: 'left',
            value: 'performer.name',
          },
          { text: 'Type', value: 'operation'},
          { text: 'Date', value: 'createdAt' },
          { text: 'Username', value: 'username' }
        ],
        pagination: {
          sortBy: 'createdAt',
          descending: true,
        },
        snapshots: []
      }
    },
      computed: {
    ...mapGetters(['fastUrl'])
  },
    methods: {
      ...mapMutations(['setPrompt', 'promptUnset']),
      getSnapshots: listSnapshots,
      undeletePornstarFromSnapshot: undeletePornstarFromSnapshot,
      undoPornstarFromSnapshot: undoPornstarFromSnapshot,
      toggleDrawer () {
        if (!this.isClosed) return this.isClosed = !this.isClosed

        /* fetch testApi and set result to snapshots */
        this.getSnapshots().then((res) => {
          this.snapshots = res.result
          this.isClosed = !this.isClosed
        }).catch((e) => {
          if (e.code === 400) {
            this.$notify({
              type: 'error',
              title: e.message
            })
        } else {
            errorHandler(e).bind(this)
        }
        })
      },
      deleteItemFromArray (item, items) {
        const index = items.findIndex(el => el.id === item.id)
        if (index !== -1) {
          item.deleteLoading = false
          items.splice(index, 1)
        }
      },
      deleteItem (item, items) {
      this.setPrompt({
        title: `Confirm undoing ${item.operation}`,
        message: `Are you sure you want to un${item.operation} Pornstar ${item.performer.name}?`,
        confirmMethod: async function () {
          item.deleteLoading = true
          this.$notify({
            type: 'warn',
            title: `Restoring Pornstar ${item.performer.name} changes`
          })
          try {
            // call api to restore the snapshot
            if(item.operation == 'delete') {
              await this.undeletePornstarFromSnapshot(item.id)
            }else if(item.operation == 'replace') {
              await this.undoPornstarFromSnapshot(item.id)
            }else if(item.operation == 'update') {
              await this.undoPornstarFromSnapshot(item.id, item.performer.id)
            }

            this.$notify({
              type: 'success',
              title: 'Successfully restored'
            })
            item.deleteLoading = false
            this.deleteItemFromArray(item, items)
          } catch (e) {
            item.deleteLoading = false
            if (e.code === 400) {
              this.$notify({
                type: 'error',
                title: e.message
              })
            } else {
              errorHandler(e).bind(this)
            }
          }
        }.bind(this),
        cancelMethod: function () {
          this.promptUnset()
        }.bind(this)
      })
    }
    }
  }
</script>

<style lang="scss" >
.history-drawer {
    z-index: 5;
    & .v-toolbar:not(.v-toolbar--fixed)  .v-toolbar__content {
      margin-left: 0 !important;

      & .v-btn {
        color: #495057 !important;
      }
    }

    &.v-navigation-drawer--mini-variant{
      height: 65px !important;
    }
}

</style>