/**
 * 
 * @param {object} oldVal 
 * @returns filtered object with keys that have any value except null, undefiend, empty string
 */
export const filterObject = oldVal => {
  return Object.keys(oldVal).reduce((acc, key) => {
    const data = { ...acc }
    if ((oldVal[key] && !Array.isArray(oldVal[key])) || (Array.isArray(oldVal[key]) && oldVal[key].length)) {
      data[key] = oldVal[key]
    }
    return data
  }, {})
}

/**
 * reset vue component data
 */
export function clearData () {
  Object.assign(this.$data, this.$options.data())
}

/**
 * 
 * @param {object} o 
 * @returns clonned object with deep nested objects inside.
 */
export function deepClone (o) {
  if (o && typeof o === 'object') {
    if (Array.isArray(o)) {
      return o.map((a) => deepClone(a))
    } else if (o.constructor === Object) {
      return Object.entries(o).reduce((prev, [k, v]) => ({ ...prev, [k]: deepClone(v) }), {})
    }
    return o
  }
  return o
}

// helper slugify method
export function slugify (txt) {
  return txt
    .toString()
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
}
