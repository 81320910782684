import httpMovieLib from './http/httpMovieLib'
import apiHandler from '@/services/http/api'

const multiPart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const getCategoriesOptions = () => apiHandler(httpMovieLib.get('/api/categories/grid?sort=id&dir=desc&limit=1000&start=0&ca[0][o]=is&ca[0][v]=1'))

export const getCategoriesGroup = () => apiHandler(httpMovieLib.get('/api/category/group/options'))

export const getCategoryDetails = id => apiHandler(httpMovieLib.get(`/api/category/${id}`))

export const getCategoryGroupDetails = id => apiHandler(httpMovieLib.get(`/api/category/group/${id}`))

export const createCategory = data => apiHandler(httpMovieLib.post('/api/category/add', data))

export const createCategoryGroup = data => apiHandler(httpMovieLib.post('/api/category/group/add', data))

export const uploadBackground = (id, data, isHard) => {
  if (isHard) return apiHandler(httpMovieLib.post(`/api/category/${id}/bg-image/hard`, data, multiPart));
  return apiHandler(httpMovieLib.post(`/api/category/${id}/bg-image/soft`, data, multiPart));
}

/**
 * Updates the focal points of the background image of a category.
 * @param {number} id - The ID of the category.
 * @param {Object} data - The data containing the new focal point coordinates.
 * @param {number|null} x - The x-coordinate of the new focal point.
 * @param {number|null} y - The y-coordinate of the new focal point.
 * @param {boolean} isHard - Whether the image is explicit or not.
 * @returns {Promise} A promise that resolves with the updated category data.
 */
export const updateBackgroundFocalPoints = (id, data, isHard) => {
  if (data.x !== null || data.y !== null) {
    // reformate data to data.focalpoint_x and data.focalpoint_y
    data.focalpoint_x = data.x;
    data.focalpoint_y = data.y;
    const endpoint = isHard ? `/api/category/${id}/bg-image/hard` : `/api/category/${id}/bg-image/soft`;
    return apiHandler(httpMovieLib.post(endpoint, data));
  }
}

export const removePicture = (id, imageData) => {
  const isHard = imageData.isHard;
  if (isHard) return apiHandler(httpMovieLib.delete(`/api/category/${id}/bg-image/hard`));
  return apiHandler(httpMovieLib.delete(`/api/category/${id}/bg-image/soft`));
}

export const updateCategory = (id, data) => apiHandler(httpMovieLib.put(`/api/category/${id}`, data))

export const updateCategoryGroup = (id, data) => apiHandler(httpMovieLib.put(`/api/category/group/${id}`, data))

export const deleteCategory = id => apiHandler(httpMovieLib.delete(`/api/category/${id}`))

export const deleteCategoryGroup = id => apiHandler(httpMovieLib.delete(`/api/category/group/${id}`))

export const categoryGrid = (searchParameters, pagination) => {
  let searchString = ''

  Object.keys(searchParameters).forEach(el => {
    if (searchParameters[el] !== '' && searchParameters[el] !== undefined && searchParameters[el] !== null) {
      switch (el) {
        case 'title': {
          searchString += `&cn[0][o]=cs&cn[0][v]=${searchParameters[el]}`
          break
        }
        case 'active': {
          searchString += `&ca[0][o]=is&ca[0][v]=${searchParameters[el]}`
          break
        }
        case 'bgImage': {
          searchString += `&cbg[0][o]=is&cbg[0][v]=${searchParameters[el]}`
          break
        }
        case 'id': {
          searchString += `&id[0][o]=is&id[0][v]=${searchParameters[el]}`
          break
        }
      }
    }
  })

  return apiHandler(httpMovieLib.get(`/api/categories/grid?sort=${pagination.orderBy}&dir=${pagination.order}&limit=${pagination.rowsPerPage}&page=${pagination.page}${searchString}`))
}

export const categoryGroupGrid = (searchParameters, pagination) => {
  let searchString = ''

  Object.keys(searchParameters).forEach(el => {
    if (searchParameters[el] !== '' && searchParameters[el] !== undefined && searchParameters[el] !== null) {
      switch (el) {
        case 'title': {
          searchString += `&cgn[0][o]=cs&cgn[0][v]=${searchParameters[el]}`
          break
        }
        case 'active': {
          searchString += `&cga[0][o]=is&cga[0][v]=${searchParameters[el]}`
          break
        }
        case 'id': {
          searchString += `&id[0][o]=is&id[0][v]=${searchParameters[el]}`
          break
        }
      }
    }
  })

  return apiHandler(httpMovieLib.get(`/api/category/group/grid?sort=${pagination.orderBy}&dir=${pagination.order}&limit=${pagination.rowsPerPage}&page=${pagination.page}${searchString}`))
}

const addBulkCategories = data => apiHandler(httpMovieLib.put('/api/mcat/add', data))

const removeBulkCategories = data => apiHandler(httpMovieLib.put('/api/mcat/remove', data))

export const manageBulkMovieCategories = (movies, categories, isAdd) => {
  if (isAdd) {
    return addBulkCategories({ movies, categories })
  } else {
    return removeBulkCategories({ movies, categories })
  }
}
