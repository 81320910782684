import VideoManager from '@/components/views/VideoManager/VideoManager'

const GeneralFilter = resolve => {
  require.ensure(['@/components/views/VideoManager/Filters/GeneralFilter'], () => {
    resolve(require('@/components/views/VideoManager/Filters/GeneralFilter'))
  })
}

const DvdFilter = resolve => {
  require.ensure(['@/components/views/VideoManager/Filters/DvdFilter'], () => {
    resolve(require('@/components/views/VideoManager/Filters/DvdFilter'))
  })
}

const VodFilter = resolve => {
  require.ensure(['@/components/views/VideoManager/Filters/VodFilter'], () => {
    resolve(require('@/components/views/VideoManager/Filters/VodFilter'))
  })
}

const Login = resolve => {
  require.ensure(['@/components/views/Login'], () => {
    resolve(require('@/components/views/Login'))
  })
}

const SeriesManager = resolve => {
  require.ensure(['@/components/views/SeriesManager/SeriesManager'], () => {
    resolve(require('@/components/views/SeriesManager/SeriesManager'))
  })
}

const SeriesFilter = resolve => {
  require.ensure(['@/components/views/SeriesManager/Filters/SeriesFilter'], () => {
    resolve(require('@/components/views/SeriesManager/Filters/SeriesFilter'))
  })
}

const CategoryManager = resolve => {
  require.ensure(['@/components/views/CategoryManager/CategoryManager'], () => {
    resolve(require('@/components/views/CategoryManager/CategoryManager'))
  })
}

const CategoryFilter = resolve => {
  require.ensure(['@/components/views/CategoryManager/Filters/CategoryFilter'], () => {
    resolve(require('@/components/views/CategoryManager/Filters/CategoryFilter'))
  })
}

const CategoryGroupsFilter = resolve => {
  require.ensure(['@/components/views/CategoryManager/Filters/CategoryGroupsFilter'], () => {
    resolve(require('@/components/views/CategoryManager/Filters/CategoryGroupsFilter'))
  })
}

const StudioManager = resolve => {
  require.ensure(['@/components/views/StudioManager/StudioManager'], () => {
    resolve(require('@/components/views/StudioManager/StudioManager'))
  })
}

const StudioFilter = resolve => {
  require.ensure(['@/components/views/StudioManager/Filters/StudioFilter'], () => {
    resolve(require('@/components/views/StudioManager/Filters/StudioFilter'))
  })
}

const ImageManager = resolve => {
  require.ensure(['@/components/views/ImageManager/ImageManager'], () => {
    resolve(require('@/components/views/ImageManager/ImageManager'))
  })
}
const AiImageManager = resolve => {
  require.ensure(['@/components/views/ImageManager/AiImageManager'], () => {
    resolve(require('@/components/views/ImageManager/AiImageManager'))
  })
}

const ImageFilter = resolve => {
  require.ensure(['@/components/views/ImageManager/Filters/ImageFilter'], () => {
    resolve(require('@/components/views/ImageManager/Filters/ImageFilter'))
  })
}

const PornstarManager = resolve => {
  require.ensure(['@/components/views/PornstarManager/PornstarManager'], () => {
    resolve(require('@/components/views/PornstarManager/PornstarManager'))
  })
}

const PornstarFilter = resolve => {
  require.ensure(['@/components/views/PornstarManager/Filters/PornstarFilter'], () => {
    resolve(require('@/components/views/PornstarManager/Filters/PornstarFilter'))
  })
}

export default [
  {
    path: '*',
    redirect: '/video-manager',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/video-manager',
    component: VideoManager,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'General Manager',
        component: GeneralFilter
      },
      {
        path: 'dvd',
        name: 'DVD Manager',
        component: DvdFilter
      },
      {
        path: 'vod',
        name: 'VOD Manager',
        component: VodFilter
      }
    ]
  },
  {
    path: '/series-manager',
    component: SeriesManager,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Series Manager',
        component: SeriesFilter
      }
    ]
  },
  {
    path: '/category-manager',
    component: CategoryManager,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Category Manager',
        component: CategoryFilter
      },
      {
        path: 'groups',
        name: 'Category Groups',
        component: CategoryGroupsFilter
      }
    ]
  },
  {
    path: '/studio-manager',
    component: StudioManager,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Studio Manager',
        component: StudioFilter
      }
    ]
  },
  {
    path: '/image-manager',
    component: ImageManager,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Image Manager',
        component: ImageFilter
      }
    ]
  },
  {
    path: '/ai-image-manager',
    component: AiImageManager,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pornstar-manager',
    component: PornstarManager,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Pornstar Manager',
        component: PornstarFilter
      }
    ]
  }
]
