<template>
  <v-sheet class="pa-2 tv">
    <v-btn 
      @click="translateAll"
      :disabled="translating"
      outline 
      style="color: #112"
    >
      <v-icon class="mr-1">translate</v-icon>Translate
    </v-btn>
    <v-flex xs12>
      <h3>Movie Title</h3>  
      <v-layout class="row">
        <v-text-field
          v-model="movieMeta['title' + lang]"
          box
          flat
          v-for="lang in languages"
          :key="'title' + lang"
          :label="'Movie Title ' + lang.toUpperCase()"
        />
      </v-layout>
      <h3>Movie Description</h3>
      <v-layout class="row">
        <Editor
          v-model="movieMeta['descr' + lang]"
          :placeholder="'Movie Description ' + lang.toUpperCase()"
          @change="value => movieMeta['descr' + lang] = value"
          v-for="lang in languages"
          :key="'descr' + lang"
        />
      </v-layout>
    </v-flex>
    <br>
    <hr>
    <br>
    <h3>Scenes</h3>
    <v-flex
      xl6
      lg6
      md6
      sm12
      xs12
    >
      <v-alert
        :value="true"
        color="info"
        icon="info"
        outline
      >
        Automaticly sets Title and Description of all scenes based on <strong> movie title and description</strong> after saving.
      </v-alert>
    </v-flex>
    <v-form
      v-for="(scene, index) in scenesDataContainer"
      :key="index + 'scene' + savedMovie.id"
      :ref="`sceneform_${index + 1}`"
    >
      <h4>Scene Nr. {{ index + 1 }}</h4>
      <v-flex xs12>
        <v-layout class="row">
          <!-- Generate scene title fields for each language -->
          <v-text-field
            v-for="lang in languages"
            :key="`scene_${index}_title_${lang}`"
            v-model="scene['title' + lang]"
            box
            flat
            :label="`Scene Title ${lang.toUpperCase()}`"
            @change="val => scenesDataContainer[index]['title' + lang] = val"
          />
        </v-layout>
        <v-layout class="row">
          <!-- Generate scene description fields for each language -->
          <Editor
            v-for="lang in languages"
            :key="`scene_${index}_descr_${lang}`"
            v-model="scene['descr' + lang]"
            :placeholder="`Scene Description ${lang.toUpperCase()}`"
            @change="value => scenesDataContainer[index]['descr' + lang] = value"
          />
        </v-layout>
      </v-flex>
      <br><hr v-if="index + 1 !== scenesDataContainer.length"><br>
    </v-form>
    <ActionButtons
      :save="() => save({ close: false, next: false })"
      :close="close"
      :type="type"
      :loading-save="loadingSave"
      :save-and-next="() => save({ close: false, next: true })"
      :save-and-close="() => save({ close: true, next: false })"
      has-next
    />
  </v-sheet>
</template>

<script>
import ActionButtons from '@/components/common/ActionButtons'
import Editor from '@/components/common/Editor'
import { clearData, deepClone } from '@/utils/object'
import { errorHandler } from '@/utils/error'
import { crudObjectHelpers } from '@/mixins/crudComponents'
import { updateAllMovieScene } from '@/services/movie.service'
import { mapGetters } from 'vuex'
import _ from 'lodash';
import { firstLetterToUpperCase } from '@/utils/string'
import { translate } from '@/services/deepl.service.js'; 

// Add more language codes as needed
const langs = ['','De', 'Nl'];
const baseMeta = function() {
  const titles = {}; 
  const descrs = {};

  langs.forEach(lang => {
    titles[`title${lang}`] = '';
    descrs[`descr${lang}`] = '';
  });

  return {
    id: '',
    ean: '',
    disabled: false, 
    studio: '',
    title: '',
    descr: '',
    ...titles,
    ...descrs
  }
};
export default {
  components: {
    ActionButtons,
    Editor
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    nextTab: {
      type: Function,
      default: () => {}
    },
    overlay: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    },
    allTabs: {
      type: Array,
      default: () => []
    },
  },
  mixins: [crudObjectHelpers],
  data () {
    return {
      name: 'Translations',
      translating: false,
      loadingSave: false,
      coverSelectForm: false,
      scenesDataContainer: [],
      scenesDataContainerEditing: null,
      movieMetaEditing: null,
      languages: langs,
      movieMeta: baseMeta.call(this),
      transItems: null,
      cover: {
        mediaName: '',
        mediaUrl: '',
        mediaFile: '',
        deleteLoading: false,
        uploaded: false,
        name: 'cover'
      },
      selectedScene: {
        width: null,
        height: null,
        link: null,
        id: null,
        isCensored: false,
        number: null
      },
      headers: [
        { text: 'Type', value: 'type' },
        { text: 'English', value: 'en' },
        { text: 'Germam', value: 'De' },
        { text: 'Dutch', value: 'Nl' }
      ],
    }
  },
  computed: {
    ...mapGetters({
      savedMovie: 'movies/savedMovie',
      type: 'movies/crudType'
    }),
  },
  watch: {
    activeTab (val) {
      if (this.allTabs[val] === this.name) {
        clearData.call(this)
        this.fillMetaData()
      }
    },
    overlay (val, oldVal) {
      if (oldVal && !val) {
        clearData.call(this)
      }
    }
  },
  created () {
    if (this.allTabs[this.activeTab] === this.name) {
      clearData.call(this)
      this.fillMetaData()
    }
  },
  methods: {
     translateAll() {
      this.translating = true;

      const fields = ['title', 'descr']; // Add more fields if needed
        //formatDescription
      const languages = ['En', 'De', 'Nl']; // List of languages

    fields.forEach((fieldName) => {
      // Determine which translations are missing for the current field
      const missingTranslations = languages.filter(lang => !this.movieMeta[`${fieldName}${lang === 'En' ? '' : lang}`]);

      // If there are no missing translations, skip to the next field
      if (missingTranslations.length === 0) return;

      // Determine which translations are available to use as sources
      const availableTranslations = languages.filter(lang => !missingTranslations.includes(lang));

      // If there are no available translations, skip to the next field
      if (availableTranslations.length === 0) return;

      // Use the first available translation as the source
      const sourceLang = availableTranslations[0];
      const sourceFieldName = `${fieldName}${sourceLang === 'En' ? '' : sourceLang}`;
      const textToTranslate = this.movieMeta[sourceFieldName];

      // Translate to all missing languages from the source
      missingTranslations.forEach(targetLang => {
        const targetFieldName = `${fieldName}${targetLang === 'En' ? '' : targetLang}`;
        if (!this.movieMeta[targetFieldName]) {
          this.translateField(fieldName, sourceLang, targetLang, textToTranslate);
        }
      });
    });
    this.translating = false;
  },

  translateField(fieldName, sourceLang, targetLang, textToTranslate) {
    console.log("Translating field:", fieldName, "from", sourceLang, "to", targetLang);
    const targetFieldName = `${fieldName}${targetLang === 'En' ? '' : targetLang}`;

    translate(textToTranslate, sourceLang.toUpperCase(), targetLang.toUpperCase())
      .then((translationResult) => {
        this.movieMeta[targetFieldName] = translationResult.text;
        console.log(`Translation set for ${targetFieldName}:`, translationResult.text);
      })
      .catch((error) => {
        console.error(`Translation error for ${targetFieldName}:`, error);
      });
  },

    fillMetaData() {
      const data = this.savedMovie;

      // Assign basic metadata
      this.movieMeta.id = data.id;
      this.movieMeta.ean = data.ean;
      this.movieMeta.disabled = data.disabled;
      this.movieMeta.studio = data.studio.id;
      this.movieMeta.title = data.title;
      this.movieMeta.descr = this.formatDescription(data.descr);

      // Initialize transItems if it's not already set
      if (!this.transItems) {
        this.transItems = {};
      }

      // Iterate over each translation and assign to transItems
      Object.keys(data.translations).forEach(lang => {
        const translation = data.translations[lang];
        if (!this.transItems[lang]) {
          this.transItems[lang] = {};
        }
        this.movieMeta[`title${firstLetterToUpperCase(lang)}`] = translation.title;
        this.movieMeta[`descr${firstLetterToUpperCase(lang)}`] = this.formatDescription(translation.descr);
        this.transItems[lang].title = translation.title;
        this.transItems[lang].descr = this.formatDescription(translation.descr);
      });

      console.log("movie meta", this.movieMeta);
      console.log("trans items", this.transItems);
      this.movieMetaEditing = deepClone(this.movieMeta);
        // Initialize scenesDataContainer if it's not already set
      if (!this.scenesDataContainer) {
        this.scenesDataContainer = {};
      }

      // Initialize transItems for scenes if it's not already set
      if (!this.transItems.scenes) {
        this.transItems.scenes = {};
      }
      // Iterate over each scene and assign to scenesDataContainer and transItems
      for (let i = 0; i < Object.keys(data.scenes).length; i++) {
        const sceneIndex = i + 1; // Assuming scene indices are 1-based
        const scene = data.scenes[sceneIndex];

        // Initialize containers for the current scene
        this.$set(this.scenesDataContainer, i, {});
        this.$set(this.transItems.scenes, sceneIndex, {});

        // Set the default language title and description
        this.$set(this.scenesDataContainer[i], 'title', scene.title);
        this.$set(this.scenesDataContainer[i], 'descr', this.formatDescription(scene.descr));

        // Iterate over each translation for the current scene
        Object.keys(scene.translations).forEach(lang => {
          if(lang === '') return;
          const translation = scene.translations[lang];
          // Set the title and description for each language in scenesDataContainer
          this.$set(this.scenesDataContainer[i], `title${firstLetterToUpperCase(lang)}`, translation.title);
          this.$set(this.scenesDataContainer[i], `descr${firstLetterToUpperCase(lang)}`, this.formatDescription(translation.descr));


          // Set the title and description for each language in transItems
          if (!this.transItems.scenes[sceneIndex][lang]) {
            this.transItems.scenes[sceneIndex][lang] = {};
          }
          this.transItems.scenes[sceneIndex][lang].title = translation.title;
          this.transItems.scenes[sceneIndex][lang].descr = this.formatDescription(translation.descr);
        });
      }
      console.log("trans items with Scenes", this.transItems);
      console.log("sceneDataContainer ", this.scenesDataContainer);
        this.scenesDataContainerEditing = deepClone(this.scenesDataContainer);
    },

    // Helper method to format description
    formatDescription(descr) {
      if (descr) {
        if (descr.startsWith('<p>')) {
          return descr;
        } else {
          return '<p>' + descr + '</p>';
        }
      }
      return '';
    },
    hasSceneChanged(scene, index) {
      // Assuming you have a utility function like Lodash's isEqual for deep comparison
      // If you don't have Lodash installed, you can use a similar deep comparison function
      return !_.isEqual(scene, this.scenesDataContainerEditing[index]);
    },
    save (options) {
      this.loadingSave = true
      //const payload = [{movieMeta: this.movieMeta}]
      const payload = []
      // fill payload with movieMeta if it has changed from movieMetaEditing
      if (!_.isEqual(this.movieMeta, this.movieMetaEditing)) {
        payload.push({
          movieMeta: this.movieMeta
        }); 
      }

      this.scenesDataContainer.forEach((el, index) => {
        if (this.hasSceneChanged(el, index)) {
          payload.push({
            id: this.savedMovie.id,
            sceneNo: index + 1,
            data: el
          });
        }
      });
      updateAllMovieScene(payload)
        .then(() => {
          this.$notify({
            type: 'success',
            title: `All movie scenes: ${this.savedMovie.id}`,
            text: 'Updated'
          })
          this.loadingSave = false
          this.scenesDataContainerEditing = deepClone(this.scenesDataContainer)
          if (options.next) this.nextTab()
          if (options.close) this.close()
        })
        .catch(errorHandler.bind(this))
    },
  }
}
</script>
<style lang="scss">
.tv {
  .ck-editor:not(.specifity-increase){
    width: 100%;
  }
} 
</style>