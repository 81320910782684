<template>
  <ckeditor
    :value="value"
    :config="config"
    :editor="editor"
    tag-name="textarea"
    @input="value => $emit('change', value)"
  />
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  props: {
    placeholder: {
      type: String,
      default: 'Description'
    },
    value: {
      type: String,
      default: ''
    }
  },
  /**
   * @editorConfig - default ckeditor config
   * @editor - ckeditor instance
   */
  data () {
    return {
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ],
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
          ]
        },
        placeholder: 'Description'
      },
      editor: ClassicEditor
    }
  },
  computed: {
    config () {
      const data = this.editorConfig
      data.placeholder = this.placeholder
      return data
    }
  }
}
</script>
