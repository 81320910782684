export const moviesGetters = {
  savedMovie: state => {
    return state.savedMovie
  },
  movieTrailers: state => {
    if (state.savedMovie)
      return state.savedMovie.trailers || {}
    else return {}
  },
  selectedScene: state => {
    return state.selectedScene
  },
  crudType: state => {
    return state.crudType
  },
  movieCopy: state => {
    return state.movieCopy
  }
}