export const moviesMutations = {
  mutateMovieData (state, data) {
    state.savedMovie = data
  },
  mutateMovieVod (state, data) {
    state.savedMovie.vod = data
  },
  mutateMovieScene (state, data) {
    state.savedMovie.numOfScenes = data.numOfScenes
    state.savedMovie.scenes = data.scenes
  },
  mutateMoviePictures (state, data) {
    state.savedMovie.pictures = data
  },
  mutateCrudType (state, data) {
    state.crudType = data
  },
  mutateSelectedScene (state, data) {
    state.selectedScene = data
  },
  mutateMovieCopy (state, data) {
    state.movieCopy = data
  }
}