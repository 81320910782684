<template>
  <v-sheet>
    <v-container
      class="crud-container"
      grid-list-md
    >
      <v-form
        ref="vodform"
        v-model="valid"
      >
        <v-progress-linear
          v-if="loadingCheck"
          :height="2"
          color="teal"
          indeterminate
        />
        <v-alert
          :value="!hasVodData && !loadingCheck"
          type="info"
          color="teal"
          outline
        >
          MovieLib record doesn't have VOD data on flexfiles
        </v-alert>
        <br>
        <v-layout
          v-if="hasVodData"
          row
          wrap
        >
          <v-flex
            md3
            lg2
          >
            Movie
            <v-text-field
              v-model.trim="dataContainer.movieDownloadPrice"
              :disabled="!availability"
              :required="!dataContainer.isDisabled"
              :rules="!dataContainer.isDisabled ? rules.fieldRequierd : []"
              box
              flat
              type="number"
              label="Download price"
            />
            <v-text-field
              v-model.trim="dataContainer.movieDownloadSpecialPrice"
              :disabled="!availability"
              box
              flat
              type="number"
              label="Download specia price"
            />
            <v-text-field
              v-model.trim="dataContainer.movieRentPrice"
              :disabled="!availability"
              box
              flat
              type="number"
              label="Rent price"
            />
            <v-text-field
              v-model.trim="dataContainer.movieRentSpecialPrice"
              :disabled="!availability"
              box
              flat
              type="number"
              label="Rent special price"
            />
          </v-flex>
          <v-flex
            md3
            lg2
          >
            Scene
            <v-text-field
              v-model.trim="dataContainer.sceneDownloadPrice"
              :disabled="!availability"
              box
              flat
              type="number"
              label="Download price"
            />
            <v-text-field
              v-model.trim="dataContainer.sceneDownloadSpecialPrice"
              :disabled="!availability"
              box
              flat
              type="number"
              label="Download specia price"
            />
            <v-text-field
              v-model.trim="dataContainer.sceneRentPrice"
              :disabled="!availability"
              box
              flat
              type="number"
              label="Rent price"
            />
            <v-text-field
              v-model.trim="dataContainer.sceneRentSpecialPrice"
              :disabled="!availability"
              box
              flat
              type="number"
              label="Rent special price"
            />
          </v-flex>
          <v-flex
            md3
            lg3
            offset-lg4
            offset-md3
          >
            <v-switch
              v-model="dataContainer.isDisabled"
              :true-value="false"
              :false-value="true"
              label="VOD Movie Enabled"
            />
            <v-switch
              v-model="availability"
              label="VOD Movie Availability"
            />
            <v-switch
              v-model="dataContainer.canStream"
              label="Can Stream"
            />
            <v-switch
              v-model="dataContainer.canRent"
              label="Can Rent"
            />
            <v-switch
              v-model="dataContainer.canDownload"
              label="Can Download"
            />
            <DatePicker
              :data="dataContainer"
              picker-ref="expiresAt"
              label="Expires At"
              @updateSync="val => dataContainer.expiresAt = val"
              @updateDate="val => dataContainer.expiresAt = val"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-container>
    <ActionButtons
      :type="type"
      :close="close"
      :loading-save="loadingSave"
      :save="() => save({ close: false, next: false })"
      :save-and-close="() => save({ close: true, next: false })"
    />
  </v-sheet>
</template>

<script>
import ActionButtons from '@/components/common/ActionButtons'
import DatePicker from '@/components/common/DatePicker'
import { filterObject, clearData, deepClone } from '@/utils/object'
import { setMovieSalesOptions, getMovieSalesOptions } from '@/services/vod.service'
import { mapGetters, mapMutations } from 'vuex'
import { errorHandler } from '@/utils/error'

export default {
  components: {
    ActionButtons,
    DatePicker
  },
  props: {
    nextTab: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    },
    allTabs: {
      type: Array,
      default: () => []
    },
    crudTabs: {
      type: Object,
      default: () => {}
    },
    overlay: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      name: 'VOD',
      hasVodData: false,
      valid: true,
      availability: false,
      loadingSave: false,
      rules: {
        fieldRequierd: [
          v => !!v || 'This field is required'
        ]
      },
      loadingCheck: false,
      dataContainerEditing: null,
      dataContainer: {
        isDisabled: false,
        canStream: false,
        canDownload: false,
        canRent: false,
        expiresAt: null,
        movieDownloadPrice: null,
        sceneDownloadPrice: null,
        movieDownloadSpecialPrice: null,
        sceneDownloadSpecialPrice: null,
        movieRentPrice: null,
        sceneRentPrice: null,
        movieRentSpecialPrice: null,
        sceneRentSpecialPrice: null
      }
    }
  },
  computed: {
    ...mapGetters({
      type: 'movies/crudType',
      savedMovie: 'movies/savedMovie'
    })
  },
  watch: {
    overlay: {
      handler (val) {
        if (val) {
          if (this.allTabs[this.activeTab] === this.name && !this.crudTabs[this.name].unsaved) {
            switch (this.type) {
              case 'add':
                clearData.call(this)
                this.dataContainerEditing = deepClone(this.dataContainer)
                this.$refs.vodform.resetValidation()
                break
              case 'edit':
                clearData.call(this)
                this.getVodData()
                break
            }
          }
        } else {
          switch (this.type) {
            case 'add':
              if (JSON.stringify(filterObject(this.dataContainer)) !== JSON.stringify(filterObject(this.$options.data().dataContainer))) {
                this.handlePrompt()
              }
              break
            case 'edit':
              if (JSON.stringify(this.dataContainer) !== JSON.stringify(this.dataContainerEditing) && !this.crudTabs[this.name].disabled) {
                this.handlePrompt()
              }
              break
          }
        }
      },
      immediate: true
    },
    activeTab (val) {
      if (this.allTabs[val] === this.name && this.type === 'edit') {
        clearData.call(this)
        this.getVodData()
      }
    }
  },
  methods: {
    ...mapMutations(['setPrompt']),
    getVodData () {
      this.loadingCheck = true
      getMovieSalesOptions(this.savedMovie.id)
        .then(response => {
          this.hasVodData = true
          Object.keys(response.result).forEach(key => {
            this.dataContainer[key] = response.result[key]
            if ((key.includes('movie') || key.includes('scene')) && key !== 'movielibId' && this.dataContainer[key]) {
              this.availability = true
            }
          })
          if (this.hasVodData) {
            this.$refs.vodform.resetValidation()
          }
          this.loadingCheck = false
          this.dataContainerEditing = deepClone(this.dataContainer)
        })
        .catch(e => {
          if (e.error.code === 404) {
            this.loadingCheck = false
            this.dataContainerEditing = deepClone(this.dataContainer)
            this.$notify({
              type: 'error',
              title: `404 not found Movie ${this.savedMovie.id}`,
              text: 'Vod data doesn\'t exist'
            })
          } else {
            errorHandler.call(this, e)
          }
        })
    },
    save (options) {
      if (this.$refs.vodform.validate()) {
        this.loadingSave = true
        const data = deepClone(this.dataContainer)
        delete data.movielibId
        delete data.isActive

        if (!this.availability) {
          this.dataContainer.movieDownloadPrice = null
          this.dataContainer.sceneDownloadPrice = null
          this.dataContainer.movieDownloadSpecialPrice = null
          this.dataContainer.sceneDownloadSpecialPrice = null
          this.dataContainer.movieRentPrice = null
          this.dataContainer.sceneRentPrice = null
          this.dataContainer.movieRentSpecialPrice = null
          this.dataContainer.sceneRentSpecialPrice = null
        }

        setMovieSalesOptions(this.savedMovie.id, data)
          .then(response => {
            Object.keys(response.result).forEach(key => {
              this.dataContainer[key] = response.result[key]
              if ((key.includes('movie') || key.includes('scene')) && key !== 'movielibId' && this.dataContainer[key]) {
                this.availability = true
              }
            })
            this.dataContainerEditing = deepClone(this.dataContainer)
            this.loadingSave = false
            this.$notify({
              type: 'success',
              title: `VOD data for: ${this.savedMovie.id}`,
              text: 'Updated'
            })
            this.$emit('refreshTable')
            if (options.close) this.close()
          })
          .catch(errorHandler.bind(this))
      } else {
        this.$notify({
          type: 'error',
          title: 'Add Movie',
          text: 'Fill required fields'
        })
      }
    },
    handlePrompt () {
      this.setPrompt({
        title: 'Unsaved form',
        message: 'You have unsaved VOD form, do you want do delete form fields data?',
        confirmMethod: function () {
          this.$emit('hasUnsaved', 'VOD')
          clearData.call(this)
          this.$refs.vodform.resetValidation()
        }.bind(this),
        cancelMethod: function () {
          const query = { ...this.$route.query }
          query.mode = this.type
          if (this.type === 'edit') query.id = this.savedMovie.id
          this.$router.replace({ query }).catch(() => {})
          this.$emit('hasUnsaved', 'VOD')
          this.close()
        }.bind(this)
      })
    }
  }
}
</script>
