import { mapMutations } from 'vuex'
import { errorHandler } from '@/utils/error'
import { updateBackgroundFocalPoints } from '@/services/categories.service'
import { firstLetterToUpperCase } from '@/utils/string'

export const crudComponentsDefaults = {
  props: {
    close: {
      type: Function,
      default: () => { }
    },
    type: {
      type: String,
      default: 'add'
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  /**
   * @valid (boolean) hold form valid state
   * @unsaved (boolean) hold form unsaved state of some form field
   * @loadingSave (boolean) hold form loading state
   * @dataContainerEditing (object/null) hold copy of initial state of form fields in editing mode
   * @rules (object) list of rules applied to form fields
   */
  data() {
    return {
      valid: true,
      unsaved: false,
      loadingSave: false,
      dataContainerEditing: null,
      rules: {
        fieldRequierd: [v => !!v || 'This field is required']
      }
    }
  },
  computed: {
    /**
     * @returns text for save action button
     */
    saveText() {
      return this.type === 'add' ? 'Save' : 'Update'
    },
    /**
     * @returns title for crud form
     */
    tabTitle() {
      const id = this.savedData
        ? this.savedData.id
          ? this.savedData.id
          : ''
        : ''
      return this.type === 'add' ? 'Add New' : `Edit ${id}`
    }
  },
  watch: {
    /**
     * this watcher is to track unsaved state of the form
     */
    isActive: {
      handler(val) {
        if (val) {
          if (!this.unsaved) {
            switch (this.type) {
              case 'add':
                this.clearData()
                break
              case 'edit':
                this.clearData()
                // this is studio only related to get restrictions for each studio
                if (this.getRestrictions)
                  this.getRestrictions(this.savedData.id)
                if (this.getStudioFeaturedCategoriesData)
                  this.getStudioFeaturedCategoriesData(this.savedData.id)

                // this is series only related to get if serie is featured
                if (this.isSerieFeatured)
                  this.isSerieFeatured(this.savedData.id)

                // this is pornstars only related to get alternative similar names
                if (this.searchAltNAmes)
                  this.searchAltNAmes(this.savedData.name)
                this.fillEditingData(this.savedData)
                break
            }
          }
        } else {
          switch (this.type) {
            case 'add':
              if (
                JSON.stringify(this.filterObject(this.dataContainer)) !==
                JSON.stringify(
                  this.filterObject(this.$options.data().dataContainer)
                )
              ) {
                this.handlePrompt()
              }
              break
            case 'edit':
              if (
                JSON.stringify(this.dataContainer) !==
                JSON.stringify(this.dataContainerEditing)
              ) {
                this.handlePrompt()
              }
              break
          }
        }
      },
      immediate: true
    },
    /**
     * this watcher track crud type and clear + reset form validation
     */
    type(newVal, oldVal) {
      if (newVal === 'add' && oldVal === 'edit') {
        this.clearData()
        this.$refs[this.formRef].resetValidation()
      }
    }
  },
  methods: {
    ...mapMutations(['setPrompt']),
    /**
     * Fill component state with data from API
     * @param {Array} data data from API
     */
    fillEditingData(data) {
      Object.keys(data).forEach(key => {
        /**
         * we have to find better workaround for translations,
         * this is a bit odd solution but properties from get and post API for translations do not match
         */
        switch (key) {
          case 'translations':
            if (data[key] && !Array.isArray(data[key])) {
              Object.keys(data[key]).forEach(lang => {
                Object.keys(data[key][lang]).forEach(prop => {
                  this.dataContainer[`${prop}${firstLetterToUpperCase(lang)}`] = data[key][lang][prop];
                });
              });
            }
            break
          case 'bgImage':
            if (data[key]) {
              this.backgroundImageHard.mediaUrl = data[key].image_url || data[key];
              if (data[key] && data[key].focalpoint_x && data[key].focalpoint_y) {
                this.backgroundImageHard.focalpoint_x = data[key].focalpoint_x
                this.backgroundImageHard.focalpoint_y = data[key].focalpoint_y
              }
              this.backgroundImageHard.uploaded = true
            }
            break
          case 'logo':
          case 'bgImageSoft':
            if (data[key]) {
              this.backgroundImage.mediaUrl = data[key].image_url || data[key];
              if (data[key] && data[key].focalpoint_x && data[key].focalpoint_y) {
                this.backgroundImage.focalpoint_x = data[key].focalpoint_x
                this.backgroundImage.focalpoint_y = data[key].focalpoint_y
              }
              this.backgroundImage.uploaded = true
            }
            break
          case 'keywords':
            Object.keys(data.keywords).forEach(key => {
              const property = data.keywords[key]
              if (!this.dataContainer.keywords[property.lang])
                this.dataContainer.keywords[property.lang] = []
              this.dataContainer.keywords[property.lang].push(property.value)
            })
            break
          case 'studio':
            if (typeof data.studio === 'object' && data.studio !== null) {
              this.dataContainer.studio = data.studio.id
            } else if (
              typeof data.studio === 'number' &&
              data.studio !== null
            ) {
              this.dataContainer.studio = data.studio
            }
            break
          case 'group':
            if (data[key] && !Array.isArray(data[key])) {
              this.dataContainer.categoryGroup = data.group.id
            }
            break
          case 'alias_of':
            if (data.alias_of) {
              this.pornstars.push(data.alias_of)
              this.dataContainer.alias_of = data.alias_of.id
            }
            break
          default:
            this.dataContainer[key] = data[key]
        }
      })
      this.dataContainerEditing = this.deepClone(this.dataContainer)
    },
    /**
     * run after API create/update call is resolved
     * helper function to store saved data and reset unsaved state of form
     * @param {object} data
     * @param {object} options
     * @param {string} type
     */
    saveResponse(id, options, type) {
      // Set focalPoints if they are set and media is not uploaded yet (focalpoints will be set on upload)
    if (!this.readyForUpload && (this.dataContainer?.focalPoints?.backgroundImage?.x || this.dataContainer?.focalPoints?.backgroundImageHard?.x)) {
        const images = ['backgroundImage', 'backgroundImageHard']
        for (const imageKey of images) {
          const focalPoint = this.dataContainer.focalPoints[imageKey]
          if (focalPoint.x !== null) {
            updateBackgroundFocalPoints(id, focalPoint, imageKey === 'backgroundImageHard')
              .then(data => {
                this.$notify({
                  type: 'success',
                  title: `Focuspoints ${imageKey === 'backgroundImageHard' ? 'Hard' : 'Soft'} Image Updated`,
                  text: data.result.message
                })
              })
              .catch(error => {
                this.$notify({
                  type: 'error',
                  title: `Focuspoints ${imageKey === 'backgroundImageHard' ? 'Hard' : 'Soft'} Image Update Error`,
                  text: error.message
                })
                throw error
              })
          }
        }
      }
      // in order to wait for record update/creation, we set refresh of listing API to half second
      setTimeout(
        () => {
          this.$emit('refreshTable')
        },
        500,
        this
      )
      if (type === 'add') {
        this.$emit('updateFormType', 'edit')
        this.updateSavedData({ id })
      }
      if (this.readyForUpload) {
        this.uploadBackgroundImage(id).then(() => {
          this.unsaved = false
          this.loadingSave = false
          this.backgroundImage.uploaded = true
          this.dataContainerEditing = this.deepClone(this.dataContainer)
          if (options.close) {
            this.close()
          }
          if (options.next) {
            this.nextTab()
          }
        })
      } else {
        this.dataContainerEditing = this.deepClone(this.dataContainer)
        this.unsaved = false
        this.loadingSave = false

        if (options.close) {
          this.close()
        }
        if (options.next) {
          this.nextTab()
        }
      }
      if (this.selectedStudioCategories) {
        if (this.selectedStudioCategories.length) {
          this.setStudioFeaturedCategories(this.savedData.id, {
            featuredCategories: this.selectedStudioCategories
          }).then(() => {
            this.$notify({
              type: 'success',
              title: 'Studio Featured Categories',
              text: 'Successfully updated'
            })
          })
        }
      }
      this.$notify({
        type: 'success',
        title: `${type === 'add' ? id : this.savedData.id} ${type === 'add' ? 'Saved' : 'Updated'
          }`
      })
    },
    /**
     * call prompt if form has unsaved field
     */
    handlePrompt() {
      this.setPrompt({
        title: 'Unsaved form',
        message:
          'You have unsaved form, do you want do delete form fields data?',
        confirmMethod: function () {
          this.unsaved = true
          this.clearData()
          this.$refs[this.formRef].resetValidation()
        }.bind(this),
        cancelMethod: function () {
          const query = { ...this.$route.query }
          query.mode = this.type
          if (this.type === 'edit') query.id = this.dataContainer.id
          this.$router.replace({ query }).catch(() => { })
          this.unsaved = true
          this.close()
        }.bind(this)
      })
    },
    /**
     * reset component state to it's initial value
     */
    clearData() {
      Object.assign(this.$data, this.$options.data())
    },
    /**
     * goes through object and remove all properties with no value
     * @param {object} oldVal
     * @returns filtered object
     */
    filterObject(oldVal) {
      return Object.keys(oldVal).reduce((acc, key) => {
        const data = { ...acc }
        if (
          (oldVal[key] && !Array.isArray(oldVal[key])) ||
          (Array.isArray(oldVal[key]) && oldVal[key].length)
        ) {
          data[key] = oldVal[key]
        }
        return data
      }, {})
    },
    /**
     * returns new object copied with new reference
     * @param {object} o
     * @returns cloned object (new reference)
     */
    deepClone(o) {
      if (o && typeof o === 'object') {
        if (Array.isArray(o)) {
          return o.map(a => this.deepClone(a))
        } else if (o.constructor === Object) {
          return Object.entries(o).reduce(
            (prev, [k, v]) => ({ ...prev, [k]: this.deepClone(v) }),
            {}
          )
        }
        return o
      }
      return o
    }
  }
}

export const crudImageFunctions = {
  methods: {
    /**
     * triggers an click event on filepicker
     */
    pickFile() {
      this.$refs.image.click()
    },
    /**
     * After file is picked data is stored and prepare for upload
     * @param {event} e
     */
    onFilePicked(e) {
      const checkIfHardImageUploader = e.currentTarget.parentNode.getAttribute(
        'hard-image-uploader'
      )
      const useFocals = e.currentTarget.parentNode.getAttribute('use-focals')
      const files = e.target.files || e.dataTransfer.files
      const selectedInput = checkIfHardImageUploader
        ? this.backgroundImageHard
        : this.backgroundImage
      if (files[0] !== undefined) {
        selectedInput.mediaFile = files[0].name
        if(useFocals){
          // set default focal point to center
          this.dataContainer.focalPoints[checkIfHardImageUploader ? 'backgroundImageHard' : 'backgroundImage'] = { x: 50, y: 50 }
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          selectedInput.mediaUrl = fr.result
          selectedInput.mediaFile = files[0]
          this.isHard = Boolean(checkIfHardImageUploader)
          if (this.isHard) {
            this.readyForUpload = {
              ...this.readyForUpload,
              backgroundImageHard: files[0]
            }
          } else {
            this.readyForUpload = {
              ...this.readyForUpload,
              backgroundImage: files[0]
            }
          }
        })
      } else {
        selectedInput.mediaName = ''
        selectedInput.mediaFile = ''
        selectedInput.mediaUrl = ''
        if (this.isHard) {
          this.readyForUpload = {
            ...this.readyForUpload,
            backgroundImageHard: null
          }
        } else {
          this.readyForUpload = {
            ...this.readyForUpload,
            backgroundImage: null
          }
        }
      }
    },
    /**
     * removing image from component state, if image is uploaded and exist on backend,
     * it will be removed with removePicture API call
     */
    clearImage(id) {
      this.$notify({
        type: 'success',
        title: `Background image`,
        text: 'Removing image...'
      })
      this[id].deleteLoading = true

      if (this[id].uploaded) {
        this.removePicture(this.savedData.id, this[id])
          .then(() => {
            this[id].deleteLoading = false

            this.$notify({
              type: 'success',
              title: `${this.savedData.id} background`,
              text: 'Successfully removed'
            })
            /* TODO: possible bug if there is an another image in que  */
            this.readyForUpload = null
            this[id].mediaUrl = ''
            this[id].mediaFile = ''
            this[id].uploaded = false
          })
          .catch(errorHandler.bind(this))
      } else {
        this.readyForUpload = null
        this[id].mediaUrl = ''
        this[id].mediaName = ''
        this[id].uploaded = false
        this[id].deleteLoading = false
      }
    },
    uploadAndNotify(type, id) {
      return new Promise((resolve, reject) => {
        const imageKey =
          type === 'hard' ? 'backgroundImageHard' : 'backgroundImage'
        const isHard = type === 'hard'

        if (this.readyForUpload[imageKey]) {
          const formData = new FormData()
          formData.append(this[imageKey].name, this.readyForUpload[imageKey])

          if (this.dataContainer.focalPoints && this.dataContainer.focalPoints[imageKey].x && this.dataContainer.focalPoints[imageKey].y){
            formData.append('focalpoint_x', this.dataContainer.focalPoints[imageKey].x)
            formData.append('focalpoint_y', this.dataContainer.focalPoints[imageKey].y)
          }

          this.uploadBackground(id, formData, isHard)
            .then(data => {
              this.$notify({
                type: 'success',
                title: `Media ${id} Uploaded`,
                text: data.result.message
              })

              this.readyForUpload[imageKey] = null
              resolve(data)
            })
            .catch(error => {
              this.$notify({
                type: 'error',
                title: 'Upload Failed',
                text: error.message
              })
              reject(error)
            })
        } else {
          resolve(null)
        }
      })
    },
    /**
     * Upload image based on component uploadBackgroundImage API
     * @param {integer} id
     */
    uploadBackgroundImage(id) {
      return Promise.all([
        this.uploadAndNotify('soft', id),
        this.uploadAndNotify('hard', id)
      ])
        .then(([softData, hardData]) => {
          // eslint-disable-next-line no-console
          console.log(softData, hardData)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }
  }
}

export const crudObjectHelpers = {
  methods: {
    /**
     *
     * @param {array} data
     * @param {object} item
     * removes an item from an array (mutating direct array)
     */
    removeItem(data, item) {
      let index
      if (typeof data[0] === 'number') {
        index = data.indexOf(item)
      } else {
        index = data.map(e => e.id).indexOf(item)
      }
      if (index >= 0) data.splice(index, 1)
    }
  }
}
