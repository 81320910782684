<template>
  <v-navigation-drawer
    v-if="loggedIn"
    id="app-drawer"
    v-model="inputValue"
    :mini-variant="drawerMini"
    app
    dark
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-layout
      class="fill-height"
      column
    >
      <v-list class="pa-1">
        <v-list-tile
          v-if="drawerMini"
          @click.stop="setDrawerMini(!drawerMini)"
        >
          <v-list-tile-action class="burger-menu">
            <v-icon>menu</v-icon>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile
          v-if="!drawerMini"
          avatar
          tag="div"
        >
          <v-list-tile-content v-if="!drawerMini">
            <v-list-tile-title class="title-center">
              <router-link
                to="/"
                class="logo-link"
              >
                Movie Manager
              </router-link>
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action
            class="drawer-minimizer"
          >
            <v-icon
              v-if="!drawerMini"
              @click.stop="setDrawerMini(!drawerMini)"
            >
              menu
            </v-icon>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <v-list
        class="pt-0"
        dense
      >
        <v-list-tile
          v-for="(link, i) in links"
          :key="i + link + 'navigation-list-small'"
          :to="link.to"
          :class="drawerMini ? 'v-list-item center-icons' : 'v-list-item'"
          active-class="success"
          avatar
        >
          <v-list-tile-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-text="link.text" />
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  /**
   * @drawer - drawer state
   * @items - drawer head items
   * @links - drawer navigation towars manager pages
   */
  data () {
    return {
      drawer: true,
      items: [
        { title: 'Home', icon: 'dashboard' },
        { title: 'About', icon: 'question_answer' }
      ],
      links: [
        {
          to: '/video-manager',
          icon: 'movie',
          text: 'Video Manager'
        },
        {
          to: '/category-manager',
          icon: 'dns',
          text: 'Category Management'
        },
        {
          to: '/series-manager',
          icon: 'local_movies',
          text: 'Series Management'
        },
        {
          to: '/studio-manager',
          icon: 'videocam',
          text: 'Studio Management'
        },
        {
          to: '/image-manager?censoredSearch=unknown&p=1&rpp=36&order=desc&orderBy=id',
          icon: 'collections',
          text: 'Image Management'
        },
        {
          to: '/pornstar-manager',
          icon: 'supervisor_account',
          text: 'Pornstar Management'
        }
      ],
    }
  },
  computed: {
    ...mapGetters(['loggedIn', 'drawerMini']),
    /**
     * setting and getting drawer state from vuex
     */
    inputValue: {
      get () {
        return this.$store.getters.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    }
  },
  methods: {
    ...mapMutations(['setDrawer', 'setDrawerMini'])
  }
}
</script>
