<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      xs12
      class="category-options"
    >
      <span class="text-sm-left categories-title">Categories</span>
      <v-switch
        v-model="hide"
        label="Hide"
        style="justify-content: flex-end;"
      />
      <v-switch
        v-model="german"
        :label="`Language: ${german ? 'De' : 'En'}`"
        style="justify-content: flex-end;"
      />
    </v-flex>
    <v-flex
      xs12
      class="checkbox-container"
    >
      <Checkbox
        v-for="item in sortedCategories"
        v-model="selected"
        :key="item.id + 'checkboxCategories'"
        :label="german ? item.nameDe : item.name"
        :value="item.id"
        class="checkbox-category"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import Checkbox from '@/components/common/Checkbox'

export default {
  components: {
    Checkbox
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      german: true,
      hide: true
    }
  },
  computed: {
    selected: {
      get () { return this.value },
      set (selected) {
        this.$emit('change', selected)
      }
    },
    filteredCategories () {
      return this.categories.filter(el => !window.HideCategories.includes(el.id))
    },
    sortedCategories () {
      if (this.german) {
        if (this.hide) {
          return [...this.filteredCategories].sort((a, b) => (a.nameDe > b.nameDe) ? 1 : -1)
        } else {
          return [...this.categories].sort((a, b) => (a.nameDe > b.nameDe) ? 1 : -1)
        }
      } else {
        if (this.hide) {
          return this.filteredCategories
        } else {
          return this.categories
        }
      }
    }
  },
  watch: {
    german (val) {
      localStorage.setItem('langSettings', val)
    },
    hide (val) {
      localStorage.setItem('hideSettings', val)
    }
  },
  created () {
    const hideSettings = localStorage.getItem('hideSettings')
    const langSettings = localStorage.getItem('langSettings')

    if (hideSettings) this.hide = hideSettings === 'true'
    if (langSettings) this.german = langSettings === 'true'
  }
}
</script>
