<template>
  <v-dialog
    ref="studio-dialog"
    :value="active"
    persistent
    full-width
    width="400px"
    @change="val => $emit('updateActive', val)"
  >
    <v-sheet>
      <v-container
        grid-list-md
        fluid
        text-xs-center
      >
        <h3 class="heading text-lg-left text-md-left">
          Studio removal
        </h3>
        <v-btn
          icon
          dark
          absolute
          round
          right
          class="close-btn"
          @click="$emit('closedialog')"
        >
          <v-icon class="close-icon">
            close
          </v-icon>
        </v-btn>
        <hr><br>
        <v-flex
          xs12
          text-xs-left
        >
          Removing the Studio from the Movie will effect and remove selected Serie for that Movie.<br>
          For undoing the process close movie form and delete unsaved data.
        </v-flex>
        <v-flex
          xs12
          text-xs-right
        >
          <v-btn
            color="info"
            class="mx-1 white--text"
            @click="$emit('closedialog')"
          >
            Close
          </v-btn>
        </v-flex>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
