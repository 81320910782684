<template>
  <v-dialog
    ref="cover-select-dialog"
    :value="active"
    persistent
    lazy
    width="900px"
    @change="val => $emit('updateActive', val)"
    @keydown.prevent="fastForwarsRewind"
  >
    <v-sheet>
      <v-container
        grid-list-md
        fluid
        text-xs-center
      >
        <v-btn
          icon
          dark
          absolute
          round
          right
          class="close-btn"
          @click="closeForm"
        >
          <v-icon class="close-icon">
            close
          </v-icon>
        </v-btn>
        <h3>Select Cover from VOD scene</h3>
        <video
          v-show="selectedScene.link && !frame"
          width="100%"
          ref="covervideo"
          muted
          crossorigin="anonymous"
          :src="selectedScene.link"
          controls
          @keydown.prevent="preventVideoSeek"
        />
        <img
          v-if="frame"
          :src="frame"
          class="captured-frame"
          alt="captured frame"
        >
        <div
          v-if="frame"
          class="resolution-placeholder"
        >
          {{ `${selectedScene.width}x${selectedScene.height}` }}
        </div>
      </v-container>
      <v-flex
        class="cover-select-controls"
        xs12
        text-xs-right
      >
        <v-layout>
          <v-flex
            xs3
            class="captured-radio-buttons"
          >
            <v-radio-group
              v-if="frameCaptured"
              v-model="isCensored"
              :mandatory="false"
              row
            >
              <v-radio
                :value="true"
                label="Censored"
              />
              <v-radio
                :value="false"
                label="Uncensored"
              />
            </v-radio-group>
          </v-flex>
          <v-flex xs9>
            <v-btn
              v-if="frameCaptured"
              :loading="loadingSave"
              :disabled="loadingSave"
              color="info"
              class="mx-1 white--text"
              @click="uploadCapturedFrame"
            >
              Upload Captured Frame
            </v-btn>
            <v-btn
              :color="frameCaptured ? 'error' : 'info'"
              class="mx-1 white--text"
              @click="captureCurrentFrame"
            >
              {{ frameCaptured ? 'Clear captured frame' : 'Capture current frame' }}
            </v-btn>
            <v-btn
              color="error"
              class="mx-1 white--text"
              @click="closeForm"
            >
              Close
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { savePicturesFromUrl } from '@/services/movie.service'
import { errorHandler } from '@/utils/error'
import { mapMutations } from 'vuex'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    selectedScene: {
      type: Object,
      default: () => {}
    },
    movieId: {
      type: Number,
      default: null
    },
    savedMovie: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      frameCaptured: false,
      offset: null,
      frame: null,
      isCensored: this.selectedScene.isCensored,
      loadingSave: false
    }
  },

  watch: {
    'selectedScene.isCensored' (val) {
      this.isCensored = val
    }
  },
  methods: {
    ...mapMutations({
      updateMoviePictures: 'movies/mutateMoviePictures',
    }),
    captureCurrentFrame () {
      if (this.frameCaptured) {
        this.clearCapturedFrame()
        return
      }
      if (this.$refs.covervideo.paused) {
        this.offset = this.$refs.covervideo.currentTime
        const canvas = document.createElement('canvas')
        canvas.width = this.selectedScene.width
        canvas.height = this.selectedScene.height
        canvas.getContext('2d').drawImage(this.$refs.covervideo, 0, 0, canvas.width, canvas.height)
        this.frame = canvas.toDataURL('image/jpeg')
        this.frameCaptured = true
      } else {
        this.$refs.covervideo.pause()
        this.captureCurrentFrame ()
      }
    },
    clearCapturedFrame () {
      this.frame = null,
      this.frameCaptured = false,
      this.isCensored = false
      this.offset = null
    },
    uploadCapturedFrame () {
      this.loadingSave = true
      const promises = []

      const imageData = {
        file: this.frame,
        type: 'cover',
        censored: this.isCensored,
        scene: this.selectedScene.number,
        offset: parseInt(this.offset)
      }

      const mode = this.selectedScene.id ? 'update' : 'add'

      promises.push({
        id: this.selectedScene.id ? this.selectedScene.id : this.movieId,
        mode,
        data: imageData
      })

      savePicturesFromUrl(promises)
        .then(response => {
            const moviePictures = { ...this.savedMovie.pictures }
            const sceneNo = this.selectedScene.number
            const picture = response[0].data.result.picture

            if (!moviePictures.scenes) moviePictures.scenes = {}
            if (!moviePictures.scenes[sceneNo]) moviePictures.scenes[sceneNo] = {}
            if (!moviePictures.scenes[sceneNo]['cover']) moviePictures.scenes[sceneNo]['cover'] = []

            if (mode === 'update' && moviePictures.scenes[sceneNo]['cover'].length) {
              for (let i = 0; i < moviePictures.scenes[sceneNo]['cover'].length; i++)
                if (moviePictures.scenes[sceneNo]['cover'][i].censored === picture.censored)
                  moviePictures.scenes[sceneNo]['cover'].splice(i,1)
            }
            moviePictures.scenes[sceneNo]['cover'].push(picture)

            this.updateMoviePictures({
              ...this.savedMovie.pictures,
              ...moviePictures
            })
          this.loadingSave = false
          this.clearCapturedFrame()
          this.$notify({
            type: 'success',
            title: 'Media',
            text: `Scene ${this.selectedScene.number} ${this.isCensored ? 'censored' : 'uncensored'} cover updated`
          })
        })
        .catch(errorHandler.bind(this))
    },
    closeForm () {
      this.$refs.covervideo.pause()
      this.$refs.covervideo.currentTime = 0
      this.clearCapturedFrame()
      this.$emit('updateActive', false)
    },
    preventVideoSeek(e) {
      e.preventDefault()
    },
    fastForwarsRewind (e) {
        e = e || window.event
        e.preventDefault()

        if (e.keyCode == '32') {
          if (this.$refs.covervideo.paused)
            this.$refs.covervideo.play()
          else
            this.$refs.covervideo.pause()
        }

        if (e.keyCode == '37') {
          this.$refs.covervideo.currentTime = this.$refs.covervideo.currentTime - 1
        }

        if (e.keyCode == '39') {
          this.$refs.covervideo.currentTime = this.$refs.covervideo.currentTime + 1
        }
    }
  }
}
</script>
