<template>
  <v-container
    class="crud-container"
    grid-list-sm
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        sm12
      >
        <v-layout
          row
          justify-center
          wrap
        >
          <v-flex
            lg2
            md2
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'cover'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Cover"
              resolution="166x0"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg2
            md2
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'coverfront'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Cover Front"
              resolution="166x0"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg2
            md2
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'coverback'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Cover Back"
              resolution="166x0"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg5
            md5
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'dvdcover'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="DVD Cover"
              resolution="0x236"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        sm12
      >
        <v-layout
          row
          justify-center
          wrap
        >
          <v-flex
            lg2
            md2
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'coverCensored'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Cover Censored"
              resolution="166x0"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg2
            md2
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'coverfrontCensored'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Cover Front Censored"
              resolution="166x0"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg2
            md2
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'coverbackCensored'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="Cover Back Censored"
              resolution="166x0"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
          <v-flex
            lg5
            md5
            sm12
            class="small-image"
          >
            <ImageContainer
              :id="'dvdcoverCensored'"
              :on-file-picked="onImageFilePicked"
              :media-set="currentImageSet"
              title="DVD Cover Censored"
              resolution="0x236"
              @updateClicked="val => $emit('updateClicked', val)"
              @removeImage="id => clearImage(id)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ImageContainer from '@/components/common/ImageContainer'

export default {
  components: {
    ImageContainer
  },
  props: {
    currentImageSet: {
      type: Object,
      default: () => {}
    },
    onImageFilePicked: {
      type: Function,
      default: () => {}
    },
    clearImage: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
