<template>
  <div>
    <v-tabs
      :value="activeTab"
      color="grey lighten-2"
      light
      slider-color="teal"
      @change="val => activeTab = val"
    >
      <v-tab
        v-for="(item, index) in allTabs"
        :key="item + index"
        :disabled="crudTabs[item].disabled"
        ripple
      >
        {{ item }}
        <v-icon
          v-if="crudType === 'edit' && item === 'Media' && crudTabs[item].missingCover"
          size="25"
          style="max-width: 25px;"
          class="mr-2"
        >
          report_problem
        </v-icon>
      </v-tab>
      <v-tab-item
        v-for="item in allTabs"
        :key="item"
      >
        <keep-alive>
          <component
            :is="item"
            :next-tab="goToNextTab"
            :close="close"
            :crud-tabs="crudTabs"
            :overlay="overlay"
            :active-tab="activeTab"
            :all-tabs="allTabs"
            :selected="selected"
            :check-images="checkImages"
            @refreshTable="$emit('refreshTable')"
            @hasUnsaved="val => $emit('hasUnsaved', val)"
            @hasSaved="val => $emit('hasSaved', val)"
          />
        </keep-alive>
      </v-tab-item>
      <v-select
        v-if="crudType === 'edit' || crudType === 'edit-scene'"
        :value="selectedScene"
        :items="scenesDropdown"
        box
        dense
        single-line
        class="scene-selection"
        hide-details
        @change="val => updateSelectedScene(val)"
      />
      <v-btn
        icon
        dark
        absolute
        round
        right
        class="close-btn"
        @click="close"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-tabs>
  </div>
</template>

<script>
import Metadata from './Tabs/MetaData/MetaData'
import Scenes from './Tabs/Scenes/Scenes'
import Media from './Tabs/Media/Media'
import VOD from './Tabs/Vod/Vod'
import Translations from './Tabs/Translations/Translations'
import { checkImagesByEan, savePicturesFromUrl } from '@/services/movie.service'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Metadata,
    Scenes,
    Media,
    Translations,
    VOD
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    overlay: {
      type: Boolean,
      default: false
    },
    goToNextTab: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    },
    crudTabs: {
      type: Object,
      default: () => {}
    },
    allTabs: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapGetters({
      savedMovie: 'movies/savedMovie',
      selectedScene: 'movies/selectedScene',
      crudType: 'movies/crudType'
    }),
    activeTab: {
      get () { return this.value },
      set (value) { this.$emit('change', value) }
    },
    scenesDropdown () {
      const arr = []
      const numOfScenes = this.savedMovie ? this.savedMovie.numOfScenes : 0
      arr.push({ text: 'Movie', value: 0 })
      for (let i = 1; i <= numOfScenes; i++) {
        arr.push({ text: 'Scene ' + i, value: i })
      }
      return arr
    }
  },
  watch: {
    selectedScene (val) {
      if (val)
        this.updateCrudType('edit-scene')
      else
        this.updateCrudType('edit')
    },
    overlay (val) {
      if (!val) {
        this.updateSelectedScene(0)
        this.$emit('change', 0)
      }
    }
  },
  methods: {
    ...mapMutations({
      updateCrudType: 'movies/mutateCrudType',
      updateSelectedScene: 'movies/mutateSelectedScene',
      updateMoviePictures: 'movies/mutateMoviePictures',
    }),
    imagesUpload (images, id, list) {
      return new Promise(async (resolve, reject) => {
        try {
          const promises = []
          for (let i = 0; i < Object.keys(images).length; i++) {
            const image = images[Object.keys(images)[i]]
            if (image.exist) {
              const formData = new FormData()
              formData.append('file', image.url)
              formData.append('type', image.type)
              formData.append('censored', image.censored ? 1 : 0)
              formData.append('part', -1)
              formData.append('offset', -1)
              if (list) {
                if (list[`${image.type}${image.censored ? 'Censored' : ''}`].id) {
                  promises.push({
                    id: list[`${image.type}${image.censored ? 'Censored' : ''}`].id,
                    mode: 'update',
                    data: formData
                  })
                } else {
                  promises.push({
                    id,
                    mode: 'add',
                    data: formData
                  })
                }
              } else {
                promises.push({
                  id,
                  mode: 'add',
                  data: formData
                })
              }
            }
          }
          const NOTIFY_UPLOADING = Date.now()
          this.$notify({
            id: NOTIFY_UPLOADING,
            type: 'success',
            title: 'Images found',
            text: `Uploading ${promises.length} images`,
            duration: -1
          })
          const values = await savePicturesFromUrl(promises)
          this.$notify.close(NOTIFY_UPLOADING)
          resolve(values)
        } catch (e) {
          reject(e)
        }
      })
    },
    checkImages (auxArr, id, list) {
      return new Promise(async (resolve, reject) => {
        try {
          const found = {}
          for (let i = 0; i < auxArr.length; i++) {
            const images = await checkImagesByEan(auxArr[i])
            found[auxArr[i]] = {}
            for (let j = 0; j < images.length; j++) {
              if (images[j].exist) {
                found[auxArr[i]][`${images[j].type}${images[j].censored ? 'Censored' : ''}`] = images[j]
              } else {
                this.$notify({
                  type: 'warn',
                  title: `${images[j].type}${images[j].censored ? 'Censored' : ''} Image`,
                  text: 'Not found',
                  duration: 8000
                })
              }
            }
            // here you check if you want to continue searching for images
            if (Object.keys(found[auxArr[i]]).length >= 2) break
            else continue
          }
          const lengthOfProperties = Object.keys(found).map(el => Object.keys(found[el]).length)
          if (lengthOfProperties.length) {
            const max = Math.max(...lengthOfProperties)
            if (max) {
              const index = lengthOfProperties.indexOf(max)
              this.$notify({
                type: 'success',
                title: `Search finished, best result:`,
                text: `Found ${max} images from ${Object.keys(found)[index]} EAN`,
                duration: 4000
              })
              // uploading images for ean that have largest amount of images.
              const values = await this.imagesUpload(found[Object.keys(found)[index]], id, list)
              const pictures = {}
              values.forEach(el => {
                if (pictures.hasOwnProperty(el.data.result.picture.type[0])) {
                  pictures[el.data.result.picture.type[0]].push(el.data.result.picture)
                } else {
                  pictures[el.data.result.picture.type[0]] = []
                  pictures[el.data.result.picture.type[0]].push(el.data.result.picture)
                }
              })
              this.updateMoviePictures(pictures)
              values.forEach(el => {
                this.$notify({
                  type: 'success',
                  title: el.data.result.message,
                  text: `${el.data.result.id} Media Updated`
                })
              })
              resolve()
            } else {
              // no images found for any of provided eans
              this.$notify({
                type: 'error',
                title: 'Cover images not found',
                text: 'no images found for any of provided eans',
                duration: 10000
              })
              resolve()
            }
            resolve()
          } else {
            // no images found for any of provided eans
            this.$notify({
              type: 'error',
              title: 'Cover images not found',
              text: 'no images found for any of provided eans',
              duration: 10000
            })
            resolve()
          }
        } catch (e) {
          reject(e)
        }
      })
    }
  }
}
</script>
