import httpMovieLib from './http/httpMovieLib'
import httpShopAdm from './http/httpShopAdm'
import apiHandler from '@/services/http/api'

const multiPart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const studioGrid = (searchParameters, pagination) => {
  let searchString = ''

  Object.keys(searchParameters).forEach(el => {
    if (searchParameters[el] !== '') {
      switch (el) {
        case 'name': {
          searchString += `&sn[0][o]=cs&sn[0][v]=${searchParameters[el]}`
          break
        }
        case 'active': {
          searchString += `&sv[0][o]=is&sv[0][v]=${searchParameters[el]}`
          break
        }
        case 'logo': {
          searchString += `&sl[0][o]=is&sl[0][v]=${searchParameters[el]}`
          break
        }
        case 'id': {
          searchString += `&id[0][o]=is&id[0][v]=${searchParameters[el]}`
          break
        }
      }
    }
  })

  return apiHandler(httpMovieLib.get(`/api/studios/grid?sort=${pagination.orderBy}&dir=${pagination.order}&limit=${pagination.rowsPerPage}&page=${pagination.page}${searchString}`))
}
export const createStudio = data => apiHandler(httpMovieLib.post('/api/studio/add', data))

export const updateStudio = (id, data) => apiHandler(httpMovieLib.put(`/api/studio/${id}`, data))

export const getStudioDetails = id => apiHandler(httpMovieLib.get(`/api/studio/${id}`))

export const deleteStudio = (id) => apiHandler(httpMovieLib.delete(`/api/studio/${id}`))

export const getStudioOptions = () => apiHandler(httpMovieLib.get('/api/studio/options'))

export const getStudioFeaturedCategories = studioId => apiHandler(httpShopAdm.get(`/api/studio/featured-categories/${studioId}`))

export const setStudioFeaturedCategories = (studioId, data) => apiHandler(httpShopAdm.post(`/api/studio/featured-categories/${studioId}`, data))

export const getStudioCategories = () => apiHandler(httpShopAdm.get('/api/studio/categories'))

export const uploadLogo = (id, data) => apiHandler(httpMovieLib.post(`/api/studio/${id}/logo`, data, multiPart))

export const removePicture = id => apiHandler(httpMovieLib.delete(`/api/studio/${id}/logo`))

export const geoRestrictions = studioid => apiHandler(httpShopAdm.get(`/api/studio/geo-restrictions?id=${studioid}`))

export const addStudioTrailer = (studioId, data) => apiHandler(httpMovieLib.post(`/api/studio/${studioId}/add-trailer`, data))

export const addAnotherStudioTrailerResolution = (trailerId, data) => apiHandler(httpMovieLib.post(`/api/studio-trailer/${trailerId}/add-resolution`, data))

export const removeStudioTrailer = trailerId => apiHandler(httpMovieLib.delete(`/api/studio-trailer/${trailerId}/remove`))

