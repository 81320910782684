<template>
  <v-sheet>
    <v-tabs
      v-model="selectedTab"
      color="grey lighten-2"
      grow
      slider-color="grey lighten-2"
      active-class="active-media-tab"
    >
      <v-tab
        v-for="(tab, index) in tabs"
        :key="tab + index"
      >
        {{ tab }}
      </v-tab>
      <v-tab-item
        lazy
      >
        <Covers
          :current-image-set="currentImageSet"
          :on-image-file-picked="onImageFilePicked"
          :clear-image="clearImage"
          @updateClicked="val => clicked = val"
        />
      </v-tab-item>
      <v-tab-item
        lazy
      >
        <v-alert
          :value="selectedBigshotImages.length === 0 && isUpdatingCensorship"
          type="info"
          color="teal"
        >
          Select images to update their Censorship status.
        </v-alert>
        <MultipleType
          id="bigshot"
          :is-updating-censorship="isUpdatingCensorship"
          :manage="manageMultImageType"
          :current-image-set="currentImageSet"
          :on-image-file-picked="onImageFilePicked"
          :clear-image="clearImage"
          :selected-bigshot-images="selectedBigshotImages"
          :type="type"
          :change-cover="changeCover"
          :update-selected-bighsot="updateSelectedBighsot"
          title="BigShot"
          @updateClicked="val => clicked = val"
        />
      </v-tab-item>
      <v-tab-item
        lazy
      >
        <MultipleType
          id="frame"
          :manage="manageMultImageType"
          :current-image-set="currentImageSet"
          :on-image-file-picked="onImageFilePicked"
          :clear-image="clearImage"
          title="Frame"
          @updateClicked="val => clicked = val"
        />
      </v-tab-item>
      <v-tab-item
        lazy
      >
        <Other
          :current-image-set="currentImageSet"
          :on-image-file-picked="onImageFilePicked"
          :clear-image="clearImage"
          @updateClicked="val => clicked = val"
        />
      </v-tab-item>
      <v-tab-item
        lazy
      >
        <Trailers
          v-if="type === 'edit'"
          @removeTrailer="id => removeTrailer(id)"
        />
      </v-tab-item>
    </v-tabs>
    <CoverSelectForm
      v-if="type === 'edit' || type === 'edit-scene'"
      :active="coverForm"
      :selected-cover-image="selectedCoverImage"
      :scene-number="selectedScene"
      :scene-images="sceneImages[selectedScene]"
      :movie-id="savedMovie && savedMovie.id || null"
      @updateActive="val => coverForm = val"
      @updatePictureList="refreshMediaData"
      @close="closeCoverForm"
    />
    <TrailerForm
      :active="trailerFormPopup"
      :selected-trailer-id="selectedTrailerId"
      :trailer-type-items="$staticData.trailerTypes"
      :mode="trailerFormMode"
      @add="(url, type) => addTrailer(url, type)"
      @remove="(id) => removeTrailerConfirm(id)"
      @close="closeTrailerForm"
    />
    <ActionButtons
      :save="() => save({ close: false, next: false })"
      :save-and-next="() => save({ close: false, next: true })"
      :save-and-close="() => save({ close: true, next: false })"
      :close="close"
      :type="type"
      :search-images="searchImages"
      :open-censorship="openCensorship"
      :update-censorship="updateCensorship"
      :cancel-censorship="cancelCensorship"
      :loading-save="loadingSave"
      :loading-censored="loadingCensored"
      :has-add-multi-type="selectedTab > 0 && selectedTab < 3"
      :has-update-censorship="selectedTab === 1 && type === 'edit'"
      :is-updating-censorship="isUpdatingCensorship"
      :find-covers-loading="findCoversLoading"
      :has-image-search="selectedTab === 0"
      :has-add-trailer="type === 'edit' && selectedTab === 4"
      has-next
      @addTrailer="trailerFormPopup = true; trailerFormMode = 'add'"
      @multiManage="() => manageMultImageType(null, 'add')"
    />
  </v-sheet>
</template>

<script>
import Covers from './Tabs/Covers'
import MultipleType from './Tabs/MultipleType'
import Trailers from './Tabs/Trailers'
import Other from './Tabs/Other'
import ActionButtons from '@/components/common/ActionButtons'
import CoverSelectForm from './Forms/CoverSelectForm'
import TrailerForm from '@/components/common/TrailerForm'
import { savePictures, removePicture, getMovieDetails, updateMultipleImages, addMovieTrailer, deleteMovieTrailer } from '@/services/movie.service'
import { errorHandler } from '@/utils/error'
import { clearData } from '@/utils/object'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    ActionButtons,
    Covers,
    CoverSelectForm,
    MultipleType,
    Other,
    Trailers,
    TrailerForm
  },
  props: {
    nextTab: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    },
    allTabs: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: Number,
      default: 0
    },
    overlay: {
      type: Boolean,
      default: false
    },
    checkImages: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      name: 'Media',
      loadingSave: false,
      loadingCensored: false,
      findCoversLoading: false,
      readyForUpload: [],
      coverForm: false,
      trailerFormPopup: false,
      trailerFormMode: 'add',
      selectedTrailerId: null,
      rules: {
        fieldRequierd: [
          v => !!v || 'This field is required'
        ]
      },
      selectedCoverImage: '',
      selectedTab: 0,
      selectedBigshotImages: [],
      isUpdatingCensorship: false,
      tabs: [
        'Covers',
        'Bigshots',
        'Frames',
        'Other',
        'Trailers'
      ],
      clicked: null,
      sceneImages: [],
      imageTypes: [
        'hero',
        'cover',
        'coverfront',
        'coverback',
        'dvdcover',
        'poster',
        'picture',
        'frame',
        'bigshot'
      ]
    }
  },
  computed: {
    ...mapGetters({
      savedMovie: 'movies/savedMovie',
      type: 'movies/crudType',
      selectedScene: 'movies/selectedScene'
    }),
    currentImageSet () { return this.sceneImages[this.selectedScene ? this.selectedScene : 0] },
    numberOfScenes () {
      if (this.savedMovie && this.savedMovie.numOfScenes) return this.savedMovie.numOfScenes
      else return 0
    }
  },
  watch: {
    activeTab (val) {
      if (this.allTabs[val] === this.name && (this.type === 'edit' || this.type === 'edit-scene')) {
        clearData.call(this)
        this.initialize()
        this.fillEditingData(this.savedMovie.pictures)
      } else if (this.allTabs[val] === this.name && this.type === 'add') {
        clearData.call(this)
        this.initialize()
      }
    },
    // this may have be buggy, test in detail
    selectedScene () {
      const tabCopy = this.selectedTab
      clearData.call(this)
      this.selectedTab = tabCopy
      this.initialize()
      this.fillEditingData(this.savedMovie.pictures)
    },
    overlay (val) {
      if (!val) this.selectedTab = 0
    }
  },
  created () {
    this.initialize()
    if (this.type === 'edit' || this.type === 'edit-scene') {
      this.fillEditingData(this.savedMovie.pictures)
    }
  },
  methods: {
    ...mapMutations({
      updateSavedMovie: 'movies/mutateMovieData',
      updateMoviePictures: 'movies/mutateMoviePictures'
    }),
    initialize () {
      for (let i = 0; i <= this.numberOfScenes; i++) {
        this.$set(this.sceneImages, i, {})
        this.imageTypes.forEach(type => {
          if (type === 'bigshot' || type === 'frame') {
            this.$set(this.sceneImages[i], type, [])
            this.$set(this.sceneImages[i], `${type}Censored`, [])
          } else {
            this.$set(this.sceneImages[i], type, {})
            this.$set(this.sceneImages[i], `${type}Censored`, {})

            this.$set(this.sceneImages[i][type], 'mediaUrl', '')
            this.$set(this.sceneImages[i][type], 'id', null)
            this.$set(this.sceneImages[i][type], 'type', type)
            this.$set(this.sceneImages[i][type], 'deleteLoading', false)
            this.$set(this.sceneImages[i][type], 'censored', false)

            this.$set(this.sceneImages[i][`${type}Censored`], 'mediaUrl', '')
            this.$set(this.sceneImages[i][`${type}Censored`], 'id', null)
            this.$set(this.sceneImages[i][`${type}Censored`], 'type', type)
            this.$set(this.sceneImages[i][`${type}Censored`], 'deleteLoading', false)
            this.$set(this.sceneImages[i][`${type}Censored`], 'censored', true)
          }
        })
      }
    },
    clearImage (id, index) {
      let selectedImage
      if (index) {
        selectedImage = this.currentImageSet[id][index]
      } else {
        selectedImage = this.currentImageSet[id]
      }
      selectedImage.deleteLoading = true
      if (selectedImage.id) {
        removePicture(selectedImage.id)
          .then(res => {
            selectedImage.deleteLoading = false
            this.$notify({
              type: 'success',
              title: selectedImage.id,
              text: res.message
            })
            const index = this.readyForUpload.findIndex(el => el.type === id)
            if (index) this.readyForUpload.splice(index, 1)
            selectedImage.mediaUrl = ''
            selectedImage.id = null
          })
          .catch(errorHandler.bind(this))
      } else {
        const index = this.readyForUpload.findIndex(el => el.type === id)
        if (index) this.readyForUpload.splice(index, 1)
        selectedImage.mediaUrl = ''
        selectedImage.deleteLoading = false
      }
    },
    onImageFilePicked (e) {
      const files = e.target.files || e.dataTransfer.files
      const selectedInput = this.clicked
      this.clicked = null
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          selectedInput.mediaUrl = fr.result
          selectedInput.mediaFile = files[0]
          this.readyForUpload.push(selectedInput)
        })
      } else {
        selectedInput.mediaFile = ''
        selectedInput.mediaUrl = ''
      }
    },
    fillEditingData (pictures) {
      Object.keys(pictures).forEach(set => {
        switch (set) {
          case 'bigshot':
          case 'frame':
            this.sceneImages[0][set] = []
            pictures[set].forEach(picture => {
              this.sceneImages[0][set].push({
                mediaUrl: picture.url,
                id: picture.id,
                offset: picture.offset ? picture.offset : null,
                part: picture.part ? picture.part : null,
                type: set,
                deleteLoading: false,
                width: picture.width,
                height: picture.height,
                censored: !!picture.censored
              })
            })
            break
          case 'scenes':
            Object.keys(pictures[set]).forEach(function (index) {
              Object.keys(pictures[set][index]).forEach(function (setName) {
                const setData = pictures[set][index][setName]
                switch (setName) {
                  case 'bigshot':
                  case 'frame':
                    this.sceneImages[index][setName] = []
                    setData.forEach(function (el) {
                      this.sceneImages[index][setName].push({
                        mediaUrl: el.url,
                        id: el.id,
                        offset: el.offset ? el.offset : null,
                        part: el.part ? el.part : null,
                        type: set,
                        width: el.width,
                        height: el.height,
                        deleteLoading: false,
                        censored: !!el.censored
                      })
                    }.bind(this))
                    break
                  default:
                    setData.forEach(function (el) {
                      const sceneSetName = el.censored ? `${setName}Censored` : setName
                      this.sceneImages[index][sceneSetName].mediaUrl = el.url
                      this.sceneImages[index][sceneSetName].id = el.id
                      this.sceneImages[index][sceneSetName].deleteLoading = false
                      this.sceneImages[index][sceneSetName].part = el.part ? el.part : null
                      this.sceneImages[index][sceneSetName].width = el.width
                      this.sceneImages[index][sceneSetName].height = el.height
                      this.sceneImages[index][sceneSetName].type = setName
                    }.bind(this))
                    break
                }
              }.bind(this))
            }.bind(this))
            break
          default:
            pictures[set].forEach(picture => {
              const scene = picture.scene ? picture.scene : 0
              const setName = picture.censored ? `${set}Censored` : set
              this.sceneImages[scene][setName].mediaUrl = picture.url
              this.sceneImages[scene][setName].id = picture.id
              this.sceneImages[scene][setName].width = picture.width
              this.sceneImages[scene][setName].height = picture.height
            })
            break
        }
      })
    },
    save (options) {
      if (this.readyForUpload.length) {
        this.loadingSave = true
        const promises = []
        this.readyForUpload.forEach(el => {
          const formData = new FormData()
          formData.append('file', el.mediaFile, el.mediaFile.name)
          formData.append('type', el.type)
          formData.append('censored', el.censored ? 1 : 0)
          formData.append('scene', this.selectedScene)
          formData.append('part', -1)
          formData.append('offset', -1)
          promises.push({
            id: el.id ? el.id : this.savedMovie.id,
            update: !!el.id,
            data: formData
          })
        })
        savePictures(promises)
          .then(() => {
            this.refreshMediaData()
            this.loadingSave = false
            this.readyForUpload.length = 0
            if (options.next) this.nextTab()
            if (options.close) this.close()
            this.$notify({
              type: 'success',
              title: 'Media',
              text: 'Record Media Updated'
            })
          })
          .catch(errorHandler.bind(this))
      } else {
        this.$notify({
          type: 'success',
          title: 'Media',
          text: 'There is nothing to update.'
        })
      }
    },
    saveAndNext (event) {
      this.save(event, true)
    },
    searchImages (includeAll) {
      this.findCoversLoading = true
      this.$notify({
        type: 'success',
        title: `Covers search on: ${window.BaseCoverUrl}`
      })
      getMovieDetails(this.savedMovie.id)
        .then(response => {
          const aux = [ ...response.result.auxEANs ]
          if (includeAll) aux.unshift(response.result.ean)
          this.checkImages(aux, this.savedMovie.id, this.sceneImages[this.selectedScene ? this.selectedScene : 0])
            .then(() => {
              this.findCoversLoading = false
              clearData.call(this)
              this.initialize()
              this.fillEditingData(this.savedMovie.pictures)
              this.$emit('refreshTable')
            })
            .catch(errorHandler.bind(this))
        })
    },
    changeCover (img) {
      this.coverForm = true
      this.selectedCoverImage = img
    },
    closeCoverForm () {
      this.coverForm = false
      this.selectedCoverImage = ''
    },
    refreshMediaData () {
      getMovieDetails(this.savedMovie.id)
        .then(response => {
          this.updateMoviePictures(response.result.pictures)
          clearData.call(this)
          this.initialize()
          this.fillEditingData(this.savedMovie.pictures)
        })
        .catch(errorHandler.bind(this))
    },
    manageMultImageType (type, mode, index) {
      if (mode === 'add') {
        type = this.selectedTab === 1 ? 'bigshot' : 'frame'
        this.sceneImages[this.selectedScene][type].push({
          mediaUrl: '',
          id: null,
          type,
          deleteLoading: false,
          censored: null
        })
      } else {
        if (index > -1) {
          this.sceneImages[this.selectedScene][type].splice(index, 1)
        }
      }
    },
    updateSelectedBighsot (id) {
      const index = this.selectedBigshotImages.indexOf(id)
      if (index !== -1) {
        this.selectedBigshotImages.splice(index, 1)
      } else {
        this.selectedBigshotImages.push(id)
      }
    },
    updateCensorship (selected, unselected) {
      this.loadingCensored = true
      if (selected !== null) {
        updateMultipleImages({
          ids: this.selectedBigshotImages,
          censored: selected
        }).then(() => {
          this.loadingCensored = false
          this.cancelCensorship()
          this.$notify({
            type: 'success',
            title: 'Selected Images',
            text: `Censored value: ${selected}`
          })
        })
          .catch(errorHandler.bind(this))
      }
      if (unselected !== null) {
        const unselectedIds = this.currentImageSet['bigshot'].filter(x => !this.selectedBigshotImages.includes(x.id))
        updateMultipleImages({
          ids: unselectedIds,
          censored: unselected
        }).then(() => {
          this.loadingCensored = false
          this.cancelCensorship()
          this.$notify({
            type: 'success',
            title: 'Selected Images',
            text: `Censored value: ${unselected}`
          })
        })
          .catch(errorHandler.bind(this))
      }
    },
    openCensorship () {
      this.isUpdatingCensorship = true
    },
    cancelCensorship () {
      this.isUpdatingCensorship = false
      this.selectedBigshotImages.splice(0, this.selectedBigshotImages.length)
    },
    closeTrailerForm () {
      this.trailerFormPopup = false
      this.selectedTrailerId = null
      this.trailerFormMode = 'add'
    },
    addTrailer (url, type) {
      addMovieTrailer({
        type,
        scene: this.selectedScene,
        url
      }, this.savedMovie.id)
        .then(response => {
          this.$emit('close', true)
          this.$notify({
            type: 'success',
            title: `Trailer Video`,
            text: response.result.message
          })
        })
        .catch(errorHandler.bind(this))
    },
    removeTrailer (id) {
      this.selectedTrailerId = id
      this.trailerFormMode = 'remove'
      this.trailerFormPopup = true
    },
    removeTrailerConfirm (id) {
      if (id) {
        this.loading = true
        deleteMovieTrailer(id)
          .then(response => {
            this.loading = false
            this.trailerFormPopup = false
            this.$notify({
              type: 'success',
              title: `Trailer Video ${id}`,
              text: response.result.message
            })
            getMovieDetails(this.savedMovie.id)
              .then(response => {
                const tabCopy = this.selectedTab
                this.updateSavedMovie(response.result)
                clearData.call(this)
                this.selectedTab = tabCopy
                this.initialize()
                this.fillEditingData(this.savedMovie.pictures)
              })
              .catch(errorHandler.bind(this))
          })
          .catch(errorHandler.bind(this))
      }
    }
  }
}
</script>

