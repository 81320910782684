<template>
  <v-sheet class="elevation-1 overflow-hidden">
    <div
      :style="!currentItems || currentItems.length === 0 ? 'height: 100px;' : ''"
      class="table-container"
    >
      <table
        :class="tableStyle === 'images' || tableStyle === 'performer' ?'media-table' : ''"
        class="v-datatable v-table theme--light"
      >
        <thead>
          <tr class="text-left grey lighten-2 pa-2 table-heading">
            <th v-if="hasSelectAll">
              <Checkbox @change="selectAll" />
            </th>
            <th
              v-for="header in headers"
              :key="`${header.text}-header`"
              :class="`${pagination.orderBy === header.value ? 'font-weight-regular grey lighten-1' : ''} ${header.sortable ? 'is-sortable' : ''}`"
              :style="header.width ? `width: ${header.width}` : '' + header.minWidth ? ` min-width: ${header.minWidth}` : ''"
              class="text-xs-left"
              @click="header.sortable ? sort(header.value) : () => {}"
            >
              {{ header.text }}
              <v-icon
                v-if="pagination.orderBy === header.value"
                small
              >
                {{ pagination.order === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </v-icon>
            </th>
          </tr>
        </thead>
        <v-progress-linear
          v-if="loading"
          :height="2"
          color="teal"
          class="table-loading"
          indeterminate
        />
        <tbody v-if="currentItems && currentItems.length > 0 && tableStyle === 'table'">
          <tr
            v-for="(item, index) in currentItems"
            :key="`${item.id}-${index}-row`"
          >
            <slot
              :item="item"
              :select-item="selectItem"
              :unselect-item="selectItem"
              :is-checked="selectedIds.indexOf(item.id) !== -1"
              name="rows"
            />
          </tr>
        </tbody>
        <tbody v-else-if="currentItems && currentItems.length > 0 && (tableStyle === 'images' || tableStyle === 'performer')">
          <div
            v-for="(item, index) in currentItems"
            :key="`${item.id}-${index}-row`"
            :class="tableStyle"
          >
            <slot
              :item="item"
              name="rows"
            />
          </div>
        </tbody>
        <div
          v-else
          class="no-data"
        >
          {{ loading ? 'Loading data...' : 'No data found' }}
        </div>
      </table>
    </div>
    <div class="table-footer">
      <div>
        <span>Items per page: <strong>{{ pagination.rowsPerPage }}</strong></span>
        <span>Page: <strong>{{ pagination.page }}</strong> of <strong v-if="totalPages !== Infinity">{{ totalPages }}</strong></span>
      </div>
      <div>
        <v-btn
          class="table-option-buttons"
          :loading="loadingPrevious"
          :disabled="loadingPrevious || pagination.page <= 1"
          icon
          round
          small
          flat
          @click.prevent="prevPage"
        >
          <v-icon small>
            chevron_left
          </v-icon>
        </v-btn>
        <v-btn
          class="table-option-buttons"
          :loading="loadingNext"
          :disabled="loadingNext || pagination.page >= totalPages || currentItems.length < pagination.rowsPerPage"
          icon
          round
          small
          flat
          @click.prevent="nextPage"
        >
          <v-icon small>
            chevron_right
          </v-icon>
        </v-btn>
      </div>
      <div>
        <span v-if="pagination.totalItems !== null">Total: <strong>{{ pagination.totalItems }}</strong></span>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import Checkbox from '../common/Checkbox'

export default {
  components: { Checkbox },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    tableStyle: {
      type: String,
      default: 'table'
    },
    pagination: {
      type: Object,
      default: () => {}
    },
    headers: {
      type: Array,
      default: () => []
    },
    editItem: {
      type: Function,
      default: () => {}
    },
    loadData: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasSelectAll: {
      type: Boolean,
      default: false
    }
  },
  /**
   * @selected - array of selected items from table
   * @loadingNext - state indicator for next page loading
   * @loadingPrevious - state indicator for previous page loading
   */
  data () {
    return {
      selected: [],
      loadingNext: false,
      loadingPrevious: false
    }
  },
  computed: {
    currentItems () {
      return this.items[this.pagination.page]
    },
    totalPages () {
      return this.pagination.totalPages !== null ? this.pagination.totalPages : Infinity
    },
    selectedIds () {
      return this.selected.map(el => el.id)
    }
  },
  watch: {
    selected (val) {
      this.$emit('updateSelected', val)
    }
  },
  methods: {
    /**
     * sortBy method
     * @param {string} s 
     */
    sort (s) {
      if (s === this.pagination.orderBy) {
        this.$emit('updatePagination', { ...this.pagination, order: this.pagination.order === 'asc' ? 'desc' : 'asc' })
      }
      this.$emit('updatePagination', { ...this.pagination, orderBy: s })
    },
    /**
     * method for handeling next page
     */
    nextPage () {
      // total pages issue for images, they have infinity pages
      if (this.loadingNext) return
      if (this.pagination.page < this.totalPages && !this.loading) {
        this.loadingNext = true
        if (!this.pagination.pagesLoaded.has(this.pagination.page + 1)) {
          this.loadData({ ...this.pagination, page: this.pagination.page + 1 })
            .then(() => {
              this.loadingNext = false
              this.$emit('updatePagination', { ...this.pagination, page: this.pagination.page + 1 })
            })
            .catch(() => this.loadingNext = false)
        } else {
          this.loadingNext = false
          this.$emit('updatePagination', { ...this.pagination, page: this.pagination.page + 1 })
        }
      }
    },
    /**
     * methond for handeling previous page
     */
    prevPage () {
      if (this.loadingPrevious) return
      if (this.pagination.page > 1 && !this.loading) {
        this.loadingPrevious = true
        if (!this.pagination.pagesLoaded.has(this.pagination.page - 1)) {
          this.loadData({ ...this.pagination, page: this.pagination.page - 1 })
            .then(() => {
              this.$emit('updatePagination', { ...this.pagination, page: this.pagination.page - 1 })
              this.loadingPrevious = false
            })
            .catch(() => this.loadingPrevious = false)
        } else {
          this.loadingPrevious = false
          this.$emit('updatePagination', { ...this.pagination, page: this.pagination.page - 1 })
        }
      }
    },
    /**
     * Methond for seleting an item form current page
     * @param {object} item 
     */
    selectItem (item) {
      const index = this.selected.findIndex(el => el.id === item.id)
      if (index !== -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(item)
      }
    },
    /**
     * method that selects all items from current page
     */
    selectAll () {
      if (this.selected.length === this.currentItems.length) {
        this.selected.splice(0, this.selected.length)
        return
      }
      if (this.selected.length && this.selected.length < this.currentItems.length) {
        this.selected.splice(0, this.selected.length)
      }
      this.currentItems.forEach(item => {
        this.selectItem(item)
      })
    }
  }
}
</script>