/**
 * 
 * @param {AxiosError} e
 * this function handles all errors in the app so that app can continue working. Error is sent in console log and displayed as notification for user 
 */
export function errorHandler (e) {
  // eslint-disable-next-line no-console
  console.log(e)
  // here we turn off all loading states if error occured durign loading.
  if (this.loadingSave) this.loadingSave = false
  if (this.isLoadingPornstars) this.isLoadingPornstars = false
  if (this.loadingAltNames) this.loadingAltNames = false
  if (this.checkPornstarsLoading) this.checkPornstarsLoading = false
  if (this.seriesLoding) this.seriesLoding = false
  if (this.findCoversLoading) this.findCoversLoading = false
  if (this.loadingCensored) this.loadingCensored = false
  if (this.isLoadingTags) this.isLoadingTags = false
  let errors
  if (Array.isArray(e.info) && e.info.length) {
    errors = Object.keys(e.info)
    if (errors.length) {
      errors.forEach(key => {
        this.$notify({
          type: 'error',
          title: `Error ${e.code}: ${e.message}`,
          text: `${key}: ${e.info[key][0]}`
        })
      })
    }
  }
  if (e.message) {
    this.$notify({
      type: 'error',
      title: `Error ${e.code}`,
      text: e.message
    })
  }
}
