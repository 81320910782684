<template>
  <div class="text-xs-center">
    <v-dialog
      :value="confirmationPrompt.active"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ confirmationPrompt.title }}
        </v-card-title>
        <v-card-text>
          {{ confirmationPrompt.message }}
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            block
            @click="confirm"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            block
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters(['confirmationPrompt'])
  },
  methods: {
    ...mapMutations([
      'setPrompt',
      'promptUnset'
    ]),
    /**
     * executing configrm method passed to vuex
     */
    confirm () {
      if (this.confirmationPrompt.confirmMethod) this.confirmationPrompt.confirmMethod()
      this.promptUnset()
    },
    /**
     * executing cancel method passed to vuex
     */
    cancel () {
      if (this.confirmationPrompt.cancelMethod) this.confirmationPrompt.cancelMethod()
      this.promptUnset()
    }
  }
}
</script>
