<template>
  <div :use-focals="useFocals">
    <v-layout
      row
      wrap
      px-2
    >
      <v-dialog
        v-model="showFocalDialog"
        :max-width="dialogWidth"
        v-if="useFocals"
      >
        <v-card>
          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >
            Focal Point
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  right
                >
                  help
                </v-icon>
              </template>
              <span>Set the focal point of the image by clicking on the image.</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />

          <fieldset>
            <div class="form-group focalpoint-wrapper">
              <div
                class="focalpoint"
                :style="{ left: focalPointX + '%', top: focalPointY + '%' }"
              >
                <img
                  :src="mediaSet[id].mediaUrl"
                  style="width: 100%; height: auto;"
                  @click="updateFocalPoint"
                  ref="image"
                  @load="onImageLoad"
                >
              </div>
            </div>

            <div class="form-group field-banner-image_url_en">
              <div class="form-group field-banner-focalpoint_x_en">
                <input
                  type="hidden"
                  id="banner-focalpoint_x_en"
                  class="form-control"
                  name="Banner[focalpoint_x_en]"
                  v-model="focalPointX"
                >
              </div>
              <div class="form-group field-banner-focalpoint_y_en">
                <input
                  type="hidden"
                  id="banner-focalpoint_y_en"
                  class="form-control"
                  name="Banner[focalpoint_y_en]"
                  v-model="focalPointY"
                >
              </div>
            </div>
          </fieldset>
        </v-card>
      </v-dialog>
      <v-flex
        :class="isSelected ? 'grey lighten-2 selected-image' : ''"
        class="image-container"
        xs12
      >
        <p
          class="mb-2 mt-3 subheading text-xs-left"
        >
          {{ title }}
        </p>
        <v-icon
          v-if="isSelected"
          right
          medium
          class="check-icon"
          color="success"
        >
          check
        </v-icon>
        <div
          v-if="!mediaSet[id].mediaUrl && !isUpdatingCensorship"
          :data-id="id"
          class="image-placeholder"
          @drop.prevent="drop"
          @dragover.prevent="() => false"
          @dragenter.prevent="dragenter"
          @dragleave.prevent="dragleave"
          @click="pickFile"
        >
          Drop File Here!
          <v-icon
            color="#b9b9b9"
            x-large
          >
            photo_size_select_actual
          </v-icon>
        </div>
        <v-tooltip
          v-if="mediaSet[id].mediaUrl && !isUpdatingCensorship"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              :loading="mediaSet[id].deleteLoading"
              :disabled="mediaSet[id].deleteLoading"
              small
              round
              fab
              top
              right
              depressed
              absolute
              class="remove-media"
              color="red"
              v-on="on"
              @click="remove"
            >
              <v-icon dark>
                close
              </v-icon>
            </v-btn>
          </template>
          <span>Remove image</span>
        </v-tooltip>
        <v-tooltip
          v-if="hasSetCoverImage && !isUpdatingCensorship && mediaSet[id].mediaUrl"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              small
              round
              fab
              top
              right
              depressed
              absolute
              class="edit-media"
              color="blue"
              v-on="on"
              @click="changeCover(mediaSet[id].mediaUrl)"
            >
              <v-icon dark>
                image
              </v-icon>
            </v-btn>
          </template>
          <span>Set as scene Cover image</span>
        </v-tooltip>
        <v-btn
          v-if="!mediaSet[id].mediaUrl && !isUpdatingCensorship"
          :id="id"
          small
          round
          fab
          top
          right
          depressed
          absolute
          class="remove-media"
          color="info"
          @click="pickFile"
        >
          <v-icon dark>
            attach_file
          </v-icon>
        </v-btn>
        <v-btn
          v-if="!mediaSet[id].mediaUrl && hasRemovePlaceholder && !isUpdatingCensorship"
          :id="id"
          small
          round
          fab
          bottom
          right
          depressed
          absolute
          class="remove-placeholder"
          color="error"
          @click="manage(mediaSet[id].type, 'remove', placeholderIndex)"
        >
          <v-icon dark>
            close
          </v-icon>
        </v-btn>
        <v-img
          v-if="mediaSet[id].mediaUrl"
          :src="mediaSet[id].id ? imageSource : mediaSet[id].mediaUrl"
          :lazy-src="mediaSet[id].id ? imageSource : mediaSet[id].mediaUrl"
          height="450"
          aspect-ratio="1"
          contain
          class="image-hover-cursor"
          @error="onImgError"
          @click="isUpdatingCensorship ? updateSelectedBighsot(mediaSet[id].id) : previewImage(mediaSet[id])"
        >
          <template #placeholder>
            <v-layout
              fill-height
              align-center
              justify-center
              ma-0
            >
              <v-progress-circular
                indeterminate
                color="teal"
              />
            </v-layout>
          </template>
        </v-img>
      </v-flex>
    </v-layout>
    <input
      :ref="`image${id}`"
      accept="image/*"
      type="file"
      style="display: none"
      @change="onFilePicked"
    >
  </div>
</template>

<style lang="scss" >
  .focalpoint-wrapper {
    position: relative;
    overflow: hidden;
    cursor: crosshair;
    font-size: 0;
  }

  .focalpoint {
    top: -1px;
    left: -1px;
  }

  .focalpoint::before,
  .focalpoint::after {
    content: '';
    position: absolute;
    z-index: 1;
    background-color: #000;
    filter: drop-shadow(0 0 2px #fff);
    transition-property: top, left;
    transition-duration: 100ms;
  }

  .focalpoint::before {
    top: inherit;
    left: 0;
    width: 100%;
    height: 1px;
  }

  .focalpoint::after {
    top: 0;
    left: inherit;
    width: 1px;
    height: 100%;
  }

  .focalpoint > img {
    margin-bottom: -1px;
  }

</style>
<script>
export default {
  props: {
    resolution: {
      type: String,
      default: '0x236'
    },
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    mediaSet: {
      type: [Object, Array],
      default: () => {}
    },
    deleteLoading: {
      type: Boolean,
      default: false
    },
    hasSetCoverImage: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    placeholderIndex: {
      type: Number,
      default: 0
    },
    isUpdatingCensorship: {
      type: Boolean,
      default: false
    },
    hasRemovePlaceholder: {
      type: Boolean,
      default: false
    },
    manage: {
      type: Function,
      default: () => {}
    },
    updateSelectedBighsot: {
      type: Function,
      default: () => {}
    },
    changeCover: {
      type: Function,
      default: () => {}
    },
    useFocals: {
      type: Boolean,
      default: false
    },
    focalPoints: {
      type: Object,
       default: () => ({x: null, y: null})
    },
    onFilePicked: {
      type: Function,
      default: () => {}
    }
  },
  /**
   * @censored - image censored state
   * @imageFailed - failed state that triggers another attempt for image to be loaded from CDN source
   */
  data () {
    return {
      censored: false,
      imageFailed: Date.now(),
      showFocalDialog: false,
      dialogWidth: 1000,
      focalPointX: 0,
      focalPointY: 0,
    }
  },
  computed: {
    imageSource () {
      return this.mediaSet[this.id].mediaUrl.includes('http') ? `${this.generateUrl(this.mediaSet[this.id].mediaUrl)}?${this.imageFailed}` : this.mediaSet[this.id].mediaUrl
    }
  },
  methods: {
    /**
     * image container upload file methods
     */
    pickFile () {
      this.$emit('updateClicked', this.mediaSet[this.id])
      if(this.useFocals) this.$emit('update-focal-point', this.focalPointX, this.focalPointY, this.id)
      this.$refs[`image${this.id}`].click()
    },
    remove () {
      this.$emit('removeImage', this.hasRemovePlaceholder ? this.mediaSet[this.id].type : this.id, this.hasRemovePlaceholder ? this.id : null)
    },
    drop (e) {
      this.$emit('updateClicked', this.mediaSet[e.target.dataset.id])
      if(this.useFocals) this.$emit('update-focal-point', this.focalPointX, this.focalPointY, this.id)
      this.onFilePicked({ target: { files: e.dataTransfer.files } })
    },
    dragenter (e) {
      e.target.classList.toggle('drag-over')
    },
    dragleave (e) {
      e.target.classList.toggle('drag-over')
    },
    /**
     * error handling method
     */
    onImgError () {
      setTimeout(() => {
        this.imageFailed = Date.now()
      }, 2000)
    },
    /**
     * 
     * @param {string} url - string to be decoded and converted to default resolutions
     */
    generateUrl (url) {
      return `${url.substr(0, url.lastIndexOf('/') + 1)}${this.resolution}${url.substr(url.lastIndexOf('/'))}`.replace('.net/', '.net/rsz/')
    },
    /**
     * method for generating preview link of image
     */
    previewImage (media) {
      const url = media.mediaUrl

      // load focal point if it was edited
      if(this.focalPoints.x){
        this.showFocalDialog = true;
        this.focalPointX = this.focalPoints.x;
        this.focalPointY = this.focalPoints.y;
        return
      }
      // load focal point if it exists in media object (on iniital load)
      if (this.useFocals) {
        this.showFocalDialog = true;
        this.focalPointX = media.focalpoint_x;
        this.focalPointY = media.focalpoint_y;
        return
      } 
      window.open(`${url.substr(0, url.lastIndexOf('/') + 1)}${this.mediaSet[this.id].width}x${this.mediaSet[this.id].height}${url.substr(url.lastIndexOf('/'))}`.replace('.net/', '.net/rsz/'), '_blank')
    },
    updateFocalPoint (event) {
      /* skip if modal is not shown */
      if (!this.showFocalDialog) return;
      const { left, top, width, height } = this.$refs.image.getBoundingClientRect();
      const x = event.clientX - left;
      const y = event.clientY - top;
      const xpercent = Math.round((x / width) * 10000) / 100;
      const ypercent = Math.round((y / height) * 10000) / 100;

      // converted to int because api only accepts int
      this.focalPointX = Math.round(xpercent).toString();
      this.focalPointY = Math.round(ypercent).toString();
      this.$emit('update-focal-point', this.focalPointX, this.focalPointY, this.id)

      this.$set(this.mediaSet, this.id, {
        ...this.mediaSet[this.id],
        focalpoint_x: this.focalPointX,
        focalpoint_y: this.focalPointY
      });
    },
    onImageLoad(event) {
      const img = event.target;
      const width = img.width;
      this.dialogWidth = width > 1200 ? 1200 : width;
    }
  },
}
</script>
