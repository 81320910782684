import { jwtTokenMovieLib, jwtTokenShopAdm, jwtTokenFlexFiles, jwtTokenExp, jwtTokenIat } from '@/config'
const set = property => (state, payload) => (state[property] = payload)
const toggle = property => state => (state[property] = !state[property])

export const mutations = {
  loginSuccess (state, { username, roles }) {
    state.loggedIn = true
    state.username = username
    state.roles = roles
  },
  logout (state) {
    state.loggedIn = false
    state.username = ''
    state.roles = null
    localStorage.removeItem(jwtTokenShopAdm)
    localStorage.removeItem(jwtTokenMovieLib)
    localStorage.removeItem(jwtTokenFlexFiles)
    localStorage.removeItem(jwtTokenExp)
    localStorage.removeItem(jwtTokenIat)
    localStorage.removeItem('baseURLMovieLib')
    localStorage.removeItem('baseURLShopAdm')
    localStorage.removeItem('baseURLFlexFiles')
  },
  setNotification (state, { message, type }) {
    state.notification.message = message
    state.notification.type = type
  },
  cacheDirectors (state, data) {
    state.cachedOptions.directors.splice(0, state.cachedOptions.directors.length)
    data.forEach(el => state.cachedOptions.directors.push(el))
  },
  setFastUrl (state, data) {
    state.fastUrl = data
  },
  setPrompt (state, data) {
    state.confirmationPrompt.active = true
    state.confirmationPrompt.title = data.title
    state.confirmationPrompt.message = data.message
    state.confirmationPrompt.confirmMethod = data.confirmMethod
    state.confirmationPrompt.cancelMethod = data.cancelMethod
  },
  promptUnset (state) {
    state.confirmationPrompt.active = false
    state.confirmationPrompt.title = ''
    state.confirmationPrompt.message = ''
    state.confirmationPrompt.confirmMethod = null
    state.confirmationPrompt.cancelMethod = null
  },
  cacheStudios (state, data) {
    state.cachedOptions.studios.splice(0, state.cachedOptions.studios.length)
    data.forEach(el => state.cachedOptions.studios.push(el))
  },
  cacheCategories (state, data) {
    state.cachedOptions.categories.splice(0, state.cachedOptions.categories.length)
    data.forEach(el => state.cachedOptions.categories.push(el))
  },
  cacheStudioCategories (state, data) {
    state.cachedOptions.studioCategories.splice(0, state.cachedOptions.studioCategories.length)
    data.forEach(el => state.cachedOptions.studioCategories.push(el))
  },
  cacheCategoriesGroup (state, data) {
    state.cachedOptions.categoriesGroup.splice(0, state.cachedOptions.categoriesGroup.length)
    data.forEach(el => state.cachedOptions.categoriesGroup.push(el))
  },
  cacheSuppliers (state, data) {
    state.cachedOptions.suppliers.splice(0, state.cachedOptions.suppliers.length)
    data.forEach(el => state.cachedOptions.suppliers.push({
      text: el.name,
      value: el.id
    }))
  },
  setDrawer: set('drawer'),
  setDrawerMini: set('drawerMini'),
  setToolbar: set('toolbar'),
  setImage: set('image'),
  toggleDrawer: toggle('drawer')
}
