export const state = {
  loggedIn: false,
  roles: null,
  username: '',
  exp: '',
  iat: '',
  fastUrl: null,
  drawer: false,
  confirmationPrompt: {
    active: false,
    title: '',
    message: '',
    confirmMethod: null,
    cancelMethod: null
  },
  drawerMini: true,
  toolbar: null,
  cachedOptions: {
    directors: [],
    studios: [],
    categories: [],
    studioCategories: [],
    categoriesGroup: [],
    suppliers: []
  }
}
