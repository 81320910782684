<template>
  <v-container
    fluid
    grid-list-md
    text-xs-center
  >
    <v-tabs
      v-model="active"
      color="grey lighten-2"
      light
      slider-color="teal"
    >
      <v-tab
        v-for="(item, index) in filterTabs"
        :key="index + item.title"
        :to="item.route"
        exact
        ripple
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-slide-x-transition mode="out-in">
      <router-view
        :edit-item="editItem"
        :edit-items="editItems"
        :add-item="addItem"
        :selected="selected"
        :edit-loading="editLoading"
        :refresh-table="refreshTable"
        @isRefreshed="refreshTable = false"
        @updateSelected="val => selected = val"
      />
    </v-slide-x-transition>
    <v-dialog
      :value="overlay"
      lazy
      content-class="overlay"
      persistent
    >
      <MovieCrud
        :value="activeOverlayTab"
        :go-to-next-tab="goToNextTab"
        :close="toggleOverlay"
        :crud-tabs="crudTabs"
        :all-tabs="allTabs"
        :overlay="overlay"
        :selected="selected"
        @refreshTable="refreshTable = true"
        @hasUnsaved="val => crudTabs[val].unsaved = true"
        @hasSaved="val => crudTabs[val].unsaved = false"
        @change="value => activeOverlayTab = value"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import CRUD from './CRUD/MoviesCRUD'
import { getMovieDetails } from '@/services/movie.service'
import { errorHandler } from '@/utils/error'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    MovieCrud: CRUD
  },
  data () {
    return {
      overlay: false,
      active: this.$route.path,
      activeOverlayTab: 0,
      selected: [],
      refreshTable: false,
      filterTabs: [
        { title: 'General', route: '/video-manager' },
        { title: 'DVD', route: '/video-manager/dvd' },
        { title: 'VOD', route: '/video-manager/vod' }
      ],
      crudTabs: {
        Metadata: { disabled: true, unsaved: false },
        Media: { disabled: true, missingCovers: false, unsaved: false },
        Scenes: { disabled: true, unsaved: false },
        VOD: { disabled: true, unsaved: false },
        Translations: { disabled: true, unsaved: false }
      },
      editLoading: false
    }
  },
  computed: {
    ...mapGetters({
      savedMovie: 'movies/savedMovie',
      selectedScene: 'movies/selectedScene',
      crudType: 'movies/crudType'
    }),
    allTabs () {
      return Object.keys(this.crudTabs)
    }
  },
  watch: {
    overlay (val) {
      if (!val && this.crudType !== 'edit-multiple') {
        const query = { ...this.$route.query }
        delete query.mode
        delete query.id
        delete query.tab
        delete query.scene
        this.$router.replace({ query }).catch(() => {})
      }
      if (!val) {
        this.activeOverlayTab = 0
        this.resetCrudTabs()
      }
    },
    activeOverlayTab (val) {
      if (this.overlay) {
        this.$router.replace({ query: { ...this.$route.query, tab: val } }).catch(() => {})
      }
    },
    selectedScene (val) {
      if (this.overlay) {
        this.$router.replace({ query: { ...this.$route.query, scene: val } }).catch(() => {})
      }
    },
    // this fixes issue when you are on crudType 'add' after new movie is saved, should enable tabs in crudType 'edit'
    crudType (val, oldVal) {
      if (val === 'edit' && oldVal === 'add') {
        this.enableTabs()
      }
    },
    'savedMovie.numOfScenes' (val) {
      if (val) {
        this.crudTabs.Scenes.disabled = false
        this.crudTabs.Scenes.unsaved = false
      } else {
        this.crudTabs.Scenes.disabled = true
        this.crudTabs.Scenes.unsaved = true
      }
    }
  },
  created () {
    const { mode, id, tab, scene } = this.$route.query
    if (mode) {
      switch (mode) {
        case 'add': {
          this.addItem()
          break
        }
        case 'edit': {
          this.editItem({ id, editLoading: false, deleteLoading: false }, true, null, tab, scene)
          break
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      updateCrudType: 'movies/mutateCrudType',
      updateSavedMovie: 'movies/mutateMovieData',
      updateSelectedScene: 'movies/mutateSelectedScene'
    }),
    toggleOverlay () {
      this.overlay = !this.overlay
    },
    resetCrudTabs () {
      Object.keys(this.crudTabs).forEach(tab => {
        this.crudTabs[tab].disabled = true
        this.crudTabs[tab].unsaved = false
      })
    },
    editItems () {
      if (this.selected.length === 1) {
        this.editItem(this.selected[0])
      } else {
        this.activeOverlayTab = 0
        this.updateCrudType('edit-multiple')
        this.resetCrudTabs()
        this.updateSavedMovie(null)
        this.toggleOverlay()
      }
    },
    editItem (item, isFromUrl, missingCover, tab = 0, scene = 0) {
      if (this.editLoading) return
      this.activeOverlayTab = parseInt(tab)
      this.updateSelectedScene(0)
      this.updateCrudType('edit')
      this.updateSavedMovie(null)
      this.resetCrudTabs()
      item.editLoading = true
      this.editLoading = true
      if (!isFromUrl) { this.$router.replace({ query: { ...this.$route.query, mode: 'edit', id: item.id, tab, scene } }).catch(() => {}) }
      getMovieDetails(item.id)
        .then(response => {
          this.updateSavedMovie(response.result)
          this.crudTabs.Media.missingCover = !!missingCover
          this.enableTabs()
          this.toggleOverlay()
          this.updateSelectedScene(parseInt(scene))
          item.editLoading = false
          this.editLoading = false
        })
        .catch(errorHandler.bind(this))
    },
    enableTabs () {
      this.crudTabs.Metadata.disabled = false
      this.crudTabs.Metadata.unsaved = false
      this.crudTabs.Media.disabled = false
      this.crudTabs.Media.unsaved = false
      this.crudTabs.VOD.disabled = false
      this.crudTabs.VOD.unsaved = false
      this.crudTabs.Translations.disabled = false
      this.crudTabs.Translations.unsaved = false
    },
    addItem () {
      this.updateCrudType('add')
      this.updateSavedMovie(null)
      this.resetCrudTabs()
      this.$router.replace({ query: { ...this.$route.query, mode: 'add', tab: 0 } }).catch(() => {})
      this.crudTabs.Metadata.disabled = false
      this.crudTabs.Metadata.unsaved = false
      this.toggleOverlay()
    },
    goToNextTab () {
      let length = 0
      for (let tab in this.crudTabs) {
        if (!this.crudTabs[tab].disabled) length++
      }
      if (this.activeOverlayTab === length - 1) {
        if (!this.crudTabs[this.allTabs[0]].disabled) this.activeOverlayTab = 0
      } else {
        if (!this.crudTabs[this.allTabs[this.activeOverlayTab + 1]].disabled) this.activeOverlayTab++
      }
    }
  }
}
</script>

