<template>
  <v-app>
    <core-history-drawer />
    <core-toolbar />
    <core-drawer />
    <core-view />
  </v-app>
</template>

<script>
import coreToolbar from '@/components/core/Toolbar'
import coreDrawer from '@/components/core/Drawer'
import coreView from '@/components/core/View'
import coreHistoryDrawer from '@/components/core/HistoryDrawer'

export default {
  components: {
    coreToolbar,
    coreDrawer,
    coreHistoryDrawer,
    coreView
  },
  beforeCreate () {
    this.$store.dispatch('userInitialization')
  }
}
</script>

<style lang="scss" >
@import '@/styles/index.scss';
</style>
