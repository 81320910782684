/* eslint-disable no-debugger */
import httpMovieLib from './http/httpMovieLib'
import apiHandler from '@/services/http/api'

const multiPart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export const moviesGrid = (searchParameters, pagination) => {
  let searchString = ''
  let filterSceneCover = false
  let moviesWithoutSceneCoversSearchString = '' 

  Object.keys(searchParameters).forEach(el => {
    if (searchParameters[el] !== '' && searchParameters[el] !== undefined && searchParameters[el] !== null) {
      switch (el) {
        case 'type': {
          searchString += `&mm[0][o]=is&mm[0][v]=${searchParameters[el]}`
          break
        }
        case 'title': {
          searchString += `&mt[0][o]=cs&mt[0][v]=${searchParameters[el]}`
          break
        }
        case 'ean': {
          searchString += `&ean[0][o]=cs&ean[0][v]=${searchParameters[el]}`
          break
        }
        case 'dvdid': {
          searchString += `&dvdid[0][o]=is&dvdid[0][v]=${searchParameters[el]}`
          break
        }
        case 'studio': {
          searchString += `&mst[0][o]=is&mst[0][v]=${searchParameters[el]}`
          break
        }
        case 'mmp': {
          if(searchParameters[el] === 'scenecover') {
            filterSceneCover = true
          }
          searchString += `&mmp[0][o]=is&mmp[0][v]=${searchParameters[el]}`
          break
        }
        case 'serie': {
          if (typeof searchParameters[el] === 'string' || typeof searchParameters[el] === 'number') {
            searchString += `&msr[o]=any`
            searchString += `&msr[v][0]=${searchParameters[el]}`
          } else if (Array.isArray(searchParameters[el])) {
            if (searchParameters[el].length) {
              searchString += `&msr[o]=any`
              searchParameters[el].forEach((id, index) => {
                searchString += `&msr[v][${index}]=${id}`
              })
            }
          }
          break
        }
        case 'category': {
          if (typeof searchParameters[el] === 'string' || typeof searchParameters[el] === 'number') {
            searchString += `&mc[o]=any`
            searchString += `&mc[v][0]=${searchParameters[el]}`
          } else if (Array.isArray(searchParameters[el])) {
            if (searchParameters[el].length) {
              searchString += `&mc[o]=any`
              searchParameters[el].forEach((id, index) => {
                searchString += `&mc[v][${index}]=${id}`
              })
            }
          }
          break
        }
        case 'tags': {
          if (typeof searchParameters[el] === 'string' || typeof searchParameters[el] === 'number') {
            searchString += `&mtg[o]=any`
            searchString += `&mtg[v][0]=${searchParameters[el]}`
          } else if (Array.isArray(searchParameters[el])) {
            if (searchParameters[el].length) {
              searchString += `&mtg[o]=any`
              searchParameters[el].forEach((tag, index) => {
                searchString += `&mtg[v][${index}]=${tag}`
              })
            }
          }
          break
        }
        /**
         * Handles parsing the 'id' search parameter value.
         * If id is a number, adds search query with 'is' operator.
         * If id includes 'MOV', splits on 'MOV' to extract the number. 
         * If id is a string with spaces or 'MOV', replaces spaces/MOV with commas.
         * Otherwise uses id value directly.
        */
        case 'id': {
          if (typeof searchParameters[el] === 'number') {
            searchString += `&id[0][o]=is&id[0][v]=${searchParameters[el]}`
          } else {
            if (searchParameters[el].includes('MOV')) {
              searchString += `&id[0][o]=is&id[0][v]=${searchParameters[el].replace(/MOV/g, '').replace(/\s+/g, ',')}`
            } else {
              if (typeof searchParameters[el] === 'string' && searchParameters[el].includes(' ')) {
                const ids = searchParameters[el].split(' ').join(',')
                searchString += `&id[0][o]=is&id[0][v]=${ids}`
              } else {
                searchString += `&id[0][o]=is&id[0][v]=${searchParameters[el]}`
              }
            }
          }
          break
        }
      }
    }
  })

  if(filterSceneCover){
    return apiHandler(httpMovieLib.get(`/api/movie-pictures/no-scene-covers`)).then(response => {
      const moviesArr = response.result
      const movieIds = Object.keys(moviesArr)
      let filteredMovies = []
      // Remove movies which are not included in the searchParameters 
      // searchParameters can containt one id number 6960
      // or an list of ids as strings: "6960, 62294"
      if(searchParameters.id){
        if(typeof searchParameters.id === 'string'){
          filteredMovies = movieIds.filter(id => searchParameters.id.includes(id));
        }else{
          filteredMovies = movieIds.filter(id => (searchParameters.id + "") === id);
        }
      }

      if (movieIds.length && (filteredMovies.length || !searchParameters.id)) {
        const movieIdsSearchString = filteredMovies.length > 0 ? filteredMovies.join(',%20') : movieIds.join(',%20')
        moviesWithoutSceneCoversSearchString += `&id[0][v]=${movieIdsSearchString}`;
        return apiHandler(httpMovieLib.get(`/api/movies?sort=${pagination.orderBy}&dir=${pagination.order}&limit=${pagination.rowsPerPage}&page=${pagination.page}${searchString}${moviesWithoutSceneCoversSearchString}`))
      } 

      // No movies without scene covers
      // Or no movies without scene covers which are included in the searchParameters
      return {
        "error": null,
        "result": {
            "totalItems": 0,
            "totalPages": 0,
            "currentPage": 1,
            "limit": 50,
            "offset": 0,
            "count": 0,
            "data": []
          }
      } 
    });
  } else {
    return apiHandler(httpMovieLib.get(`/api/movies?sort=${pagination.orderBy}&dir=${pagination.order}&limit=${pagination.rowsPerPage}&page=${pagination.page}${searchString}`))
  }
}

export const createMovie = data => apiHandler(httpMovieLib.post('/api/movie/create', data))

export const updateMovie = (id, data) => apiHandler(httpMovieLib.put(`/api/movie/${id}/update`, data))

export const manageMovieScenes = (id, sceneNo) => apiHandler(httpMovieLib.put(`/api/movie/${id}/num-of-scenes/${sceneNo}`))

export const updateMovieScene = (id, sceneNo, data) => apiHandler(httpMovieLib.put(`/api/movie/${id}/scene/${sceneNo}`, data))

export const updateAllMovieScene = payload => {
  debugger
  const promises = []
  payload.forEach(el => {
    if(el.movieMeta){
      // eslint-disable-next-line no-console
      console.log("MOVIE DATE UPDATED")
      promises.push(updateMovie(el.movieMeta.id, el.movieMeta));
    }else{
      promises.push(updateMovieScene(el.id, el.sceneNo, el.data))
    }
  })

  return Promise.all(promises)
    .then(values => values)
    .catch(e => Promise.reject(e))
}

export const removePicture = id => apiHandler(httpMovieLib.delete(`/api/movie-picture/${id}`))

const addPicture = (id, data) => httpMovieLib.post(`/api/movie/${id}/add-picture`, data, multiPart)

export const addPictureFromUrl = (id, data) => httpMovieLib.post(`/api/movie/${id}/add-picture`, data, { headers: { 'Content-Type': 'application/json' } })

export const updatePictureFromUrl = (id, data) => httpMovieLib.post(`/api/movie-picture/${id}`, data, { headers: { 'Content-Type': 'application/json' } })

export const updatePicture = (id, data) => httpMovieLib.post(`/api/movie-picture/${id}`, data, multiPart)

export const savePictures = payload => {
  const promises = []
  payload.forEach(el => {
    if (el.update) {
      promises.push(updatePicture(el.id, el.data))
    } else {
      promises.push(addPicture(el.id, el.data))
    }
  })

  return Promise.all(promises)
    .then(values => values)
    .catch(e => Promise.reject(e))
}

export const savePicturesFromUrl = payload => {
  const promises = []
  payload.forEach(el => {
    if (el.mode === 'add') promises.push(addPictureFromUrl(el.id, el.data))
    else promises.push(updatePictureFromUrl(el.id, el.data))
  })

  return Promise.all(promises)
    .then(values => values)
    .catch(e => Promise.reject(e))
}

export const getMovieDetails = id => apiHandler(httpMovieLib.get(`/api/movie/${id}`))

export const getMovieDetailsByEan = ean => apiHandler(httpMovieLib.get(`/api/movie/ean/${ean}`))

const imageExists = image => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      image.exist = true
      resolve(image)
    }
    img.onerror = () => {
      image.exist = false
      reject(image)
    }
    img.src = image.url
  })
}

export const checkImagesByEan = ean => {
  const images = []

  images.push({
    url: `${window.BaseCoverUrl}${ean}_f.jpg`,
    type: 'coverfront',
    censored: true
  })
  images.push({
    url: `${window.BaseCoverUrl}${ean}_fu.jpg`,
    type: 'coverfront',
    censored: false
  })
  images.push({
    url: `${window.BaseCoverUrl}${ean}_b.jpg`,
    type: 'coverback',
    censored: true
  })
  images.push({
    url: `${window.BaseCoverUrl}${ean}_bu.jpg`,
    type: 'coverback',
    censored: false
  })

  return Promise.all(images.map(image => imageExists(image).catch(e => e)))
}

export const updateMultipleImages = data => apiHandler(httpMovieLib.put('/api/movie-pictures/update', data))

export const deleteAuxEan = (movieId, ean) => apiHandler(httpMovieLib.delete(`/api/movie/${movieId}/aux-ean/${ean}`))

export const addMovieTrailer = (data, movieId) => apiHandler(httpMovieLib.put(`/api/movie/${movieId}/add-trailer`, data))

export const updateMovieTrailer = (data, trailerId) => apiHandler(httpMovieLib.post(`/api/movie/trailer/${trailerId}`, data))

export const deleteMovieTrailer = trailerId => apiHandler(httpMovieLib.delete(`/api/movie/trailer/${trailerId}`))
