<template>
  <v-content>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
      <notifications
        :duration="3000"
        close-on-click
        class="hover-notification"
        position="bottom right"
      />
      <confirm-dialog />
    </div>
  </v-content>
</template>

<script>
import ConfirmDialog from '@/components/common/ConfirmDialog'

export default {
  components: {
    confirmDialog: ConfirmDialog
  },
  metaInfo () {
    return {
      title: 'Movie Manager'
    }
  }
}
</script>
