<template>
  <v-container
    class="crud-container"
    fluid
    grid-list-sm
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        v-for="(shot, index) in currentImageSet[id]"
        :key="index + id"
        xs3
        class="wide-image"
      >
        <ImageContainer
          :id="index.toString()"
          :media-set="currentImageSet[id]"
          :on-file-picked="onImageFilePicked"
          :title="`${title} (${index + 1})`"
          :manage="manage"
          :placeholder-index="index"
          :is-updating-censorship="isUpdatingCensorship"
          :has-set-cover-image="id === 'bigshot' && type === 'edit-scene'"
          :change-cover="changeCover"
          :update-selected-bighsot="updateSelectedBighsot"
          :is-selected="selectedBigshotImages.includes(shot.id)"
          resolution="0x236"
          has-remove-placeholder
          @updateClicked="val => $emit('updateClicked', val)"
          @removeImage="(id, index) => clearImage(id, index)"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ImageContainer from '@/components/common/ImageContainer'

export default {
  components: {
    ImageContainer
  },
  props: {
    id: {
      type: String,
      default: 'bigshot'
    },
    type: {
      type: String,
      default: 'add'
    },
    title: {
      type: String,
      default: 'bigshot'
    },
    currentImageSet: {
      type: Object,
      default: () => {}
    },
    isUpdatingCensorship: {
      type: Boolean,
      default: false
    },
    onImageFilePicked: {
      type: Function,
      default: () => {}
    },
    selectedBigshotImages: {
      type: Array,
      default: () => []
    },
    changeCover: {
      type: Function,
      default: () => {}
    },
    updateSelectedBighsot: {
      type: Function,
      default: () => {}
    },
    clearImage: {
      type: Function,
      default: () => {}
    },
    manage: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
