<template>
  <v-sheet>
    <v-container
      class="crud-container"
      grid-list-md
    >
      <br>
      <v-form
        v-for="(scene, index) in scenesDataContainer"
        :key="index + 'scene' + savedMovie.id"
        :ref="`sceneform_${index + 1}`"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            md4
            lg4
          >
            <h4>{{ savedMovie.title ? savedMovie.title : savedMovie.translations.de.title }} / Scene {{ index + 1 }}</h4>
            <v-layout
              class="scene-cover-layout"
              row
            >
              <v-flex
                lg6
                md6
                style="position: relative;"
              >
                <h5>Censored Cover</h5>
                <v-img
                  v-if="getSceneCoverImage(index + 1, true).url"
                  :src="getSceneCoverImage(index + 1, true).url"
                />
                <div
                  v-else
                  class="scene-cover-placeholder"
                >
                  No image available
                </div>
                <v-btn
                  class="cover-select-vod"
                  absolute
                  top
                  right
                  color="primary"
                  @click.prevent="openVodCoverForm(index + 1, getSceneCoverImage(index + 1, true), true)"
                >
                  Select from VOD
                </v-btn>
              </v-flex>
              <v-flex
                lg6
                md6
                style="position: relative;"
              >
                <h5>Uncesored Cover</h5>
                <v-img
                  v-if="getSceneCoverImage(index + 1, false).url"
                  :src="getSceneCoverImage(index + 1, false).url"
                  class="scene-cover"
                />
                <div
                  v-else
                  class="scene-cover-placeholder"
                >
                  No image available
                </div>
                <v-btn
                  absolute
                  class="cover-select-vod"
                  top
                  right
                  color="primary"
                  @click.prevent="openVodCoverForm(index + 1, getSceneCoverImage(index + 1, false), false)"
                >
                  Select from VOD
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            md2
            lg2
          >
            <v-text-field
              :value="scene.titleDe"
              box
              flat
              label="Title DE"
              @change="val => scenesDataContainer[index].titleDe = val"
            />
            <v-text-field
              :value="scene.title"
              box
              flat
              label="Title EN"
              @change="val => scenesDataContainer[index].title = val"
            />
            <v-text-field
              :value="scene.duration"
              box
              flat
              type="number"
              label="Duration"
              @change="val => scenesDataContainer[index].duration = val"
            />
            <v-autocomplete
              :value="scene.stars"
              :items="pornstars"
              :search-input.sync="searchPornstars"
              small-chips
              box
              chips
              color="blue-grey lighten-2"
              label="Pornstars"
              item-text="name"
              item-value="id"
              multiple
              @change="val => updatePornstars(index, val)"
            >
              <template #selection="data">
                <v-chip
                  :selected="data.selected"
                  close
                  class="chip--select-multi"
                  @input="removeItem(scenesDataContainer[index].stars, data.item.id)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template #item="data">
                <template>
                  <v-list-tile-content>
                    <v-list-tile-title v-html="data.item.name" />
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex
            md6
            lg6
          >
            <v-layout row>
              <v-flex
                md6
                lg6
              >
                <Editor
                  :value="scene.descrDe"
                  placeholder="Description De"
                  @change="value => scenesDataContainer[index].descrDe = value"
                />
              </v-flex>
              <v-flex
                md6
                lg6
              >
                <Editor
                  :value="scene.descr"
                  placeholder="Description"
                  @change="value => scenesDataContainer[index].descr = value"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template #actions v-if="scenesDataContainer[index].categories">
              <v-icon color="teal">arrow_drop_down</v-icon>
            </template>
            <template #header>
              <div> Categories for Scene {{ index + 1 }} {{ scenesDataContainer[index].categories && "(differ from Movie)" }}</div>
            </template>
            <CategoriesBoxes
              :value="scenesDataContainer[index].categories ? scenesDataContainer[index].categories : movieCategories"
              :categories="categories"
              @change="newVal => {$set(scenesDataContainer[index], 'categories', newVal) }"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <br><hr v-if="index + 1 !== scenesDataContainer.length" class="fade-line"><br>
      </v-form>
    </v-container>
    <CoverSelectForm
      v-if="savedMovie"
      :active="coverSelectForm"
      :selected-scene="selectedScene"
      :movie-id="savedMovie && savedMovie.id || null"
      :saved-movie="savedMovie"
      @updateActive="val => updateCoverForm(val)"
    />
    <ActionButtons
      :save="() => save({ close: false, next: false })"
      :close="close"
      :type="type"
      :loading-save="loadingSave"
      :save-and-next="() => save({ close: false, next: true })"
      :save-and-close="() => save({ close: true, next: false })"
      has-next
    />
  </v-sheet>
</template>

<script>
import ActionButtons from '@/components/common/ActionButtons'
import Editor from '@/components/common/Editor'
import CoverSelectForm from './CoverSelectVodForm'
import { clearData, deepClone } from '@/utils/object'
import { errorHandler } from '@/utils/error'
import { crudObjectHelpers } from '@/mixins/crudComponents'
import { updateAllMovieScene } from '@/services/movie.service'
import { getMovieVodLinks } from '@/services/vod.service'
import { mapActions, mapGetters } from 'vuex'
import CategoriesBoxes from '../MetaData/FormFields/CategoriesBoxes'

export default {
  components: {
    ActionButtons,
    Editor,
    CoverSelectForm,
    CategoriesBoxes
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    nextTab: {
      type: Function,
      default: () => {}
    },
    overlay: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    },
    allTabs: {
      type: Array,
      default: () => []
    },
  },
  mixins: [crudObjectHelpers],
  data () {
    return {
      name: 'Scenes',
      loadingSave: false,
      coverSelectForm: false,
      scenesDataContainer: [],
      searchPornstars: '',
      scenesDataContainerEditing: null,
      cover: {
        mediaName: '',
        mediaUrl: '',
        mediaFile: '',
        deleteLoading: false,
        uploaded: false,
        name: 'cover'
      },
      selectedScene: {
        width: null,
        height: null,
        link: null,
        id: null,
        isCensored: false,
        number: null
      },
      movieCategories: [113,235],
    }
  },
  computed: {
    ...mapGetters({
      savedMovie: 'movies/savedMovie',
      categories: 'cachedCategories',
      type: 'movies/crudType'
    }),
    pornstars () {
      return this.savedMovie.roles.map(el => {
        return {
          name: el.star.name,
          id: el.star.id
        }
      }).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    activeTab (val) {
      if (this.allTabs[val] === this.name) {
        clearData.call(this)
        this.fillScenesData()
      }
    },
    overlay (val, oldVal) {
      if (oldVal && !val) {
        clearData.call(this)
      }
    }
  },
  created () {
    if (this.allTabs[this.activeTab] === this.name) {
      clearData.call(this)
      this.fillScenesData(),
      this.getCategoriesOptionsData()
    }
  },
  methods: {
      ...mapActions([
      'getCategoriesOptionsData',
    ]),
    fillScenesData () {
      for (let i = 0; i < Object.keys(this.savedMovie.scenes).length; i++) {
        this.$set(this.scenesDataContainer, i, {})
        this.$set(this.scenesDataContainer[i], 'title', this.savedMovie.scenes[i + 1].title)
        this.$set(this.scenesDataContainer[i], 'titleDe', this.savedMovie.scenes[i + 1].translations.de.title)
        this.$set(this.scenesDataContainer[i], 'duration', this.savedMovie.scenes[i + 1].duration)
        this.$set(this.scenesDataContainer[i], 'descr', this.savedMovie.scenes[i + 1].descr)
        this.$set(this.scenesDataContainer[i], 'descrDe', this.savedMovie.scenes[i + 1].translations.de.descr)
        this.$set(this.scenesDataContainer[i], 'stars', [])
        // only set scene categories if its different from movie
        // empty array means use movie categories
        // possible edge-case if there should be a scene without a category
        if(this.savedMovie.scenes[i+1].categories.length){
          this.$set(this.scenesDataContainer[i], 'categories', this.savedMovie.scenes[i+1].categories)
        }
        // default categories from movie
        this.movieCategories = this.savedMovie.categories.map(el => el.id);
   
        // handle pornstars
        if (this.savedMovie.roles.length) {
          const sceneRoles = new Set();
          this.savedMovie.roles.forEach(el => {
            if (el.scenes.includes(i + 1)) sceneRoles.add(el.star)
          })
          Array.from(sceneRoles).sort((a, b) => a.name - b.name).forEach(el => this.scenesDataContainer[i].stars.push(el.id))
        }
        this.scenesDataContainerEditing = deepClone(this.scenesDataContainer)
      }
    },
    getSceneCoverImage (sceneNum, censored) {
      if (!Array.isArray(this.savedMovie.pictures)) {
        if (this.savedMovie.pictures.scenes) {
          if (this.savedMovie.pictures.scenes[sceneNum]) {
            if (this.savedMovie.pictures.scenes[sceneNum].cover) {
              const image = this.savedMovie.pictures.scenes[sceneNum].cover.find(el => el.censored === censored)
              if (image) return image
            }
          }
        }
      }
      return ''
    },
    updatePornstars (sceneNo, data) {
      this.searchPornstars = ''
      this.scenesDataContainer[sceneNo].stars.splice(0, this.scenesDataContainer[sceneNo].stars.length)
      data.forEach(el => this.scenesDataContainer[sceneNo].stars.push(el))
    },
    save (options) {
      this.loadingSave = true
      const payload = []
      this.scenesDataContainer.forEach((el, index) => payload.push({
        id: this.savedMovie.id,
        sceneNo: index + 1,
        data: el
      }))
      updateAllMovieScene(payload)
        .then(() => {
          this.$notify({
            type: 'success',
            title: `All movie scenes: ${this.savedMovie.id}`,
            text: 'Updated'
          })
          this.loadingSave = false
          this.scenesDataContainerEditing = deepClone(this.scenesDataContainer)
          if (options.next) this.nextTab()
          if (options.close) this.close()
        })
        .catch(errorHandler.bind(this))
    },
    openVodCoverForm (sceneIndex, image, isCensored) {
      getMovieVodLinks(this.savedMovie.id, sceneIndex)
        .then(({ result }) => {
          const [vodLinks] = Object.keys(result).filter(key => !result[key].origId).map(el => result[el])
          if (vodLinks.cdn?.length) {
            this.coverSelectForm = true
            const resolution = vodLinks.resolution.split('x')
            this.selectedScene.width = parseInt(resolution[0])
            this.selectedScene.height = parseInt(resolution[1])
            this.selectedScene.link = vodLinks.cdn[0]
            this.selectedScene.number = sceneIndex
            if (image !== '') {
              this.selectedScene.id = image.id
            } else {
              this.selectedScene.id = null
            }
            this.selectedScene.isCensored = isCensored
          }else {
            this.$notify({
              type: 'error',
              title: `VOD links`,
              text: 'Not found for this scene'
            })
          }
        })
        .catch(errorHandler.bind(this))
    },
    updateCoverForm (val) {
      if (!val) {
        this.selectedScene.width = null
        this.selectedScene.height = null
        this.selectedScene.link = null
        this.selectedScene.id = null
        this.selectedScene.isCensored = false
        this.selectedScene.number = null
      }
      this.coverSelectForm = val
    }
  }
}
</script>

<style lang="scss">
.fade-line {
  height: 4px;
  color: red;
  background-image: linear-gradient(90deg, rgba(81, 168, 120, 1) 0%, rgba(81, 168, 120, 0.2) 100%);
  border: none;
}
</style>