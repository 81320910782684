const parser = response => response.data

const errorHandler = e => Promise.reject(e)
/**
 * 
 * @param {axios} promise 
 * @returns small helper function that reduces one additional promise for each endpoint.
 */
const apiHandler = promise => {
  return promise
    .then(parser)
    .catch(errorHandler)
}

export default apiHandler
