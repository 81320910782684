<template>
  <v-flex
    style="position: relative;"
  >
    <h3>{{ heading }}</h3>
    <v-tooltip
      bottom
    >
      <template #activator="{ on }">
        <v-btn
          small
          round
          fab
          top
          right
          depressed
          absolute
          class="remove-trailer"
          color="red"
          v-on="on"
          @click="$emit('removeTrailer', data.id)"
        >
          <v-icon dark>
            close
          </v-icon>
        </v-btn>
      </template>
      <span>Remove trailer</span>
    </v-tooltip>
    <br>
    <video
      height="auto"
      width="100%"
      preload="metadata"
      controls
    >
      <source
        :src="data.url"
        type="video/mp4"
      >
    </video>
  </v-flex>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    heading: {
      type: String,
      default: ''
    }
  }
}
</script>
